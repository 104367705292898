import {
    SET_OFFICE_LOADING,
    SET_ORDER_LOADING
} from '../constants.jsx'

let initialState = {
    order_loading: true,
    office_loading: true
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_OFFICE_LOADING:
            state = Object.assign({}, state, {
                office_loading: action.value
            });
            break;
        case SET_ORDER_LOADING:
            state = Object.assign({}, state, {
                order_loading: action.value
            });
            break;
    }
    return state;
}