import React, {Component} from 'react'
import {render} from 'react-dom'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { routerReducer } from 'react-router-redux'
import Currency from './Common/Currency.jsx'
import Utils from './Common/Utils.jsx'
import PostalCode from './OnlineScheduler/Components/PostalCode.jsx'
import Calendar from './OnlineScheduler/Components/Calendar.jsx'
import Contacts from './OnlineScheduler/Components/Contacts.jsx'
import Address from './OnlineScheduler/Components/Address.jsx'
import PropertyInfo from './OnlineScheduler/Components/PropertyInfo.jsx'
import Packages from './OnlineScheduler/Components/Packages.jsx'
import Review from './OnlineScheduler/Components/Review.jsx'
import * as reducers from './OnlineScheduler/Reducers'
import { app_state } from './OnlineScheduler/app_data.jsx'
import { loadOrder, loadOffice } from './OnlineScheduler/Actions/App.jsx';
import './OnlineScheduler/osstyle.css';

Currency.init(app_state.company_key);

const reducer = combineReducers({...reducers,routing:routerReducer});
const store = createStore(reducer, applyMiddleware(thunk));
// cbs - removed due to api changes - TODO - const history = syncHistoryWithStore(/* need history here */, store);
const { Office, Order } = store.getState();
const queryParams = Utils.parseQuery(window.location.search);

if (Office.authorized) {
    store.dispatch(loadOffice())
        .then(store.dispatch(loadOrder()));
}

if ('scrollRestoration' in window.history) {
    history.scrollRestoration = 'manual';
}

const NotFound = (props) => (
    <div className="container">
        <img src={'/'+Office.company_key+'/logo'} style={{display: 'block', margin: '10px auto', maxWidth: '250px'}}/>
        <div className="row" style={{marginTop:'25px'}}>
            <div className="col-md-6 col-md-offset-3">
                <h3>Sorry!</h3>
                <p>
                    We couldn't find the page you were looking for.
                    If you need help you can call us at <a href={'tel:'+Office.office_phone}>{Office.office_phone}</a> or
                    email us at <a href={'mailto:'+Office.office_email}>{Office.office_email}</a>.
                </p>
                <p className="text-center" style={{marginTop:'50px'}}>
                    <a href={'https://inspectionsupport.com/'+Office.company_key+'/online-scheduler/'+Office.office_uuid}
                       className="btn btn-success">Schedule Again</a>
                </p>
                <p className="text-center" style={{marginTop:'50px'}}>
                    {Office.office_name}<br/>
                    <a href={'tel:'+Office.office_phone}>{Office.office_phone}</a><br/>
                    <a href={'mailto:'+Office.office_email}>{Office.office_email}</a>
                </p>
            </div>
        </div>
    </div>
);

const OnlineSchedulerDisabled = (props) => (
    <div className="container">
        <img src={'/'+Office.company_key+'/logo'} style={{display: 'block', margin: '10px auto', maxWidth: '250px'}}/>
        <div className="row" style={{marginTop:'25px'}}>
            <div className="col-md-6 col-md-offset-3">
                <h3>Sorry!</h3>
                <p>
                    Our Online Scheduling System is currently disabled.
                    If you need help you can call us at <a href={'tel:'+Office.office_phone}>{Office.office_phone}</a> or
                    email us at <a href={'mailto:'+Office.office_email}>{Office.office_email}</a>.
                </p>
                <p className="text-center" style={{marginTop:'50px'}}>
                    {Office.office_name}<br/>
                    <a href={'tel:'+Office.office_phone}>{Office.office_phone}</a><br/>
                    <a href={'mailto:'+Office.office_email}>{Office.office_email}</a>
                </p>
            </div>
        </div>
    </div>
);

const OnlineSchedulerDisabledNoServices = (props) => (
    <div className="container">
        <img src={'/'+Office.company_key+'/logo'} style={{display: 'block', margin: '10px auto', maxWidth: '250px'}}/>
        <div className="row" style={{marginTop:'25px'}}>
            <div className="col-md-6 col-md-offset-3">
                <h3>Sorry!</h3>
                <p>
                    Our Online Scheduling System is currently unable to provide any services.
                    If you need help you can call us at <a href={'tel:'+Office.office_phone}>{Office.office_phone}</a> or
                    email us at <a href={'mailto:'+Office.office_email}>{Office.office_email}</a>.
                </p>
                <p className="text-center" style={{marginTop:'50px'}}>
                    {Office.office_name}<br/>
                    <a href={'tel:'+Office.office_phone}>{Office.office_phone}</a><br/>
                    <a href={'mailto:'+Office.office_email}>{Office.office_email}</a>
                </p>
            </div>
        </div>
    </div>
);

const OnlineSchedulerUnauthorized = (props) => (
    <div className="container">
        <img src={'/'+Office.company_key+'/logo'} style={{display: 'block', margin: '10px auto', maxWidth: '250px'}}/>
        <div className="row" style={{marginTop:'25px'}}>
            <div className="col-md-6 col-md-offset-3">
                <h3>Sorry!</h3>
                <p>
                    Your session expired or you are not authorized to schedule this online order.
                    If you need help you can call us at <a href={'tel:'+Office.office_phone}>{Office.office_phone}</a> or
                    email us at <a href={'mailto:'+Office.office_email}>{Office.office_email}</a>.
                </p>
                <p className="text-center" style={{marginTop:'50px'}}>
                    <a href={'https://inspectionsupport.com/'+Office.company_key+'/online-scheduler/'+Office.office_uuid}
                       className="btn btn-success">Schedule Again</a>
                </p>
                <p className="text-center" style={{marginTop:'50px'}}>
                    {Office.office_name}<br/>
                    <a href={'tel:'+Office.office_phone}>{Office.office_phone}</a><br/>
                    <a href={'mailto:'+Office.office_email}>{Office.office_email}</a>
                </p>
            </div>
        </div>
    </div>
);

let routes = [];

if (app_state.enabled || (queryParams.hasOwnProperty('try') && parseInt(queryParams.try) === 1)) {
    if (!Office.authorized && !Order.complete) {
        routes.push(<Route key="1" path="/:ck/online-scheduler*" exact={true} component={OnlineSchedulerUnauthorized}/>);
    } else if (Order.confirmed) {
        routes.push(<Route key="1" path="/:ck/online-scheduler*" exact={true} component={Review}/>);
    } else if (Office.services_count === 0) {
        routes.push(<Route key="1" path="/:ck/online-scheduler*" exact={true} component={OnlineSchedulerDisabledNoServices}/>);
    } else {
        if(app_state.calendar_last) {
            routes.push(<Route key="1" path="/:ck/online-scheduler" exact={true} component={Address}/>);
            routes.push(<Route key="2" path="/:ck/online-scheduler/address" component={Address}/>);
            routes.push(<Route key="3" path="/:ck/online-scheduler/contacts" component={Contacts}/>);
            routes.push(<Route key="4" path="/:ck/online-scheduler/property-info" component={PropertyInfo}/>);
            routes.push(<Route key="5" path="/:ck/online-scheduler/packages" component={Packages}/>);
            routes.push(<Route key="6" path="/:ck/online-scheduler/calendar" component={Calendar}/>);
            routes.push(<Route key="7" path="/:ck/online-scheduler/review" component={Review}/>);
            routes.push(<Route key="8" path="/:ck/online-scheduler/unauthorized" component={OnlineSchedulerUnauthorized}/>);
            routes.push(<Route key="9" path='*' exact={true} component={NotFound}/>);
        } else {
            if (app_state.zip_restrict) {
                routes.push(<Route key="1" path="/:ck/online-scheduler" exact={true} component={PostalCode}/>);
                routes.push(<Route key="2" path="/:ck/online-scheduler/postal-code" component={PostalCode}/>);
                routes.push(<Route key="3" path="/:ck/online-scheduler/calendar" component={Calendar}/>);
                routes.push(<Route key="4" path="/:ck/online-scheduler/contacts" component={Contacts}/>);
                routes.push(<Route key="5" path="/:ck/online-scheduler/address" component={Address}/>);
                routes.push(<Route key="6" path="/:ck/online-scheduler/property-info" component={PropertyInfo}/>);
                routes.push(<Route key="7" path="/:ck/online-scheduler/packages" component={Packages}/>);
                routes.push(<Route key="8" path="/:ck/online-scheduler/review" component={Review}/>);
                routes.push(<Route key="9" path="/:ck/online-scheduler/unauthorized" component={OnlineSchedulerUnauthorized}/>);
                routes.push(<Route key="10" path='*' exact={true} component={NotFound}/>);
            } else {
                routes.push(<Route key="1" path="/:ck/online-scheduler" exact={true} component={Calendar}/>);
                routes.push(<Route key="2" path="/:ck/online-scheduler/calendar" component={Calendar}/>);
                routes.push(<Route key="3" path="/:ck/online-scheduler/contacts" component={Contacts}/>);
                routes.push(<Route key="4" path="/:ck/online-scheduler/address" component={Address}/>);
                routes.push(<Route key="5" path="/:ck/online-scheduler/property-info" component={PropertyInfo}/>);
                routes.push(<Route key="6" path="/:ck/online-scheduler/packages" component={Packages}/>);
                routes.push(<Route key="7" path="/:ck/online-scheduler/review" component={Review}/>);
                routes.push(<Route key="8" path="/:ck/online-scheduler/unauthorized" component={OnlineSchedulerUnauthorized}/>);
                routes.push(<Route key="9" path='*' exact={true} component={NotFound}/>);
            }
        }
    }
} else {
    routes.push(<Route key="1" path="/:ck/online-scheduler*" exact={true} component={OnlineSchedulerDisabled}/>);
}

store.subscribe(() => {
    const newState = store.getState();
    if (newState.Office.authorized === false) {
        window.location = '/'+newState.Office.company_key+'/online-scheduler/unauthorized?t='+ newState.Office.token +'&office='+newState.Office.office_uuid;
    }
});

render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                {routes}
            </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);