import {
    SET_TOKEN,
    SET_AUTHORIZED,
    SET_COMPANY_KEY,
    SET_OFFICE_UUID,
    SET_OFFICE_NAME,
    SET_OFFICE_PHONE,
    SET_OFFICE_EMAIL,
    SET_OFFICE_STATE,
    SET_SETTINGS,
    SET_USERS,
    SET_STATES,
    SET_CONTROLS,
    SET_OFFICE_ALL
} from '../constants.jsx'

export function setOfficeAll(value) {
    return { type: SET_OFFICE_ALL, value }
}

export function setToken(value) {
    return { type: SET_TOKEN, value }
}

export function setAuthorized(value) {
    return { type: SET_AUTHORIZED, value }
}

export function setCompanyKey(value) {
    return { type: SET_COMPANY_KEY, value }
}

export function setOfficeUuid(value) {
    return { type: SET_OFFICE_UUID, value }
}

export function setOfficeName(value) {
    return { type: SET_OFFICE_NAME, value }
}

export function setOfficeEmail(value) {
    return { type: SET_OFFICE_EMAIL, value }
}

export function setOfficePhone(value) {
    return { type: SET_OFFICE_PHONE, value }
}

export function setOfficeState(value) {
    return { type: SET_OFFICE_STATE, value }
}

export function setSettings(value) {
    return { type: SET_SETTINGS, value }
}

export function setUsers(value) {
    return { type: SET_USERS, value }
}

export function setStates(value) {
    return { type: SET_STATES, value }
}

export function setControls(value) {
    return { type: SET_CONTROLS, value }
}
