import React, { Component } from 'react'
import { withRouter } from 'react-router'
import FixedSideBar from './FixedSideBar.jsx'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ContactsActions from '../Actions/Contacts.jsx'
import * as OrderActions from '../Actions/Order.jsx'
import * as OfficeActions from '../Actions/Office.jsx'
import NextButton from './NextButton.jsx'
import BackButton from './BackButton.jsx'
import Container from './Container.jsx'
import AgentForm from './AgentForm.jsx'
import InsuranceAgentForm from './InsuranceAgentForm.jsx'
import ClientForm from './ClientForm.jsx'
import { AddContact, ContactForm } from './ContactForm.jsx'
import { copyObject } from '../util.jsx'
import { canSetupOnPageLoad, canSetupOnExistingState, getAnalyticsEventLabel } from '../util.jsx';
import Utils from '../../Common/Utils.jsx'
import { withStyles } from '@material-ui/core/styles';
import { setNavContacts, setNavLoading } from '../Actions/Nav.jsx'
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, FormHelperText, FormControl, InputLabel } from '@material-ui/core';
import './compstyles.css';

const styles = theme => ({
    icon: {
        // fill: theme.palette.primary.main,
        right: '12px',
        top: 'calc(42% - 12px)', // Adjust the position if needed
        width: '30px', // Increase or decrease the size as per your needs
        height: '30px', // Increase or decrease the size as per your needs
        position: 'absolute',
        pointerEvents: 'none',
      },
});

export class ContactsContainer extends Component {

    constructor(props) {
        super(props);

        this.updateUserOtherValue = this.updateUserOtherValue.bind(this);
        this.setUserOther = this.setUserOther.bind(this);
        this.cancelSetUserOther = this.cancelSetUserOther.bind(this);
        this.clearUserRole = this.clearUserRole.bind(this);
        this.validateField = this.validateField.bind(this);
        this.updateTextField = this.updateTextField.bind(this);

        this.selectBuyersAgent = this.selectBuyersAgent.bind(this);
        this.editBuyersAgent = this.editBuyersAgent.bind(this);
        this.cancelEditBuyersAgent = this.cancelEditBuyersAgent.bind(this);
        this.clearBuyersAgent = this.clearBuyersAgent.bind(this);
        this.newBuyersAgent = this.newBuyersAgent.bind(this);
        this.saveBuyersAgent = this.saveBuyersAgent.bind(this);
        this.setNoBuyersAgent = this.setNoBuyersAgent.bind(this);
        this.cancelNoBuyersAgent = this.cancelNoBuyersAgent.bind(this);

        this.selectSellersAgent = this.selectSellersAgent.bind(this);
        this.editSellersAgent = this.editSellersAgent.bind(this);
        this.cancelEditSellersAgent = this.cancelEditSellersAgent.bind(this);
        this.clearSellersAgent = this.clearSellersAgent.bind(this);
        this.newSellersAgent = this.newSellersAgent.bind(this);
        this.saveSellersAgent = this.saveSellersAgent.bind(this);
        this.setNoSellersAgent = this.setNoSellersAgent.bind(this);
        this.cancelNoSellersAgent = this.cancelNoSellersAgent.bind(this);

        this.selectInsuranceAgent = this.selectInsuranceAgent.bind(this);
        this.editInsuranceAgent = this.editInsuranceAgent.bind(this);
        this.cancelEditInsuranceAgent = this.cancelEditInsuranceAgent.bind(this);
        this.clearInsuranceAgent = this.clearInsuranceAgent.bind(this);
        this.newInsuranceAgent = this.newInsuranceAgent.bind(this);
        this.saveInsuranceAgent = this.saveInsuranceAgent.bind(this);
        this.setNoInsuranceAgent = this.setNoInsuranceAgent.bind(this);
        this.cancelNoInsuranceAgent = this.cancelNoInsuranceAgent.bind(this);

        this.editClient = this.editClient.bind(this);
        this.cancelEditClient = this.cancelEditClient.bind(this);
        this.clearClient = this.clearClient.bind(this);
        this.newClient = this.newClient.bind(this);
        this.saveClient = this.saveClient.bind(this);

        this.createContactObject = this.createContactObject.bind(this);
        this.selectContact = this.selectContact.bind(this);
        this.editContact = this.editContact.bind(this);
        this.cancelEditContact = this.cancelEditContact.bind(this);
        this.clearContact = this.clearContact.bind(this);
        this.newContact = this.newContact.bind(this);
        this.saveContact = this.saveContact.bind(this);
        this.setNoContact = this.setNoContact.bind(this);
        this.setContactType = this.setContactType.bind(this);
        this.saveContacts = this.saveContacts.bind(this);

        this.resetValidation = this.resetValidation.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.save = this.save.bind(this);
        this.setRef = this.setRef.bind(this);
        this.setup = this.setup.bind(this);

        // local state
        this.state = {
            user_other: {
                prompt: false,
                value: null
            },
            display_fields: {
                buyers_agent: null,
                sellers_agent: null,
                insurance_agent: null,
                client: null,
                contacts: null
            },
            buyers_agent: {
                edit: false,
                add: false,
                saving: false,
                none: false,
                warning: false,
                validation: null,
                data: null,
                preExisting: false
            },
            sellers_agent: {
                edit: false,
                add: false,
                saving: false,
                none: false,
                warning: false,
                validation: null,
                data: null,
                preExisting: false
            },
            insurance_agent: {
                edit: false,
                add: false,
                saving: false,
                none: false,
                warning: false,
                validation: null,
                data: null,
                preExisting: false
            },
            client: {
                edit: false,
                add: false,
                saving: false,
                validation: null,
                data: null
            },
            contact: null,
            contact_types_to_display: null,
            contact_validation: null,
            contacts: null,
            add_contact: false,
            saving: false,
            setup: false,
            currency: null,
            user_role: "",
            user_role_text: ""
        };
    }

    setRef(key, ref) {
        this[key] = ref;
    }

    validateField(field, value) {
        switch (field) {
            case 'first':
            case 'last':
            case 'address1':
            case 'city':
                return value !== '';
                break;
            case 'postal_code':
                return value && value.length > 1 && value.length < 11;
                break;
            case 'mobile_phone':
            case 'work_phone':
            case 'home_phone':
            case 'work_fax':
            case 'home_fax':
                return value !== '';
                break;
            case 'email':
                const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return emailRegex.test(String(value).toLowerCase());
                break;
        }
        return true;
    }

    updateTextField(event, object, field, validate) {
        const value = event.target.value;
        let state = this.state;
        let stateObject = typeof object !== 'object' ? state[object] : object;
        let validation = stateObject.validation;
        if (validate) {
            validation[field] = this.validateField(field, value);
        }
        stateObject.data[field] = value;
        this.setState(state);
    }

    setUserRole(user) {
        const { setUser } = this.props.contactsActions;
        let user_other = this.state.user_other;
        user_other.prompt = user.type === 'OTHER';
        this.setState({ 
            user_other: user_other, 
            user_role: user.type,
            user_role_text: user
         });
        if (user.type !== 'OTHER') {
            setUser(user);
            if (window.ga) {
                window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'Online Scheduler',
                    eventAction: 'set-role',
                    eventLabel: getAnalyticsEventLabel(this.props.company_key, user.text)
                });
            }
        } else {
            setTimeout(() => {
                this.ref_user_other_input.focus();
            }, 10);
        }
    }

    updateUserOtherValue(e) {
        const value = e.target.value;
        let user_other = this.state.user_other;
        if ('' !== value) {
            user_other.value = value;
        }
        this.setState({ user_other });
    }

    setUserOther() {
        const { setUser } = this.props.contactsActions;
        let user_other = this.state.user_other;
        let user = copyObject(this.props.user_types.find((type) => { return type.type === 'OTHER'; }));
        user.other = this.state.user_other.value;
        setUser(user);
        user_other.prompt = false;
        this.setState({ user_other });
    }

    cancelSetUserOther() {
        let user_other = this.state.user_other;
        user_other.prompt = false;
        this.setState({ user_other });
    }

    clearUserRole() {
        const { setUser } = this.props.contactsActions;
        let user_other = this.state.user_other;
        user_other.prompt = false;
        user_other.value = '';
        setUser(null);
    }

    resetValidation(object) {
        for (let p in object) {
            if (object.hasOwnProperty(p)) {
                object[p] = null;
            }
        }
        return object;
    }

    /* BUYER'S AGENT BEGIN */
    selectBuyersAgent(agent) {
        const { setBuyersAgent } = this.props.contactsActions;
        let buyers_agent = this.state.buyers_agent;
        buyers_agent.data = agent;
        buyers_agent.warning = !(agent && typeof agent === 'object');
        buyers_agent.preExisting = true;
        this.setState({ buyers_agent });
        setBuyersAgent(agent);
    }

    editBuyersAgent() {
        let buyers_agent = this.state.buyers_agent;
        buyers_agent.edit = true;
        this.setState({ buyers_agent });
    }

    cancelEditBuyersAgent() {
        let buyers_agent = this.state.buyers_agent;
        const wasAdd = buyers_agent.add;
        buyers_agent.edit = false;
        buyers_agent.add = false;
        if (wasAdd) {
            buyers_agent.data = null;
        }
        this.setState({ buyers_agent });
    }

    clearBuyersAgent() {
        const { setBuyersAgent } = this.props.contactsActions;
        let buyers_agent = this.state.buyers_agent;
        buyers_agent.edit = false;
        buyers_agent.data = null;
        buyers_agent.validation = this.resetValidation(buyers_agent.validation);
        buyers_agent.preExisting = false;
        this.setState({ buyers_agent });
        setBuyersAgent(null);
    }

    newBuyersAgent() {
        let buyers_agent = this.state.buyers_agent;
        buyers_agent.edit = false;
        buyers_agent.add = true;
        buyers_agent.data = copyObject(this.props.object_models.agent);
        buyers_agent.validation = this.resetValidation(buyers_agent.validation);
        this.setState({ buyers_agent });
    }

    setNoBuyersAgent() {
        const { setBuyersAgent } = this.props.contactsActions;
        let buyers_agent = this.state.buyers_agent;
        const wasAgent = typeof buyers_agent.data === 'object' && buyers_agent.data;
        buyers_agent.none = true;
        buyers_agent.edit = false;
        buyers_agent.add = false;
        buyers_agent.data = null;
        buyers_agent.validation = this.resetValidation(buyers_agent.validation);
        this.setState({ buyers_agent });
        if (wasAgent) {
            setBuyersAgent(null);
        }
    }

    cancelNoBuyersAgent() {
        let buyers_agent = this.state.buyers_agent;
        buyers_agent.none = false;
        buyers_agent.edit = false;
        buyers_agent.add = false;
        buyers_agent.data = null;
        buyers_agent.validation = this.resetValidation(buyers_agent.validation);
        this.setState({ buyers_agent });
    }

    saveBuyersAgent() {
        return new Promise((resolve, reject) => {
            let buyers_agent = this.state.buyers_agent;

            if (!buyers_agent.data && this.props.order.user && this.props.order.user.type === 'BUYERS_AGENT') {
                buyers_agent.warning = true;
                this.setState({ buyers_agent });
                return reject(new Error('BUYERS_AGENT_FAILED_VALIDATION'));
            }

            if (!buyers_agent.data || (!buyers_agent.edit && !buyers_agent.add)) {
                // bail out if we are in non-edit mode
                return resolve();
            }

            for (let p in buyers_agent.validation) {
                if (buyers_agent.validation.hasOwnProperty(p)) {
                    buyers_agent.validation[p] = this.validateField(p, buyers_agent.data[p]);
                }
            }

            this.setState({ buyers_agent }, () => {
                const { setBuyersAgent } = this.props.contactsActions;
                const keys = Object.keys(buyers_agent.validation);
                const validated = keys.filter((key) => {
                    return buyers_agent.validation[key] === true;
                });

                if (validated.length === keys.length) {
                    buyers_agent.edit = false;
                    buyers_agent.add = false;
                    buyers_agent.saving = true;
                    buyers_agent.warning = false;
                    buyers_agent.preExisting = false;
                    buyers_agent.validation = this.resetValidation(buyers_agent.validation);
                    this.setState({ buyers_agent });
                    setBuyersAgent(buyers_agent.data)
                        .then((agent) => {
                            if (agent) {
                                buyers_agent.data.id = agent.id;
                            }
                            buyers_agent.saving = false;
                            this.setState({ buyers_agent });
                            resolve();
                        });
                } else {
                    // focus first failed field
                    let first = null;
                    for (let p in buyers_agent.validation) {
                        if (buyers_agent.validation.hasOwnProperty(p) && buyers_agent.validation[p] === false) {
                            first = p;
                            break;
                        }
                    }
                    if (first) {
                        this['ref_buyers_agent_' + first].focus();
                    }
                    reject(new Error('BUYERS_AGENT_FAILED_VALIDATION'));
                }
            });
        });
    }
    /* BUYER'S AGENT END */

    /* SELLER'S AGENT BEGIN */
    selectSellersAgent(agent) {
        const { setSellersAgent } = this.props.contactsActions;
        let sellers_agent = this.state.sellers_agent;
        sellers_agent.data = agent;
        sellers_agent.warning = !(agent && typeof agent === 'object');
        sellers_agent.preExisting = true;
        this.setState({ sellers_agent });
        setSellersAgent(agent);
    }

    editSellersAgent() {
        let sellers_agent = this.state.sellers_agent;
        sellers_agent.edit = true;
        this.setState({ sellers_agent });
    }

    cancelEditSellersAgent() {
        let sellers_agent = this.state.sellers_agent;
        const wasAdd = sellers_agent.add;
        sellers_agent.edit = false;
        sellers_agent.add = false;
        if (wasAdd) {
            sellers_agent.data = null;
        }
        this.setState({ sellers_agent });
    }

    clearSellersAgent() {
        const { setSellersAgent } = this.props.contactsActions;
        let sellers_agent = this.state.sellers_agent;
        sellers_agent.none = false;
        sellers_agent.edit = false;
        sellers_agent.data = null;
        sellers_agent.validation = this.resetValidation(sellers_agent.validation);
        sellers_agent.preExisting = false;
        this.setState({ sellers_agent });
        setSellersAgent(null);
    }

    newSellersAgent() {
        let sellers_agent = this.state.sellers_agent;
        sellers_agent.none = false;
        sellers_agent.edit = false;
        sellers_agent.add = true;
        sellers_agent.data = copyObject(this.props.object_models.agent);
        sellers_agent.validation = this.resetValidation(sellers_agent.validation);
        this.setState({ sellers_agent });
    }

    setNoSellersAgent() {
        const { setSellersAgent } = this.props.contactsActions;
        let sellers_agent = this.state.sellers_agent;
        const wasAgent = typeof sellers_agent.data === 'object' && sellers_agent.data;
        sellers_agent.none = true;
        sellers_agent.edit = false;
        sellers_agent.add = false;
        sellers_agent.data = null;
        sellers_agent.validation = this.resetValidation(sellers_agent.validation);
        this.setState({ sellers_agent });
        if (wasAgent) {
            setSellersAgent(null);
        }
    }

    cancelNoSellersAgent() {
        let sellers_agent = this.state.sellers_agent;
        sellers_agent.none = false;
        sellers_agent.edit = false;
        sellers_agent.add = false;
        sellers_agent.data = null;
        sellers_agent.validation = this.resetValidation(sellers_agent.validation);
        this.setState({ sellers_agent });
    }

    saveSellersAgent() {
        return new Promise((resolve, reject) => {
            let sellers_agent = this.state.sellers_agent;

            if (!sellers_agent.data && this.props.order.user && this.props.order.user.type === 'SELLERS_AGENT') {
                sellers_agent.warning = true;
                this.setState({ sellers_agent });
                return reject(new Error('SELLERS_AGENT_FAILED_VALIDATION'));
            }

            if (!sellers_agent.data || (!sellers_agent.edit && !sellers_agent.add)) {
                // bail out if we are in non-edit mode
                return resolve();
            }

            for (let p in sellers_agent.validation) {
                if (sellers_agent.validation.hasOwnProperty(p)) {
                    sellers_agent.validation[p] = this.validateField(p, sellers_agent.data[p]);
                }
            }

            this.setState({ sellers_agent }, () => {
                const { setSellersAgent } = this.props.contactsActions;
                const keys = Object.keys(sellers_agent.validation);
                const validated = keys.filter((key) => {
                    return sellers_agent.validation[key] === true;
                });

                if (validated.length === keys.length) {
                    sellers_agent.edit = false;
                    sellers_agent.add = false;
                    sellers_agent.saving = true;
                    sellers_agent.warning = false;
                    sellers_agent.preExisting = false;
                    sellers_agent.validation = this.resetValidation(sellers_agent.validation);
                    this.setState({ sellers_agent });
                    setSellersAgent(sellers_agent.data)
                        .then((agent) => {
                            if (agent) {
                                sellers_agent.data.id = agent.id;
                            }
                            sellers_agent.saving = false;
                            this.setState({ sellers_agent });
                            resolve();
                        });
                } else {
                    // focus first failed field
                    let first = null;
                    for (let p in sellers_agent.validation) {
                        if (sellers_agent.validation.hasOwnProperty(p) && sellers_agent.validation[p] === false) {
                            first = p;
                            break;
                        }
                    }
                    if (first) {
                        this['ref_sellers_agent_' + first].focus();
                    }
                    reject(new Error('SELLERS_AGENT_FAILED_VALIDATION'));
                }
            });
        });
    }
    /* SELLER'S AGENT END */

    /* INSURANCE AGENT BEGIN */
    selectInsuranceAgent(agent) {
        const { setInsuranceAgent } = this.props.contactsActions;
        let insurance_agent = this.state.insurance_agent;
        insurance_agent.data = agent;
        insurance_agent.warning = !(agent && typeof agent === 'object');
        insurance_agent.preExisting = true;
        this.setState({ insurance_agent });
        setInsuranceAgent(agent);
    }

    editInsuranceAgent() {
        let insurance_agent = this.state.insurance_agent;
        insurance_agent.edit = true;
        this.setState({ insurance_agent });
    }

    cancelEditInsuranceAgent() {
        let insurance_agent = this.state.insurance_agent;
        const wasAdd = insurance_agent.add;
        insurance_agent.edit = false;
        insurance_agent.add = false;
        if (wasAdd) {
            insurance_agent.data = null;
        }
        this.setState({ insurance_agent });
    }

    clearInsuranceAgent() {
        const { setInsuranceAgent } = this.props.contactsActions;
        let insurance_agent = this.state.insurance_agent;
        insurance_agent.none = false;
        insurance_agent.edit = false;
        insurance_agent.data = null;
        insurance_agent.preExisting = false;
        insurance_agent.validation = this.resetValidation(insurance_agent.validation);
        this.setState({ insurance_agent });
        setInsuranceAgent(null);
    }

    newInsuranceAgent() {
        let insurance_agent = this.state.insurance_agent;
        insurance_agent.edit = false;
        insurance_agent.add = true;
        insurance_agent.data = copyObject(this.props.object_models.insurance_agent);
        insurance_agent.validation = this.resetValidation(insurance_agent.validation);
        this.setState({ insurance_agent });
    }

    setNoInsuranceAgent() {
        const { setInsuranceAgent } = this.props.contactsActions;
        let insurance_agent = this.state.insurance_agent;
        const wasAgent = typeof insurance_agent.data === 'object' && insurance_agent.data;
        insurance_agent.none = true;
        insurance_agent.edit = false;
        insurance_agent.add = false;
        insurance_agent.data = null;
        insurance_agent.validation = this.resetValidation(insurance_agent.validation);
        this.setState({ insurance_agent });
        if (wasAgent) {
            setInsuranceAgent(null);
        }
    }

    cancelNoInsuranceAgent() {
        let insurance_agent = this.state.insurance_agent;
        insurance_agent.none = false;
        insurance_agent.edit = false;
        insurance_agent.add = false;
        insurance_agent.data = null;
        insurance_agent.validation = this.resetValidation(insurance_agent.validation);
        this.setState({ insurance_agent });
    }

    saveInsuranceAgent() {
        return new Promise((resolve, reject) => {
            let insurance_agent = this.state.insurance_agent;

            if (!insurance_agent.data && this.props.order.user && this.props.order.user.type === 'INSURANCE_AGENT') {
                insurance_agent.warning = true;
                this.setState({ insurance_agent });
                return reject(new Error('INSURANCE_AGENT_FAILED_VALIDATION'));
            }

            if (!insurance_agent.data || (!insurance_agent.edit && !insurance_agent.add)) {
                // bail out if we are in non-edit mode
                return resolve();
            }

            for (let p in insurance_agent.validation) {
                if (insurance_agent.validation.hasOwnProperty(p)) {
                    insurance_agent.validation[p] = this.validateField(p, insurance_agent.data[p]);
                }
            }

            this.setState({ insurance_agent }, () => {
                const { setInsuranceAgent } = this.props.contactsActions;
                const keys = Object.keys(insurance_agent.validation);
                const validated = keys.filter((key) => {
                    return insurance_agent.validation[key] === true;
                });

                if (validated.length === keys.length) {
                    insurance_agent.edit = false;
                    insurance_agent.add = false;
                    insurance_agent.saving = true;
                    insurance_agent.warning = false;
                    insurance_agent.preExisting = false;
                    insurance_agent.validation = this.resetValidation(insurance_agent.validation);
                    this.setState({ insurance_agent });
                    setInsuranceAgent(insurance_agent.data)
                        .then((agent) => {
                            if (agent) {
                                insurance_agent.data.id = agent.id;
                            }
                            insurance_agent.saving = false;
                            this.setState({ insurance_agent });
                            resolve();
                        });
                } else {
                    // focus first failed field
                    let first = null;
                    for (let p in insurance_agent.validation) {
                        if (insurance_agent.validation.hasOwnProperty(p) && insurance_agent.validation[p] === false) {
                            first = p;
                            break;
                        }
                    }
                    if (first) {
                        this['ref_insurance_agent_' + first].focus();
                    }
                    reject(new Error('INSURANCE_AGENT_FAILED_VALIDATION'));
                }
            });
        });
    }
    /* INSURANCE AGENT END */

    /* CLIENT BEGIN */
    editClient() {
        let client = this.state.client;
        client.edit = true;
        this.setState({ client });
    }

    cancelEditClient() {
        let client = this.state.client;
        const wasAdd = client.add;
        client.edit = false;
        client.add = false;
        if (wasAdd) {
            client.data = null;
        }
        this.setState({ client });
    }

    clearClient() {
        const { setClient } = this.props.contactsActions;
        let client = this.state.client;
        client.edit = false;
        client.add = true;
        client.data = null;
        client.validation = this.resetValidation(client.validation);
        this.setState({ client });
        setClient(null);
    }

    newClient() {
        let client = this.state.client;
        client.edit = false;
        client.add = true;
        client.data = copyObject(this.props.object_models.client);
        client.validation = this.resetValidation(client.validation);
        this.setState({ client });
    }

    saveClient() {
        return new Promise((resolve, reject) => {
            let client = this.state.client;

            if (!client.edit && !client.add) {
                // bail out if we are in non-edit mode
                return resolve();
            }

            for (let p in client.validation) {
                if (client.validation.hasOwnProperty(p)) {
                    client.validation[p] = this.validateField(p, client.data[p]);
                }
            }

            this.setState({ client }, () => {
                const { setClient } = this.props.contactsActions;
                const keys = Object.keys(client.validation);
                const validated = keys.filter((key) => {
                    return client.validation[key] === true;
                });

                if (validated.length === keys.length) {
                    client.edit = false;
                    client.add = false;
                    client.saving = true;
                    client.validation = this.resetValidation(client.validation);
                    this.setState({ client });
                
                    // Filter out non-empty values before passing to setClient
                    const nonEmptyClients = {};
                    Object.keys(client.data).forEach(key => {
                        const value = client.data[key];
                        if (value !== null && value !== "" && value !== undefined) {
                            nonEmptyClients[key] = value;
                        }
                    });
                
                    setClient(nonEmptyClients)
                        .then(() => {
                            client.saving = false;
                            this.setState({ client });
                            resolve();
                        });
                }
                
                 else {
                    // focus first failed field
                    let first = null;
                    for (let p in client.validation) {
                        if (client.validation.hasOwnProperty(p) && client.validation[p] === false) {
                            first = p;
                            break;
                        }
                    }
                    if (first) {
                        this['ref_client_' + first].focus();
                    }
                    reject(new Error('CLIENT_FAILED_VALIDATION'));
                }
            });
        });
    }
    /* CLIENT END */

    /* CONTACT BEGIN */
    createContactObject(contact) {
        let new_contact = copyObject(this.state.contact);
        new_contact.data = contact;
        return new_contact;
    }

    selectContact(contact) {
        const { addContact } = this.props.contactsActions;
        let contacts = this.state.contacts;
        for (let i = 0, l = contacts.length; i < l; i++) {
            if (contacts[i].data.id === '') {
                contacts[i].search = false;
                contacts[i].data = contact;
                break;
            }
        }
        this.setState({ contacts, add_contact: false });
        addContact(contact);
    }

    editContact(index) {
        let contacts = this.state.contacts;
        let contact = contacts[index];
        if (contact) {
            contact.edit = true;
            this.setState({ contacts });
        }
    }

    cancelEditContact(index) {
        let contacts = this.state.contacts;
        let contact = contacts[index];
        if (contact) {
            const wasAdd = contact.add;
            contact.edit = false;
            contact.add = false;
            if (wasAdd) {
                contacts.splice(index, 1);
            }
            this.setState({ contacts, add_contact: false });
        }
    }

    clearContact(index) {
        const { removeContact } = this.props.contactsActions;
        let contacts = this.state.contacts;
        const removed = contacts.splice(index, 1);
        this.setState({ contacts });
        if (removed[0].data.id !== '') {
            removeContact(removed[0].data);
        }
    }

    newContact(bWithSearch = false) {
        let contacts = this.state.contacts;
        let new_contact = contacts.find((obj) => { return obj.data.id === ''; });
        if (!new_contact) {
            new_contact = this.createContactObject(copyObject(this.props.object_models.contact));
            new_contact.data = copyObject(this.props.object_models.contact);
            contacts.push(new_contact);
        }
        new_contact.edit = false;
        new_contact.add = !bWithSearch;
        new_contact.search = bWithSearch;
        this.setState({ contacts, add_contact: true });
    }

    setNoContact(index) {
        const { removeContact } = this.props.contactsActions;
        let contacts = this.state.contacts;
        const removed = contacts.splice(index, 1);
        this.setState({ contacts, add_contact: false });
        if (removed[0].data.id !== '') {
            removeContact(removed[0].data);
        }
    }

    saveContact(index) {
        return new Promise((resolve, reject) => {
            let contacts = this.state.contacts;
            let contact = contacts[index];

            for (let p in contact?.validation) {
                if (contact?.validation.hasOwnProperty(p)) {
                    if (contact.data.hasOwnProperty(p)) {
                        contact.validation[p] = this.validateField(p, contact.data[p]);
                    } 
                }
            }

            this.setState({ contacts }, () => {
                const { addContact } = this.props.contactsActions;
                const keys = Object.keys(contact.validation).filter(key => {
                    const value = contact.validation[key];
                    return value !== '' && value !== null && value !== undefined;
                });
                const validated = keys.filter((key) => {
                    return contact.validation[key] === true;
                });
                
                if (validated.length === keys.length) {
                    contact.edit = false;
                    contact.add = false;
                    contact.saving = true;
                    contact.validation = this.resetValidation(contact.validation);
                    this.setState({ contacts, add_contact: false });
                    addContact(contact.data)
                        .then(() => {
                            contact.saving = false;
                            this.setState({ contacts });
                            resolve();
                        });
                } else {
                    // focus first failed field
                    let first = null;
                    for (let p in contact.validation) {
                        if (contact.validation.hasOwnProperty(p) && contact.validation[p] === false) {
                            first = p;
                            break;
                        }
                    }
                    if (first) {
                        this['ref_contact_type_' + contact.data.type.id + '_' + first].focus();
                    }
                    reject(new Error('CONTACT_FAILED_VALIDATION'));
                }
            });
        });
    }

    setContactType(index, type) {
        let contacts = [...this.state.contacts];
        let contact = { ...contacts[index] };
        contact.data.type = type;
        contacts[index] = contact;
        this.setState({ contacts });
    }

    saveContacts() {
        let all = [];
        for (let i = 0, l = this.state.contacts.length; i < l; i++) {
            all.push(this.saveContact(i));
        }
        return Promise.all(all).catch((e) => { throw e; });
    }
    /* CONTACT END */

    save() {
        let valid = true;
        this.props.setNavLoading(true);
        this.setState({ saving: true });
        this.saveClient()
            .then(this.saveBuyersAgent)
            .then(this.saveSellersAgent)
            .then(this.saveInsuranceAgent)
            .then(this.saveContacts)
            .catch((e) => {
                valid = false;
            })
            .then(() => {
                if (valid) {
                    this.setState({ saving: false }, this.next);
                } else {
                    this.setState({ saving: false });
                    this.props.setNavLoading(false);
                }
            });
    }

    prev() {
        this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/address?t=' + this.props.token + '&office=' + this.props.office_uuid);
    }

    next() {
        if (this.props.order.controls.length > 0) {
            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/property-info?t=' + this.props.token + '&office=' + this.props.office_uuid);
        } else {
            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/packages?t=' + this.props.token + '&office=' + this.props.office_uuid);
        }
    }

    setup() {
        if (!this.state.setup) {

            // display fields
            let ba_display_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_BA_DISPLAY_FIELDS')
                .split(',').filter((item) => { return item !== '' });
            let sa_display_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_SA_DISPLAY_FIELDS')
                .split(',').filter((item) => { return item !== '' });
            let ia_display_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_IA_DISPLAY_FIELDS')
                .split(',').filter((item) => { return item !== '' });
            let client_display_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CLIENT_DISPLAY_FIELDS')
                .split(',').filter((item) => { return item !== '' });
            let contacts_display_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CONTACTS_DISPLAY_FIELDS')
                .split(',').filter((item) => { return item !== '' });

            // BA required fields
            const ba_required_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_BA_REQUIRED_FIELDS')
                .split(',')
                .reduce((result, item, index) => {
                    result[item] = null;
                    return result;
                }, {});

            let ba_validation = {
                first: null,
                last: null,
                ...ba_required_fields
            };

            for (let i in ba_validation) {
                if (ba_validation.hasOwnProperty(i) && !ba_display_fields.includes(i)) {
                    ba_display_fields.push(i);
                }
            }

            // SA required fields
            const sa_required_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_SA_REQUIRED_FIELDS')
                .split(',')
                .reduce((result, item, index) => {
                    result[item] = null;
                    return result;
                }, {});

            let sa_validation = {
                first: null,
                last: null,
                ...sa_required_fields
            };

            for (let i in sa_validation) {
                if (sa_validation.hasOwnProperty(i) && !sa_display_fields.includes(i)) {
                    sa_display_fields.push(i);
                }
            }

            // IA required fields
            const ia_required_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_IA_REQUIRED_FIELDS')
                .split(',')
                .reduce((result, item, index) => {
                    result[item] = null;
                    return result;
                }, {});

            let ia_validation = {
                first: null,
                last: null,
                ...ia_required_fields
            };

            for (let i in ia_validation) {
                if (ia_validation.hasOwnProperty(i) && !ia_display_fields.includes(i)) {
                    ia_display_fields.push(i);
                }
            }

            // client required fields
            const client_required_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CLIENT_REQUIRED_FIELDS')
                .split(',')
                .reduce((result, item, index) => {
                    result[item] = null;
                    return result;
                }, {});

            let client_validation = {
                first: null,
                last: null,
                ...client_required_fields
            };

            for (let i in client_validation) {
                if (client_validation.hasOwnProperty(i) && !client_display_fields.includes(i)) {
                    client_display_fields.push(i);
                }
            }

            // contact required fields
            const contact_required_fields = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CONTACTS_REQUIRED_FIELDS')
                .split(',')
                .reduce((result, item, index) => {
                    result[item] = null;
                    return result;
                }, {});

            let contact_validation = {
                first: null,
                last: null,
                ...contact_required_fields
            };

            for (let i in contact_validation) {
                if (contact_validation.hasOwnProperty(i) && !contacts_display_fields.includes(i)) {
                    contacts_display_fields.push(i);
                }
            }

            if (ba_display_fields.length === 0) {
                let ba_required_fields_array = Object.keys(ba_required_fields);
                if (ba_required_fields_array.length > 0) {
                    ba_display_fields = ba_required_fields_array;
                }
            }

            if (sa_display_fields.length === 0) {
                let sa_required_fields_array = Object.keys(sa_required_fields);
                if (sa_required_fields_array.length > 0) {
                    sa_display_fields = sa_required_fields_array;
                }
            }

            if (ia_display_fields.length === 0) {
                let ia_required_fields_array = Object.keys(ia_required_fields);
                if (ia_required_fields_array.length > 0) {
                    ia_display_fields = ia_required_fields_array;
                }
            }

            if (client_display_fields.length === 0) {
                let client_required_fields_array = Object.keys(client_required_fields);
                if (client_required_fields_array.length > 0) {
                    client_display_fields = client_required_fields_array;
                }
            }

            if (contacts_display_fields.length === 0) {
                let contacts_required_fields_array = Object.keys(contact_required_fields);
                if (contacts_required_fields_array.length > 0) {
                    contacts_display_fields = contacts_required_fields_array;
                }
            }

            const contact = {
                edit: false,
                add: false,
                search: false,
                saving: false,
                validation: contact_validation,
                data: null
            };

            let contact_types_to_display = this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CONTACT_TYPES').split(',').filter((item) => {
                return item !== '';
            });

            let contacts = [];
            for (let i = 0, l = this.props.order.contacts.length; i < l; i++) {
                let new_contact = copyObject(contact);
                new_contact.data = this.props.order.contacts[i];
                contacts.push(new_contact);
            }

            this.setState({
                user_other: {
                    prompt: false,
                    value: this.props.order.user && this.props.order.user.type === 'OTHER' ? this.props.order.user.other : ''
                }
            });

            this.setState({
                display_fields: {
                    buyers_agent: ba_display_fields,
                    sellers_agent: sa_display_fields,
                    insurance_agent: ia_display_fields,
                    client: client_display_fields,
                    contacts: contacts_display_fields
                }
            });

            this.setState({
                buyers_agent: {
                    edit: false,
                    add: false,
                    saving: false,
                    none: false,
                    validation: ba_validation,
                    preExisting: (this.props.order.buyers_agent && typeof this.props.order.buyers_agent === 'object'),
                    data: this.props.order.buyers_agent
                }
            });

            this.setState({
                sellers_agent: {
                    edit: false,
                    add: false,
                    saving: false,
                    none: false,
                    validation: sa_validation,
                    preExisting: (this.props.order.sellers_agent && typeof this.props.order.sellers_agent === 'object'),
                    data: this.props.order.sellers_agent
                }
            });

            this.setState({
                insurance_agent: {
                    edit: false,
                    add: false,
                    saving: false,
                    none: false,
                    validation: ia_validation,
                    preExisting: (this.props.order.insurance_agent && typeof this.props.order.insurance_agent === 'object'),
                    data: this.props.order.insurance_agent
                }
            });

            this.setState({
                client: {
                    edit: false,
                    add: !this.props.order.client || !this.props.order.client.hasOwnProperty('id'),
                    saving: false,
                    validation: client_validation,
                    data: this.props.order.client || copyObject(this.props.object_models.client)
                }
            });

            this.setState({
                contact,
                contact_types_to_display,
                contact_validation,
                contacts,
                setup: true
            });
        }

        Utils.scrollTop(500);
        this.props.setNavContacts(true);
        this.props.setNavLoading(false);
    }

    componentDidMount() {
        // run if we got here from an existing state
        if (canSetupOnPageLoad(this.props)) {
            this.setup();
        }
    }

    componentWillReceiveProps(nextProps) {
        // run if we got here from a new state (i.e. new page load)
        if (canSetupOnExistingState(this.props, nextProps)) {
            this.setup();
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };


    render() {
        let content = null;
        const { classes } = this.props;
        const dropDownStyle = {
            right: '12px',
            top: 'calc(42% - 12px)', 
            width: '30px', 
            height: '30px', 
            position: 'absolute',
            pointerEvents: 'none',
        };
        if (this.state.setup) {
            const userIsBuyersAgent = this.props.order.user && this.props.order.user.type === 'BUYERS_AGENT';
            const userIsSellersAgent = this.props.order.user && this.props.order.user.type === 'SELLERS_AGENT';
            const userIsInsuranceAgent = this.props.order.user && this.props.order.user.type === 'INSURANCE_AGENT';
            const userIsClient = this.props.order.user && this.props.order.user.type && ['CLIENT_HOMEOWNER', 'CLIENT_BUYER'].indexOf(this.props.order.user.type) !== -1;

            const buyersAgentProps = {
                type: 'buyers_agent',
                label: 'Buyer\'s Agent',
                fields: this.state.display_fields.buyers_agent,
                userIsAgent: userIsBuyersAgent,
                order: this.props.order,
                flags: {
                    add: this.state.buyers_agent.add,
                    edit: this.state.buyers_agent.edit,
                    none: this.state.buyers_agent.none,
                    preExisting: this.state.buyers_agent.preExisting
                },
                data: this.state.buyers_agent.data,
                validation: this.state.buyers_agent.validation,
                warning: this.state.buyers_agent.warning,
                select: this.selectBuyersAgent,
                edit: this.editBuyersAgent,
                add: this.newBuyersAgent,
                remove: this.clearBuyersAgent,
                none: this.setNoBuyersAgent,
                cancelNone: this.cancelNoBuyersAgent,
                save: this.saveBuyersAgent,
                cancel: this.cancelEditBuyersAgent,
                update: this.updateTextField,
                setRef: this.setRef,
                states: this.props.states,
                allowEdit: !this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_DO_NOT_ALLOW_EDIT_AGENTS_INFO')
            };

            const sellersAgentProps = {
                type: 'sellers_agent',
                label: 'Seller\'s Agent',
                fields: this.state.display_fields.sellers_agent,
                userIsAgent: userIsSellersAgent,
                order: this.props.order,
                flags: {
                    add: this.state.sellers_agent.add,
                    edit: this.state.sellers_agent.edit,
                    none: this.state.sellers_agent.none,
                    preExisting: this.state.sellers_agent.preExisting
                },
                data: this.state.sellers_agent.data,
                validation: this.state.sellers_agent.validation,
                warning: this.state.sellers_agent.warning,
                select: this.selectSellersAgent,
                edit: this.editSellersAgent,
                add: this.newSellersAgent,
                remove: this.clearSellersAgent,
                none: this.setNoSellersAgent,
                cancelNone: this.cancelNoSellersAgent,
                save: this.saveSellersAgent,
                cancel: this.cancelEditSellersAgent,
                update: this.updateTextField,
                setRef: this.setRef,
                states: this.props.states,
                allowEdit: !this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_DO_NOT_ALLOW_EDIT_AGENTS_INFO')
            };

            const clientProps = {
                userIsClient: userIsClient,
                fields: this.state.display_fields.client,
                order: this.props.order,
                flags: { add: this.state.client.add, edit: this.state.client.edit },
                data: this.state.client.data,
                validation: this.state.client.validation,
                edit: this.editClient,
                add: this.newClient,
                remove: this.clearClient,
                save: this.saveClient,
                cancel: this.cancelEditClient,
                update: this.updateTextField,
                setRef: this.setRef,
                states: this.props.states
            };

            if ('' !== this.props.order.address1 && !this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_USE_INSPECTION_ADDRESS')) {
                clientProps.useSubjectProperty = () => {
                    let client = this.state.client;
                    if (!client.data.address1) {
                        client.data.address1 = this.props.order.address1;
                    }
                    if (!client.data.address2) {
                        client.data.address2 = this.props.order.address2;
                    }
                    if (!client.data.city) {
                        client.data.city = this.props.order.city;
                    }
                    if (!client.data.state) {
                        client.data.state = this.props.order.state;
                    }
                    if (!client.data.postal_code) {
                        client.data.postal_code = this.props.order.postal_code;
                    }
                    this.setState({ client });
                };
            }

            const insuranceAgentProps = {
                userIsAgent: userIsInsuranceAgent,
                fields: this.state.display_fields.insurance_agent,
                order: this.props.order,
                flags: {
                    add: this.state.insurance_agent.add,
                    edit: this.state.insurance_agent.edit,
                    none: this.state.insurance_agent.none,
                    preExisting: this.state.insurance_agent.preExisting
                },
                data: this.state.insurance_agent.data,
                validation: this.state.insurance_agent.validation,
                warning: this.state.insurance_agent.warning,
                select: this.selectInsuranceAgent,
                edit: this.editInsuranceAgent,
                add: this.newInsuranceAgent,
                remove: this.clearInsuranceAgent,
                none: this.setNoInsuranceAgent,
                cancelNone: this.cancelNoInsuranceAgent,
                save: this.saveInsuranceAgent,
                cancel: this.cancelEditInsuranceAgent,
                update: this.updateTextField,
                setRef: this.setRef,
                states: this.props.states,
                allowEdit: !this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_DO_NOT_ALLOW_EDIT_AGENTS_INFO')
            };

            let validContactTypes = this.props.contact_types.filter((type) => {
                return this.state.contact_types_to_display.indexOf(String(type.id)) !== -1
                    && !this.state.contacts.find((contact) => {
                        return !contact.add && !contact.search && contact.data.type && contact.data.type.id === type.id
                    });
            });

            validContactTypes.unshift({ id: '', name: '--please select---' });

            const createContactProps = (index) => {
                let contact = null;
                if (typeof index === 'undefined') {
                    contact = this.createContactObject(copyObject(this.props.object_models.contact));
                } else {
                    contact = this.state.contacts[index];
                }
                return {
                    fields: this.state.display_fields.contacts,
                    order: this.props.order,
                    types: validContactTypes,
                    flags: { edit: contact.edit, add: contact.add, search: contact.search },
                    data: contact.data,
                    validation: contact.validation,
                    select: this.selectContact,
                    setType: (e) => this.setContactType(index, validContactTypes.find((type) => {
                        return String(type.id) == e.target.value;
                    })),
                    add: () => {
                        this.newContact(false)
                    },
                    edit: () => {
                        this.editContact(index)
                    },
                    remove: () => {
                        this.clearContact(index)
                    },
                    none: () => {
                        this.setNoContact(index)
                    },
                    save: () => {
                        return this.saveContact(index)
                    },
                    cancel: () => {
                        this.cancelEditContact(index)
                    },
                    update: (e, type, field, validation) => this.updateTextField(e, contact, field, validation),
                    setRef: this.setRef,
                    states: this.props.states
                };
            };

            let counter = 1;

            const func = this.props.user_types.filter((user_type) => {
                return (
                    (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SELLERS_AGENT') || user_type.type !== 'SELLERS_AGENT')
                    &&
                    (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSURANCE_AGENT') || user_type.type !== 'INSURANCE_AGENT')
                    &&
                    (counter++)
                )
            })

            const selectedRole = func.find((user_type) => {
                if (user_type.type === this.props.order?.user?.type) {
                    // console.log("user_type", user_type);
                    return user_type;
                }
                return false;
            });

            const customDropDownIcon = () => (
                <div style={{position: 'absolute', right: '1rem'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id="arrow-drop-down" fill="rgba(0, 0, 0, 0.54)">
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path d="M7 10l5 5 5-5H7z"></path></svg>
                </div>
            )
        
            
            content = <div>
                <div className="text-left">
                    <h4 className="left-container-padding" style={{ paddingBottom: "14px" }}>Select your role:</h4>
                    <div className="col-md-10 col-lg-9 role-select-input" style={{ float: 'none', margin: '0 auto', display: 'flex' }}>
                        {counter <= 4 ? (
                            func.map((user_type, key) => {
                                return (<button key={key} type="button"
                                    className={"btn btn-default btn-sm" + (user_type.type == this.state.user_role ? ' selectedStyle' : '')}
                                    style={{ marginRight: "8px", width: "100%", padding: "15px", fontSize: "16px"}}
                                    onClick={() => {
                                        this.setUserRole(user_type)
                                    }}>
                                    {user_type.text}
                                </button>)
                            })
                        ) :
                            <div style={{width: "100%"}}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel shrink style={{  fontSize: 22,background: "white",paddingRight: 5,position: "absolute",top: "-3px",fontFamily: 'Source Sans Pro',}}>Your role</InputLabel>
                            <Select label="Your role"
                                // IconComponent={customDropDownIcon}
                                MenuProps={{
                                  autoFocus: false,
                                  disableAutoFocusItem: true,
                                  disableEnforceFocus: true,
                                  disableAutoFocus: true,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                  },
                                  getContentAnchorEl: null
                              }}
                                disableAutoFocus
                                disableAutoFocusItem
                                value={this.state.user_role_text || selectedRole}
                                style={{ marginRight: "1%" , fontSize: "16px"}}
                                // inputProps={{
                                //     classes: {
                                //       icon: dropDownStyle,
                                //     },
                                //   }}
                                inputProps={{
                                    classes: {
                                      icon: classes.icon,
                                    },
                                  }}
                                >
                                {func.map((user_type, key) => {
                                return (<MenuItem key={key} type="button"
                                    value={user_type}
                                    style={{fontSize : "16px"}}
                                    onClick={() => {
                                        this.setUserRole(user_type)
                                    }}>
                                    {user_type.text}
                                </MenuItem>)
                            })}
                            </Select>
                            {/* <FormHelperText>Select a month</FormHelperText> */}
                        </FormControl>
                            </div>
                        }
                    </div>
                </div>  

                {this.props.order.user && userIsClient
                    &&
                    <div>
                        <ClientForm {...clientProps} />
                        <AgentForm {...buyersAgentProps} />
                        {this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SELLERS_AGENT')
                            && <AgentForm {...sellersAgentProps} />}
                        {this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSURANCE_AGENT')
                            && <InsuranceAgentForm {...insuranceAgentProps} />}
                    </div>}

                {this.props.order.user && userIsBuyersAgent
                    &&
                    <div>
                        <AgentForm {...buyersAgentProps} />
                        <ClientForm {...clientProps} />
                        {this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SELLERS_AGENT')
                            && <AgentForm {...sellersAgentProps} />}
                        {this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSURANCE_AGENT')
                            && <InsuranceAgentForm {...insuranceAgentProps} />}
                    </div>}

                {this.props.order.user && userIsSellersAgent
                    &&
                    <div>
                        <AgentForm {...sellersAgentProps} />
                        <ClientForm {...clientProps} />
                        <AgentForm {...buyersAgentProps} />
                        {this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSURANCE_AGENT')
                            && <InsuranceAgentForm {...insuranceAgentProps} />}
                    </div>}

                {this.props.order.user && userIsInsuranceAgent
                    &&
                    <div>
                        <InsuranceAgentForm {...insuranceAgentProps} />
                        <ClientForm {...clientProps} />
                        <AgentForm {...buyersAgentProps} />
                        {this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SELLERS_AGENT')
                            && <AgentForm {...sellersAgentProps} />}
                    </div>}

                {this.props.order.user && this.state.contact_types_to_display.length > 0
                    && <div>
                        {this.state.contacts.map((contact, index) => {
                            return <ContactForm key={index} {...createContactProps(index)} />
                        })}
                        {!this.state.add_contact && <AddContact search={() => {
                            this.newContact(true)
                        }} />}
                    </div>}

                    <div className="col-md-12 action-buttons-space">
                        <BackButton back={this.prev} className="back-btn-class" />
                        <NextButton next={this.save} disabled={!this.props.order.user} className={this.state?.saving || !this.props.order.user ? !this.props.order.user ? 'next-btn-class-dis' : 'hide-button' : 'next-btn-class show-button'}/>
                        <button className={this.state?.saving  ? 'next-btn-class show-button' : 'hide-button'} >&nbsp;<i className="fa fa-spinner fa-spin"></i>&nbsp;</button>
                    </div>

            </div>;
        }

        return (
            <div>
                {!this.props.app.office_loading && !this.props.app.order_loading && <FixedSideBar {...this.props} />}
                <Container title="People associated with this inspection" {...this.props}>
                    {content}
                </Container>
            </div>);
    }
}

/**
 *
 */
export default withStyles(styles)(
    withRouter(
        connect(
            (state, ownProps) => ({
                router: ownProps,
                ...state.Office,
                order: state.Order,
                nav: state.Nav,
                app: state.App,
            }),
            (dispatch) => ({
                contactsActions: bindActionCreators(ContactsActions, dispatch),
                orderActions: bindActionCreators(OrderActions, dispatch),
                officeActions: bindActionCreators(OfficeActions, dispatch),
                setNavContacts: (value) => dispatch(setNavContacts(value)),
                setNavLoading: (value) => dispatch(setNavLoading(value)),
            })
        )(ContactsContainer)
    )
);