import React, { Component } from 'react'
import { asyncContainer, Typeahead, Highlighter } from 'react-bootstrap-typeahead'
import { app_state } from '../app_data.jsx'
import request from 'superagent'
import PersonEditForm from './PersonEditForm.jsx'
import PersonViewForm from './PersonViewForm.jsx'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper } from '@material-ui/core';
import "./contactinfo.css";

const styles = {
    autocomplete: {
      minWidth: '104%',
    },
    inputLabel: {
        fontSize: 22,
        background: "white",
        paddingRight: 5,
        position: "absolute",
        top: "-3px",
        fontFamily: 'Source Sans Pro',
    //   '&.Mui-focused': { // Add the focused style here
    //     color: '#0F75BC',
    //    },
    },
    input: {
        style: {
            fontSize: 16,
        }
      }
  };

const ResetIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" fill="rgb(117, 117, 117)" viewBox="0 -960 960 960" width="20"><path d="m448-326 112-112 112 112 43-43-113-111 111-111-43-43-110 112-112-112-43 43 113 111-113 111 43 43ZM120-480l169-239q13-18 31-29.5t40-11.5h420q24.75 0 42.375 17.625T840-700v440q0 24.75-17.625 42.375T780-200H360q-22 0-40-11.5T289-241L120-480Zm75 0 154 220h431v-440H349L195-480Zm585 0v-220 440-220Z"/></svg>
)

const AsyncTypeahead = asyncContainer(Typeahead);

const CompanyFormView = (props) => (
    <div className={'form-group'}>
        <label className="control-label col-md-3 col-lg-2">Company:</label>
        <div className="col-md-8 col-lg-6">
            <p className="form-control-static">{props.company.value}</p>
        </div>
    </div>
);

export class InsuranceAgentView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <PersonViewForm {...this.props}>
                    {(!this.props.hasOwnProperty('compact') || !this.props.compact) && <div className="form-group">
                        <label className="col-sm-2 col-md-4 control-label">Company:</label>
                        <div className="col-sm-10 col-md-8">
                            <p className="form-control-static">{this.props.company}</p>
                        </div>
                    </div>}
                    {this.props.hasOwnProperty('compact') && this.props.compact && <div>{this.props.company}</div>}
                </PersonViewForm>
            </div>
        )
    }
}

export class InsuranceAgentEditView extends Component {
    constructor(props) {
        super(props);

        this.updateFirstName = this.updateFirstName.bind(this);
        this.updateLastName = this.updateLastName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateMobilePhone = this.updateMobilePhone.bind(this);
        this.updateWorkPhone = this.updateWorkPhone.bind(this);
        this.updateHomePhone = this.updateHomePhone.bind(this);
        this.updateWorkFax = this.updateWorkFax.bind(this);
        this.updateHomeFax = this.updateHomeFax.bind(this);
        this.updateAddress1 = this.updateAddress1.bind(this);
        this.updateAddress2 = this.updateAddress2.bind(this);
        this.updateCity = this.updateCity.bind(this);
        this.updateState = this.updateState.bind(this);
        this.updatePostalCode = this.updatePostalCode.bind(this);
    }

    updateFirstName(e) {
        this.props.updateTextField(e, 'insurance_agent', 'first', this.props.validation.hasOwnProperty('first'));
    }

    updateLastName(e) {
        this.props.updateTextField(e, 'insurance_agent', 'last', this.props.validation.hasOwnProperty('last'));
    }

    updateEmail(e) {
        this.props.updateTextField(e, 'insurance_agent', 'email', this.props.validation.hasOwnProperty('email'));
    }

    updateMobilePhone(e) {
        this.props.updateTextField(e, 'insurance_agent', 'mobile_phone', this.props.validation.hasOwnProperty('mobile_phone'));
    }

    updateWorkPhone(e) {
        this.props.updateTextField(e, 'insurance_agent', 'work_phone', this.props.validation.hasOwnProperty('work_phone'));
    }

    updateHomePhone(e) {
        this.props.updateTextField(e, 'insurance_agent', 'home_phone', this.props.validation.hasOwnProperty('home_phone'));
    }

    updateWorkFax(e) {
        this.props.updateTextField(e, 'insurance_agent', 'work_fax', this.props.validation.hasOwnProperty('work_fax'));
    }

    updateHomeFax(e) {
        this.props.updateTextField(e, 'insurance_agent', 'home_fax', this.props.validation.hasOwnProperty('home_fax'));
    }

    updateAddress1(e) {
        this.props.updateTextField(e, 'insurance_agent', 'address1', this.props.validation.hasOwnProperty('address1'));
    }

    updateAddress2(e) {
        this.props.updateTextField(e, 'insurance_agent', 'address2', this.props.validation.hasOwnProperty('address2'));
    }

    updateCity(e) {
        this.props.updateTextField(e, 'insurance_agent', 'city', this.props.validation.hasOwnProperty('city'));
    }

    updateState(e) {
        this.props.updateTextField(e, 'insurance_agent', 'state', this.props.validation.hasOwnProperty('state'));
    }

    updatePostalCode(e) {
        this.props.updateTextField(e, 'insurance_agent', 'postal_code', this.props.validation.hasOwnProperty('postal_code'));
    }

    render() {
        return (
            <div>
                <div className="form-horizontal container-left">
                    <PersonEditForm
                        ref_key="insurance_agent"
                        setRef={this.props.setRef}
                        fields={this.props.fields}
                        first={{value:this.props.first,update:this.updateFirstName}}
                        last={{value:this.props.last,update:this.updateLastName}}
                        email={{value:this.props.email,update:this.updateEmail}}
                        mobile_phone={{value:this.props.mobile_phone,update:this.updateMobilePhone}}
                        work_phone={{value:this.props.work_phone,update:this.updateWorkPhone}}
                        home_phone={{value:this.props.home_phone,update:this.updateHomePhone}}
                        work_fax={{value:this.props.work_fax,update:this.updateWorkFax}}
                        home_fax={{value:this.props.home_fax,update:this.updateHomeFax}}
                        address1={{value:this.props.address1,update:this.updateAddress1}}
                        address2={{value:this.props.address2,update:this.updateAddress2}}
                        city={{value:this.props.city,update:this.updateCity}}
                        state={{value:this.props.state,update:this.updateState}}
                        postal_code={{value:this.props.postal_code,update:this.updatePostalCode}}
                        validation={this.props.validation}
                        save={this.props.save}
                        states={this.props.states}>
                        {/* {!this.props.isNew && <CompanyFormView company={{value:this.props.company}}/>} */}
                    </PersonEditForm>
                </div>
                {/* <div className="text-center" style={{marginTop:'5px'}}>
                    <button type="button" className="btn btn-success btn-xs"
                            onClick={this.props.save}>
                        <i className="fas fa-save"/> Save
                    </button>
                    &nbsp;
                    <button type="button" className="btn btn-danger btn-xs"
                            onClick={this.props.cancel}>
                        <i className="fas fa-ban"/> Cancel
                    </button>
                </div> */}
            </div>
        )
    }
}

export class InsuranceAgentSearchView extends Component {
    constructor(props) {
        super(props);
        this.addNewInsuranceAgent = {
            display: "Add new agent",
            id: "",
            state: 1,
            address1: "",
            address2: "",
            agency: "",
            city: "",
            email: "",
            first: "",
            home_fax: "",
            home_phone: "",
            last: "",
            mobile_phone: "",
            postal_code: "",
            work_fax: "",
            work_phone: "",
            add_new: true,
        };
        // this.renderMenuItemChildren = this.renderMenuItemChildren.bind(this);
        // this.handleSelection = this.handleSelection.bind(this);
        // this.handleSearch = this.handleSearch.bind(this);
        // this.handleInputChange = this.handleInputChange.bind(this);

        this.state = {
            isLoading: false,
            options: [this.addNewInsuranceAgent],
            query: ''
        };
    }

    // renderMenuItemChildren(option, props, index) {
    //     return <div>
    //         <Highlighter key="name" search={props.text}>
    //             {option.display}
    //         </Highlighter>
    //         {option.company && option.company !== '' && option.company !== 'n/a' && <span style={{fontSize:'85%'}}> ({option.company})</span>}
    //     </div>;
    // }

    // handleSelection(items) {
    //     this.props.select(items[0]);
    // }

    // handleInputChange(query) {
    //     this.setState({query});
    // }

    // handleSearch(query) {
    //     this.setState({isLoading: true});
    //     request.get('/'+app_state.company_key+'/json/online_scheduler/search_insurance_agents?query='+query)
    //         .set('X-Auth-Token', app_state.token)
    //         .then((res) => {
    //             this.setState({
    //                 isLoading: false,
    //                 options: res.body
    //             });
    //         });
    // }

    // componentDidMount() {
    //     /*setTimeout(() => {
    //      this.ref_typeahead.getInstance().focus();
    //      }, this.props.focusDelay);*/
    // }

    onChangeFunc(e, value){
        if(this.state.query && !value?.add_new){
            this.props.select(value);
        }
        else{
            this.props.add();
        }
    }
   
    handleSearchFunc(e){
        console.log("Handle Search Func", e);
        this.setState({isLoading: true});
        request.get('/'+app_state.company_key+'/json/online_scheduler/search_insurance_agents?query='+e)
            .set('X-Auth-Token', app_state.token)
            // .on('error', (err) => errorHandler(err, dispatch))
            .then((res) => {
                // if(res.body != []){
                    let data = [...res.body,this.addNewInsuranceAgent];
                    this.setState({
                        isLoading: false,
                        options: data,
                        query: e
                    });
                // }
            });
    }

    render() {
        return (<>
        <Autocomplete
                    id="combo-box-demo"
                    freeSolo
                    options={this.state.options}
                    getOptionLabel={(option) => {
                        if(option?.display){
                            return option?.display;
                        }
                    }}
                    filterOptions={(options) => options}
                     style={styles.autocomplete}
                     renderOption={(option, { selected }) => {
                        if(option?.display == "Add new agent"){
                            return (
                                <li style={{color: "#3f51b5"}}>
                                  {option?.display}
                                </li>
                              );
                        }
                        else{
                            return (
                                <li>
                                  {option?.display}
                                </li>
                              );
                        }
                      }}
                    // onInputChange={this.handleInputChange}
                    renderInput={(params) => 
                    <TextField {...params} 
                    label={(this.props.me?'Search for your profile':'Search for your Insurance Agent')}
                    variant="outlined"
                    InputProps={{ ...params.InputProps, ...styles.input }}
                            InputLabelProps={{
                                style: styles.inputLabel,
                                shrink: true
                            }}
                    onKeyUp={(ev) => {
                        if (ev.target.value !== "" || ev.target.value !== null) {
                          this.handleSearchFunc(ev.target.value);
                        }
                      }}
                    value={this.state.query}
                    />}
                    onChange={(e, value) => this.onChangeFunc(e, value)}
                    PaperComponent={(props) => (
                        <Paper {...props} style={{
                            fontSize: "16px"
                        }} />
                      )}
                />
{/*         
        <AsyncTypeahead
            {...this.state}
            id={'insurance-agent-search'}
            ref={(el) => { this.ref_typeahead = el }}
            minLength={2}
            labelKey="display"
            onInputChange={this.handleInputChange}
            onSearch={this.handleSearch}
            onChange={this.handleSelection}
            placeholder={(this.props.me?'Search for your profile':'Search for your Insurance Agent')}
            useCache={true}
            renderMenuItemChildren={this.renderMenuItemChildren}
            filterBy={['first','last','display','email','mobile_phone']} 
        />*/}
            </>
        )
    }
}

export default class InsuranceAgentForm extends Component {
    constructor(props) {
        super(props);

        this.save = this.save.bind(this);
    }

    save()
    {
        this.props.save()
            .then(null, (e) => null)
    }

    componentDidUpdate()
    {
        if (this.props.warning) {
            window.scrollTo(0, this['ref_agent_warning'].offsetTop);
        }
    }

    render() {
        return (
            <div className="contact-form">
                {this.props.warning && <div className={'alert alert-danger alert-tight'} style={{marginTop:'25px'}}
                                            ref={(el) => { this['ref_agent_warning'] = el; }}>
                    <p className={'lead'} style={{fontWeight:'bold'}}>Please search for or add your agent profile!</p>
                </div>}
                <div className="panel">
                    <div>
                        {this.props.flags.none
                            ? <div>
                                No Insurance Agent
                                <div className="pull-right">
                                    <button type="button" className="btn btn-default btn-xs"
                                            onClick={this.props.cancelNone}>
                                        Change
                                    </button>
                                </div>
                                <div style={{clear:'both'}}/>
                            </div>
                            : <div className="left-container-padding" style={{ fontSize:'18px', fontWeight: '600',display:'flex', justifyContent: 'space-between'}}>
                                {this.props.flags.edit ? 'Edit ' : ''}
                                {this.props.flags.add ? 'Add ' : ''}
                                Insurance Agent{this.props.userIsAgent ? ' (me)' : ''}
                                {this.props.data ? ( <div style={{paddingRight: '22%', cursor: "pointer"}} onClick={this.props.cancelNone}>
                                  <span style={{fontSize: '16px', color: '#757575'}}> Reset </span>   
                                  <span style={{position: 'relative', top: '5px'}}><ResetIcon/></span>
                                </div>) : null}
                            </div>
                        }
                    </div>
                    {!this.props.flags.none && <div className="panel-body">
                        {this.props.data === null && !this.props.flags.edit && !this.props.flags.add
                        &&
                        <div className="row">
                             <div className="col-lg-8 agent-container-style">
                                <InsuranceAgentSearchView type={this.props.type} me={this.props.userIsAgent}
                                                 select={this.props.select}
                                                 focusDelay={500} add={this.props.add}/>
                                <div className="btn-toolbar text-center" style={{marginTop: '6px'}}>
                                    {/* <button type="button" className="btn btn-primary btn-xs"
                                            style={{float: 'none'}}
                                            onClick={this.props.add}>
                                        <i className="far fa-plus-circle"/> New Profile
                                    </button>
                                    {!this.props.userIsAgent && <button type="button" className="btn btn-danger btn-xs"
                                                                        style={{float: 'none'}}
                                                                        onClick={this.props.none}>
                                        <i className="fa fa-remove"/> No Insurance Agent
                                    </button>} */}
                                </div>
                            </div>
                        </div>}

                        {this.props.data !== null && !this.props.flags.edit && !this.props.flags.add
                        && <InsuranceAgentEditView {...this.props.data}
                        fields={this.props.fields}
                        setRef={this.props.setRef}
                        userIsAgent={this.props.userIsAgent}
                        isNew={false}
                        updateTextField={this.props.update}
                        validation={this.props.validation}
                        save={this.save}
                        cancel={this.props.cancel}
                        states={this.props.states}/>
                        }
                        {this.props.flags.edit && <InsuranceAgentEditView {...this.props.data}
                                                                 fields={this.props.fields}
                                                                 setRef={this.props.setRef}
                                                                 userIsAgent={this.props.userIsAgent}
                                                                 isNew={false}
                                                                 updateTextField={this.props.update}
                                                                 validation={this.props.validation}
                                                                 save={this.save}
                                                                 cancel={this.props.cancel}
                                                                 states={this.props.states}/>
                        }
                        {this.props.flags.add && <InsuranceAgentEditView {...this.props.data}
                                                                fields={this.props.fields}
                                                                setRef={this.props.setRef}
                                                                userIsAgent={this.props.userIsAgent}
                                                                isNew={true}
                                                                updateTextField={this.props.update}
                                                                validation={this.props.validation}
                                                                save={this.save}
                                                                cancel={this.props.cancel}
                                                                states={this.props.states}/>
                        }
                    </div>}
                    {/* {this.props.data !== null && !this.props.flags.none && !this.props.flags.edit && !this.props.flags.add
                    && <div className="panel-footer">
                        <div className="btn-toolbar text-center">
                            {((!this.props.allowEdit && !this.props.flags.preExisting) || (this.props.allowEdit)) &&
                                <button type="button" className="btn btn-default btn-xs"
                                        style={{float:'none'}}
                                        onClick={this.props.edit}>
                                    <i className="fas fa-pencil"/> Edit
                                </button>
                            }
                            <button type="button" className="btn btn-primary btn-xs"
                                    style={{float:'none'}}
                                    onClick={this.props.add}>
                                <i className="fa fa-plus-circle"/> New Agent
                            </button>
                            <button type="button" className="btn btn-danger btn-xs"
                                    style={{float:'none'}}
                                    onClick={this.props.remove}>
                                <i className="fa fa-remove"/> Remove Agent
                            </button>
                        </div>
                    </div>} */}
                </div>
            </div>
        )
    }
}
