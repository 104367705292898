import {
    SET_SAVED,
    SET_DATETIME,
    SET_DURATION,
    SET_INSPECTORS,
    SET_ADDRESS1,
    SET_ADDRESS2,
    SET_CITY,
    SET_STATE,
    SET_POSTAL_CODE,
    SET_LATITUDE,
    SET_LONGITUDE,
    SET_COUNTY,
    SET_SQFT,
    SET_YB,
    SET_FOUNDATION_TYPE,
    SET_SALES_PRICE,
    SET_TEXT_CONTROL,
    SET_TEXTBOX_CONTROL,
    SET_CHECKBOX_CONTROL,
    SET_DROPDOWN_CONTROL,
    SET_TRIGGERED_DROPDOWN_CONTROL,
    SET_TRIGGERED_TEXTBOX_CONTROL,
    SET_DATE_CONTROL,
    SET_CLOSING_DATE_CONTROL,
    SET_PACKAGES,
    SET_SERVICES,
    SET_PRICING,
    SET_PRICING_LOADING,
    SET_USER,
    SET_BUYERS_AGENT,
    SET_SELLERS_AGENT,
    SET_CLIENT,
    SET_INSURANCE_AGENT,
    SET_CONTACTS,
    SET_CONFIRMED,
    SET_PAY_URL,
    SET_ORDER_ALL,
    SET_RUSH_RER,
    SET_REFER_REASON,
    SET_REFER_REASON_OTHER
} from '../constants.jsx'

export function setOrderAll(value) {
    return { type: SET_ORDER_ALL, value }
}

export function setSaved(value) {
    return { type: SET_SAVED, value }
}

export function setDatetime(value) {
    return { type: SET_DATETIME, value }
}

export function setDuration(value) {
    return { type: SET_DURATION, value }
}

export function setInspectors(value) {
    return { type: SET_INSPECTORS, value }
}

export function setAddress1(value) {
    return { type: SET_ADDRESS1, value }
}

export function setAddress2(value) {
    return { type: SET_ADDRESS2, value }
}

export function setCity(value) {
    return { type: SET_CITY, value }
}

export function setState(value) {
    return { type: SET_STATE, value }
}

export function setPostalCode(value) {
    return { type: SET_POSTAL_CODE, value }
}

export function setCounty(value) {
    return { type: SET_COUNTY, value }
}

export function setLatitude(value) {
    return { type: SET_LATITUDE, value }
}

export function setLongitude(value) {
    return { type: SET_LONGITUDE, value }
}

export function setSqft(value) {
    return { type: SET_SQFT, value }
}

export function setYb(value) {
    return { type: SET_YB, value }
}

export function setFoundationType(value) {
    return { type: SET_FOUNDATION_TYPE, value }
}

export function setSalesPrice(value) {
    return { type: SET_SALES_PRICE, value }
}

export function setTextControl(value, control_id) {
    return { type: SET_TEXT_CONTROL, value, control_id }
}

export function setTextboxControl(value, control_id) {
    return { type: SET_TEXTBOX_CONTROL, value, control_id }
}

export function setCheckboxControl(value, control_id) {
    return { type: SET_CHECKBOX_CONTROL, value, control_id }
}

export function setDropdownControl(value, control_id) {
    return { type: SET_DROPDOWN_CONTROL, value, control_id }
}

export function setTriggeredDropdownControl(value, control_id, checked) {
    return { type: SET_TRIGGERED_DROPDOWN_CONTROL, value, checked, control_id }
}

export function setTriggeredTextboxControl(value, control_id, checked) {
    return { type: SET_TRIGGERED_TEXTBOX_CONTROL, value, checked, control_id }
}

export function setDateControl(value, control_id) {
    return { type: SET_DATE_CONTROL, value, control_id }
}

export function setClosingDateControl(value, control_id) {
    return { type: SET_CLOSING_DATE_CONTROL, value, control_id }
}

export function setPackages(value) {
    return { type: SET_PACKAGES, value }
}

export function setServices(value) {
    return { type: SET_SERVICES, value }
}

export function setPricing(value) {
    return { type: SET_PRICING, value }
}

export function setPricingLoading(value) {
    return { type: SET_PRICING_LOADING, value }
}

export function setUser(value) {
    return { type: SET_USER, value }
}

export function setBuyersAgent(value) {
    return { type: SET_BUYERS_AGENT, value }
}

export function setSellersAgent(value) {
    return { type: SET_SELLERS_AGENT, value }
}

export function setClient(value) {
    return { type: SET_CLIENT, value }
}

export function setInsuranceAgent(value) {
    return { type: SET_INSURANCE_AGENT, value }
}

export function setContacts(value) {
    return { type: SET_CONTACTS, value }
}

export function setConfirmed(value) {
    return { type: SET_CONFIRMED, value }
}

export function setPayUrl(value) {
    return { type: SET_PAY_URL, value }
}

export function setRushRer(value) {
    return {type: SET_RUSH_RER, value}
}

export function setReferReason(value) {
    return {type: SET_REFER_REASON, value}
}

export function setReferReasonOther(value) {
    return {type: SET_REFER_REASON_OTHER, value}
}
