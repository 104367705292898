import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FixedSideBar from './FixedSideBar.jsx'
import { savePostalCodeLocalStorage } from '../Actions/Address.jsx'
import * as OrderActions from '../Actions/Order.jsx'
import * as OfficeActions from '../Actions/Office.jsx'
import NextButton from './NextButton.jsx'
import Container from './Container.jsx'
import Utils from '../../Common/Utils.jsx'
import { canSetupOnExistingState, canSetupOnPageLoad } from '../util.jsx';
import './postalcode.css';

export class PostalCodeContainer extends Component  {

    constructor(props) {
        super(props);

        this.updatePostalCode = this.updatePostalCode.bind(this);
        this.save = this.save.bind(this);
        this.next = this.next.bind(this);
        this.setup = this.setup.bind(this);

        // local state
        this.state = {
            validation: {
                postal_code: false
            },
            postal_code: props.order.postal_code,
            saving: false
        };
    }

    updatePostalCode(event) {
        const value = event.target.value;
        let validation = this.state.validation;
        validation.postal_code = value !== '';
        this.setState({postal_code:value,no_availability:false,validation});
    }

    next() {
        this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/calendar?t=' + this.props.token + '&office=' + this.props.office_uuid);
    }

    save() {
        let validation = this.state.validation;
        validation.postal_code = this.state.postal_code !== '';

        this.setState({validation,saving:true});

        if (validation.postal_code) {
            this.props.orderActions.setPostalCode(this.state.postal_code);
            this.props.savePostalCodeLocalStorage(this.state.postal_code);
            this.next();
        }
    }

    setup() {
        Utils.scrollTop(500);
    }

    componentDidMount() {
        // run if we got here from an existing state
        if (canSetupOnPageLoad(this.props)) {
            this.setup();
        }
    }

    componentWillReceiveProps(nextProps) {
        // run if we got here from a new state (i.e. new page load)
        if (canSetupOnExistingState(this.props, nextProps)) {
            this.setup();
            let validation = this.state.validation;
            validation.postal_code = '' !== this.props.order.postal_code;
            this.setState({
                validation,
                postal_code: this.props.order.postal_code,
            });
        }
    }

    render() {
        return (
            <div>

            <Container classes="mg-25-p" title="Please enter the Postal Code of the property to be inspected" {...this.props}>
                <div className="form-horizontal ps-cls">

                    <div className="activeDiv fg-class">
                      <label  className="labelText activeLabal">Postal Code</label>
                      <input type="text" className="form-control property-textbox activeLabal" 
                             autoComplete="off" onChange={this.updatePostalCode}
                             value={this.state.postal_code}
                             onKeyUp={(e) => { if (e.key === 'Enter') { this.save() } }}/>
                    </div>

                     {this.state.no_availability && <div className="fg-class" style={{marginTop:'25px'}}>
                        <div className="alert alert-danger bannerNotificationRed">
                            We're sorry but there is no upcoming availability in the {this.state.postal_code} postal code.
                            You can always call us at <a href={'tel:'+this.props.office_phone}>{this.props.office_phone}</a> for help scheduling your inspection.
                        </div>
                    </div>}

                    <div className="fg-class" style={{marginTop:'25px'}}>
                        <NextButton className={this.state.saving ? 'hide-button' : 'next-btn-class'} next={this.save} disabled={!this.state.postal_code || this.state.saving}/>
                        <button className={this.state?.saving ? 'next-btn-class show-button' : 'next-btn-class hide-button'} >&nbsp;<i className="fa fa-spinner fa-spin"></i>&nbsp;</button>
                     </div>

                  </div>

                </Container>
            </div>);
    }
}

/**
 *
 */
export default withRouter(connect(
    (state, ownProps) => {
        return { router: ownProps, ...state.Office, order: state.Order, nav: state.Nav, app: state.App }
    },
    (dispatch) => {
        return {
            orderActions: bindActionCreators(OrderActions, dispatch),
            officeActions: bindActionCreators(OfficeActions, dispatch),
            savePostalCodeLocalStorage: (value) => dispatch(savePostalCodeLocalStorage(value))
        }
    }
)(PostalCodeContainer));
