import {
    SET_TOKEN,
    SET_AUTHORIZED,
    SET_COMPANY_KEY,
    SET_OFFICE_UUID,
    SET_OFFICE_NAME,
    SET_OFFICE_PHONE,
    SET_OFFICE_EMAIL,
    SET_OFFICE_STATE,
    SET_SETTINGS,
    SET_USERS,
    SET_STATES,
    SET_CONTROLS,
    SET_OFFICE_ALL
} from '../constants.jsx'
import { app_state } from '../app_data.jsx'

const initialState = app_state;

class Settings {
    constructor(items) {
        this.items = items.slice(0);
        this.cache = {};
        this.loaded = this.items.length > 0;
    }

    getSetting(key) {
        if (this.cache.hasOwnProperty(key)) {
            //console.log('cache hit', key, this.cache[key]);
            return this.cache[key];
        }
        for (let i = 0, l = this.items.length; i < l; i++) {
            if (this.items[i].key === key) {
                //console.log('found', key, this.items[i]);
                this.cache[key] = this.items[i];
                return this.items[i];
            }
        }
        return null;
    }

    getSettingValue(key) {
        const setting = this.getSetting(key);
        if (setting) {
            return setting.value;
        }
        return '';
    }

    getSettingBool(key) {
        const value = this.getSettingValue(key);
        return value === true || value === 'Y' || value === 'y' || value === 'yes' || value === 1 || value === '1';
    }
}


initialState.settings = new Settings([]);

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_OFFICE_ALL:
            const settings = new Settings(action.value.settings_data);
            state = Object.assign({}, state, { ...action.value, settings });
            delete state.settings_data;
            break;
        case SET_TOKEN:
            state.token = action.value;
            break;
        case SET_AUTHORIZED:
            state.authorized = action.value;
            break;
        case SET_COMPANY_KEY:
            state.companykey = action.value;
            break;
        case SET_OFFICE_UUID:
            state.office_uuid = action.value;
            break;
        case SET_OFFICE_NAME:
            state.office_name = action.value;
            break;
        case SET_OFFICE_PHONE:
            state.office_phone = action.value;
            break;
        case SET_OFFICE_EMAIL:
            state.office_email = action.value;
            break;
        case SET_OFFICE_STATE:
            state.office_state = action.value;
            break;
        case SET_SETTINGS:
            state.settings = action.value;
            break;
        case SET_USERS:
            state.users = action.value;
            break;
        case SET_STATES:
            state.states = action.value;
            break;
        case SET_CONTROLS:
            state.controls = action.value;
            break;
    }

    return state;
}