import {
    SET_NAV_CALENDAR,
    SET_NAV_ADDRESS,
    SET_NAV_CONTACTS,
    SET_NAV_PROPERTY_INFO,
    SET_NAV_PACKAGES,
    SET_NAV_REVIEW,
    SET_NAV_LOADING
} from '../constants.jsx'

export function setNavCalendar(value) {
    return { type: SET_NAV_CALENDAR, value }
}

export function setNavAddress(value) {
    return { type: SET_NAV_ADDRESS, value }
}

export function setNavContacts(value) {
    return { type: SET_NAV_CONTACTS, value }
}

export function setNavPropertyInfo(value) {
    return { type: SET_NAV_PROPERTY_INFO, value }
}

export function setNavPackages(value) {
    return { type: SET_NAV_PACKAGES, value }
}

export function setNavReview(value) {
    return { type: SET_NAV_REVIEW, value }
}

export function setNavLoading(value) {
    return { type: SET_NAV_LOADING, value }
}