import {
    SET_OFFICE_LOADING,
    SET_ORDER_LOADING
} from '../constants.jsx'
import request from 'superagent'
import errorHandler from '../error.jsx'
import moment from "moment";
import { setOrderAll } from './Order.jsx'
import { setOfficeAll } from './Office.jsx'
import { getPostalCodeLocalStorage } from './Address.jsx'

export function setOfficeLoading(value) {
    return { type: SET_OFFICE_LOADING, value }
}

export function setOrderLoading(value) {
    return { type: SET_ORDER_LOADING, value }
}

/**
 *
 * @returns {function(*, *)}
 */
export function loadOrder() {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        dispatch(setOrderLoading(true));

        return request.get('/'+company_key+'/online-scheduler/order')
            .set('X-Auth-Token', token)
            .then((res) => {
                let postalCode = res.postal_code;
                let data = Object.assign({}, res.body);

                if (!postalCode) {
                    const postalCodeFromLs = dispatch(getPostalCodeLocalStorage());
                    if (postalCodeFromLs) {
                        data.postal_code = postalCodeFromLs;
                    }
                }

                data.datetime = data.datetime ? moment(data.datetime) : data.datetime;
                data.pricing_loading = false;

                dispatch(setOrderAll(data));
                dispatch(setOrderLoading(false));
            });

    }
}

/**
 *
 * @returns {function(*, *)}
 */
export function loadOffice() {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        dispatch(setOfficeLoading(true));

        return request.get('/'+company_key+'/online-scheduler/office')
            .set('X-Auth-Token', token)
            .then((res) => {
                dispatch(setOfficeAll(Object.assign({}, res.body)));
                dispatch(setOfficeLoading(false));
            });

    }
}