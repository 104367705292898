import React, { Component } from "react";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./../osstyle.css"
import moment from "moment";
import * as OrderActions from "../Actions/Order.jsx";
import * as OfficeActions from "../Actions/Office.jsx";
import * as ReviewActions from "../Actions/Review.jsx";
import * as CalendarActions from "../Actions/Calendar.jsx";
import * as PropertyInfoActions from "../Actions/PropertyInfo.jsx";
import Container from "./Container.jsx";
import { ClientView } from "./ClientForm.jsx";
import { AgentView } from "./AgentForm.jsx";
import { InsuranceAgentView } from "./InsuranceAgentForm.jsx";
import { ContactView } from "./ContactForm.jsx";
import { getDropDownValue, getFoundationTypeLabel } from "./PropertyInfo.jsx";
import { serviceInPackage } from "./Packages.jsx";
import Currency from "../../Common/Currency.jsx";
import Utils from "../../Common/Utils.jsx";
import { setNavReview, setNavLoading } from "../Actions/Nav.jsx";
import { OnlineSchedulerAppointmentNotAvailableModalView } from "./Calendar.jsx";
import {
  canSetupOnExistingState,
  canSetupOnPageLoad,
  getAnalyticsEventLabel,
} from "../util.jsx";

const PayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="17"
    viewBox="0 -960 960 960"
    width="20"
    fill="#2D5629"
  >
    <path d="M880-740v520q0 24-18 42t-42 18H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42ZM140-631h680v-109H140v109Zm0 129v282h680v-282H140Zm0 282v-520 520Z" />
  </svg>
);
const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="17"
    viewBox="0 -960 960 960"
    width="20"
    fill="#0B5284"
  >
    <path d="M180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Zm0-490h600v-130H180v130Zm0 0v-130 130Zm300 230q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
  </svg>
);

export class ReviewContainer extends Component {
  constructor(props) {
    super(props);

    this.prev = this.prev.bind(this);
    this.save = this.save.bind(this);
    this.setup = this.setup.bind(this);
    this.hasControlType = this.hasControlType.bind(this);

    // local state
    this.state = {
      saving: false,
      modal: {
        appointment_not_available: {
          show: false,
          appointments: [],
        },
      },
      setup: false,
      ba_display_fields: [],
      sa_display_fields: [],
      ia_display_fields: [],
      client_display_fields: [],
      contacts_display_fields: [],
      show_info : false
    };

    this.selectAppointment = this.selectAppointment.bind(this);
  }

  prev() {
    this.props.router.history.push(
      "/" +
        this.props.company_key +
        "/online-scheduler/packages?t=" +
        this.props.token +
        "&office=" +
        this.props.office_uuid
    );
  }

  save() {
    this.setState({ saving: true });
    let start = moment(this.props.order.datetime);
    let end = start.clone();
    end.add(this.props.order.duration, "minutes");

    const limit =
      this.props.settings.getSettingValue("ONLINE_SCHEDULER_V3_APT_DISPLAY") ===
        "list" &&
      this.props.settings.getSettingBool(
        "ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT"
      )
        ? this.props.settings.getSettingValue(
            "ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT_VALUE"
          )
        : 0;

    const inspectors = this.props.order.inspectors.map((inspector) => {
      return inspector.id;
    });

    this.props.calendarActions
      .isTimeAvailable(
        start,
        end,
        this.props.order.duration,
        limit,
        inspectors,
        this.props.order.services,
        this.props.order.postal_code
      )
      .then((res) => {
        if (this.props.calendar.time_available) {
          this.props.reviewActions.save().then(() => {
            this.setState({ saving: false });
            Utils.scrollTop();
          });
          if (window.ga) {
            window.ga("send", {
              hitType: "event",
              eventCategory: "Online Scheduler",
              eventAction: "finished",
              eventLabel: getAnalyticsEventLabel(
                this.props.company_key,
                "Online Scheduler Order was finished"
              ),
            });
          }
        } else {
          let modal = this.state.modal;
          modal.appointment_not_available.show = true;
          modal.appointment_not_available.appointments = res.hasOwnProperty(
            "appointments"
          )
            ? res.appointments
            : [];
          this.setState({ modal, saving: false });
          if (window.ga) {
            window.ga("send", {
              hitType: "event",
              eventCategory: "Online Scheduler",
              eventAction: "appointment-unavailable",
              eventLabel: getAnalyticsEventLabel(
                this.props.company_key,
                "The chosen appointment was no longer available"
              ),
            });
          }
        }
      });
  }

  selectAppointment(appointment) {
    this.setState({ saving: true });
    const datetime = moment(appointment.start_datetime);
    this.props.calendarActions.setSelectedAppointment(appointment);
    this.props.orderActions.setDatetime(datetime);
    this.props.orderActions.setInspectors(
      appointment.users.map((inspector) => {
        return { id: inspector, requested: false };
      })
    );
    this.props.calendarActions
      .save()
      .then(this.props.reviewActions.save)
      .then(() => {
        this.props.reviewActions.save().then(() => {
          this.setState({ saving: false });
          Utils.scrollTop();
        });
      });
    let modal = this.state.modal;
    modal.appointment_not_available.show = false;
    this.setState({ modal });
  }

  getPackageSubTotalBeforeDiscounts(pkg) {
    let subtotal = pkg.base || 0.0;
    if (pkg && pkg.hasOwnProperty("services")) {
      for (let i = 0, l = pkg.services.length; i < l; i++) {
        for (let x = 0, y = pkg.services[i].fees.length; x < y; x++) {
          if (pkg.services[i].fees[x].value > 0) {
            subtotal += pkg.services[i].fees[x].value;
          }
        }
      }
    }
    return subtotal;
  }

  hasControlType(type) {
    for (let i = 0, l = this.props.order.controls.length; i < l; i++) {
      if (this.props.order.controls[i].type === type) {
        return true;
      }
    }
    return false;
  }

  componentWillMount() {
    if (
      this.props.calendar.services_changed &&
      this.props.settings.getSettingBool(
        "ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST"
      )
    ) {
      this.props.orderActions.setDatetime(null);
      this.props.router.history.push(
        "/" +
          this.props.company_key +
          "/online-scheduler/calendar?t=" +
          this.props.token +
          "&office=" +
          this.props.office_uuid
      );
    }
  }

  setup() {
    Utils.scrollTop(500);
    const ba_display_fields = this.props.settings
      .getSettingValue("ONLINE_SCHEDULER_V3_BA_DISPLAY_FIELDS")
      .split(",")
      .filter((item) => {
        return item !== "";
      });
    const sa_display_fields = this.props.settings
      .getSettingValue("ONLINE_SCHEDULER_V3_SA_DISPLAY_FIELDS")
      .split(",")
      .filter((item) => {
        return item !== "";
      });
    const ia_display_fields = this.props.settings
      .getSettingValue("ONLINE_SCHEDULER_V3_IA_DISPLAY_FIELDS")
      .split(",")
      .filter((item) => {
        return item !== "";
      });
    const client_display_fields = this.props.settings
      .getSettingValue("ONLINE_SCHEDULER_V3_CLIENT_DISPLAY_FIELDS")
      .split(",")
      .filter((item) => {
        return item !== "";
      });
    const contacts_display_fields = this.props.settings
      .getSettingValue("ONLINE_SCHEDULER_V3_CONTACTS_DISPLAY_FIELDS")
      .split(",")
      .filter((item) => {
        return item !== "";
      });

    this.setState({
      setup: true,
      ba_display_fields,
      sa_display_fields,
      ia_display_fields,
      client_display_fields,
      contacts_display_fields,
    });
    this.props.setNavReview(true);
    this.props.setNavLoading(false);
  }

  componentDidMount() {
    // run if we got here from an existing state
    if (canSetupOnPageLoad(this.props)) {
      this.setup();
    }
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }

  componentWillReceiveProps(nextProps) {
    // run if we got here from a new state (i.e. new page load)
    if (canSetupOnExistingState(this.props, nextProps)) {
      this.setup();
    }
  }

  render() {
    let content = null;
    let title = "";

    if (this.state.setup) {
      // title = (this.props.order.confirmed ? 'Confirmed' : 'Confirm') + ' Details';

      const order_state = this.props.states.find((state) => {
        return state.id === this.props.order.state;
      });

      const showInspectorNames = this.props.settings.getSettingBool(
        "ONLINE_SCHEDULER_V3_SHOW_INSPECTOR_NAMES"
      );
      const userIsBuyersAgent =
        this.props.order.user && this.props.order.user.type === "BUYERS_AGENT";
      const userIsSellersAgent =
        this.props.order.user && this.props.order.user.type === "SELLERS_AGENT";
      const userIsInsuranceAgent =
        this.props.order.user &&
        this.props.order.user.type === "INSURANCE_AGENT";
      const userIsClient =
        this.props.order.user &&
        ["CLIENT_HOMEOWNER", "CLIENT_BUYER"].indexOf(
          this.props.order.user.type
        ) !== -1;
      const userIsOther =
        this.props.order.user && this.props.order.user.type === "OTHER";

      const hasSqft = this.hasControlType("SquareFeet");
      const hasYb = this.hasControlType("YearBuilt");
      const hasSalesPrice = this.hasControlType("SalesPrice");
      const hasFoundationType = this.hasControlType("FoundationType");

      const foundationTypeLabel = getFoundationTypeLabel(this.props.order);

      const canPay =
        !this.props.order.paid &&
        this.props.settings.getSettingBool(
          "ONLINE_SCHEDULER_V3_SHOW_PAYMENT_LINK"
        );
      const bHideFees = this.props.settings.getSettingBool(
        "ONLINE_SCHEDULER_V3_HIDE_ALL_FEES"
      );

      const inspectorIds = this.props.order.inspectors.map((user) => {
        return user.id;
      });

      const inspectorNames = this.props.users
        .filter((user) => {
          return inspectorIds.indexOf(user.id) !== -1;
        })
        .map((user) => {
          return user.display;
        });

      const specialNote =
        this.props.propertyInfoActions.getSpecialNoteLocalStorage();

      content = (
        <div className='thnku-wrapper'>
          {this.props.order.confirmed && (
            <div className="row thnku-bx" style={{ marginBottom: "20px" }}>
              <div>
                <h2>Thank you!</h2>
                {this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CONFIRMATION_BANNER') && '' !== this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CONFIRMATION_BANNER')
                            ? <div style={{fontSize: "16px", color: "#000", lineHeight: "20px",paddingBottom: "15px",marginBottom:'10px'}} dangerouslySetInnerHTML={{__html: this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CONFIRMATION_BANNER').replace(/\r?\n/g, '<br/>')}}/>
                            : <div>
                                 <p style={{ fontSize: "16px", color: "#000", lineHeight: "20px",paddingBottom: "15px"}}>
                                    Your inspection order has been placed. Someone from our
                                    office will contact you soon for further confirmation.
                                  </p>
                                  <p style={{ fontSize: "16px", color: "#000", lineHeight: "20px", paddingBottom: "40px"}}>
                                    If you need to contact us, you can email us at{" "}
                                    <a href={'mailto:'+this.props.office_email}>{this.props.office_email}</a>{" "}
                                    or call us at{" "}
                                    <a href={'tel:'+this.props.office_phone}>{this.props.office_phone}</a>.
                                  </p>
                            </div>
                        }
               
                {/* {canPay && <p>You can pay for your inspection online!</p>} */}
                <div
                  className={"btn-toolbar text-center thnku-btns-wrapper"}
                  
                >
                  {canPay && (
                    <a
                      href={this.props.order.pay_url}
                      target="_blank"
                      className="btn"
                      style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"row", background: "#DFF0D8",
                      border: "1px solid #5EAE56",
                      borderRadius: "4px"}}
                    >
                        <PayIcon />&nbsp;{" "}
                      <span style={{color:"#2D5629", fontStyle: "normal",fontWeight: "600", fontSize: "14px"}}>Pay Online</span>
                    </a>
                  )}
                  <a
                    href={
                      "/" +
                      this.props.company_key +
                      "/online-scheduler/" +
                      this.props.office_uuid
                    }
                    style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"row", background: "#EAF5FF",
                      border: "1px solid #0F75BC", padding: "6px 16px",
                      borderRadius: "4px"}}
                    className="btn"
                  >
                    <CalendarIcon />&nbsp;{" "}
                    <span style={{color:"#0B5284", fontStyle: "normal",fontWeight: "600", fontSize: "14px"}}> Schedule Again </span>
                  </a>
                  {this.props.settings.getSettingBool(
                    "ONLINE_SCHEDULER_V3_REDIRECT_BUTTON_ENABLED"
                  ) &&
                    this.props.settings.getSettingValue(
                      "ONLINE_SCHEDULER_V3_REDIRECT_BUTTON_URL"
                    ) !== "" && (
                      <a
                        href={this.props.settings.getSettingValue(
                          "ONLINE_SCHEDULER_V3_REDIRECT_BUTTON_URL"
                        )}
                        className={"btn btn-default"}
                        rel={"external"}
                        target={"_blank"}
                      >
                         <span style={{color:"#454545", fontStyle: "normal",fontWeight: "600", fontSize: "14px"}}>  {this.props.settings.getSettingValue(
                          "ONLINE_SCHEDULER_V3_REDIRECT_BUTTON_TEXT"
                        )}</span>
                      </a>
                    )}
                    {/* <a
                    onClick={(e) => {this.setState({show_info : !this.state.show_info}); console.log("this.state.show_info", this.state.show_info)}} 
                    className="btn"
                    style={{border: "1px solid #D5D5D5",
                    borderRadius: "4px"}}
                  >
                  <span style={{color:"#454545", fontStyle: "normal",fontWeight: "600", fontSize: "14px"}}>  More Info </span>
                  </a> */}
                </div>
              </div>
            </div>
          )}
              {this.state.show_info ? <div className="row">
            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h5>
                    <i className="fa fa-calendar-alt" />
                    &nbsp;{" "}
                    {showInspectorNames
                      ? "Date, Time and Inspector"
                      : "Date and Time"}
                  </h5>
                </div>
                <div className="panel-body">
                  {this.props.order.datetime
                    ? this.props.order.datetime.format("M/D/YYYY [at] h:mmA")
                    : "n/a"}
                  {showInspectorNames &&
                    this.props.order.inspectors.length > 0 && (
                      <span>&nbsp;with {Utils.commaList(inspectorNames)}</span>
                    )}
                  {!this.props.order.confirmed && (
                    <div className="text-center" style={{ marginTop: "15px" }}>
                      <button
                        type="button"
                        className="btn btn-info btn-xs"
                        onClick={() => {
                          this.props.router.history.push(
                            "/" +
                              this.props.company_key +
                              "/online-scheduler/calendar?t=" +
                              this.props.token +
                              "&office=" +
                              this.props.office_uuid
                          );
                        }}
                      >
                        Change
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h5>
                    <i className="far fa-user" />
                    &nbsp;My Info ({this.props.order.user.text})
                  </h5>
                </div>
                <div className="panel-body">
                  {userIsClient && this.props.order.client && (
                    <div className="contact-form">
                      <ClientView
                        compact={true}
                        {...this.props.order.client}
                        fields={this.state.client_display_fields}
                        states={this.props.states}
                      />
                    </div>
                  )}
                  {userIsBuyersAgent && this.props.order.buyers_agent && (
                    <div className="contact-form">
                      <AgentView
                        compact={true}
                        {...this.props.order.buyers_agent}
                        fields={this.state.ba_display_fields}
                        states={this.props.states}
                      />
                    </div>
                  )}
                  {userIsSellersAgent && this.props.order.sellers_agent && (
                    <div className="contact-form">
                      <AgentView
                        compact={true}
                        {...this.props.order.sellers_agent}
                        fields={this.state.sa_display_fields}
                        states={this.props.states}
                      />
                    </div>
                  )}
                  {userIsInsuranceAgent && this.props.order.insurance_agent && (
                    <div className="contact-form">
                      <AgentView
                        compact={true}
                        {...this.props.order.insurance_agent}
                        fields={this.state.ia_display_fields}
                        states={this.props.states}
                      />
                    </div>
                  )}
                  {userIsOther && (
                    <div className="contact-form">
                      Other: {this.props.user.other.value}
                    </div>
                  )}
                  {!this.props.order.confirmed && (
                    <div className="text-center" style={{ marginTop: "15px" }}>
                      <button
                        type="button"
                        className="btn btn-info btn-xs"
                        onClick={() => {
                          this.props.router.history.push(
                            "/" +
                              this.props.company_key +
                              "/online-scheduler/contacts?t=" +
                              this.props.token +
                              "&office=" +
                              this.props.office_uuid
                          );
                        }}
                      >
                        Change
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h5>
                    <i className="fas fa-home" />
                    &nbsp;Address
                  </h5>
                </div>
                <div className="panel-body">
                  <div>{this.props.order.address1}</div>
                  {this.props.order.address2 !== "" && (
                    <div>{this.props.order.address2}</div>
                  )}
                  {this.props.order.city !== "" && (
                    <span>{this.props.order.city}</span>
                  )}
                  {order_state ? ", " + order_state.abbreviation : ""}
                  {this.props.order.postal_code !== "" && (
                    <span>&nbsp;{this.props.order.postal_code}</span>
                  )}
                  {!this.props.order.confirmed && (
                    <div className="text-center" style={{ marginTop: "15px" }}>
                      <button
                        type="button"
                        className="btn btn-info btn-xs"
                        onClick={() => {
                          this.props.router.history.push(
                            "/" +
                              this.props.company_key +
                              "/online-scheduler/address?t=" +
                              this.props.token +
                              "&office=" +
                              this.props.office_uuid
                          );
                        }}
                      >
                        Change
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {this.props.order.controls.length > 0 && (
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h5>
                      <i className="fas fa-info-circle" />
                      &nbsp;Property Info
                    </h5>
                  </div>
                  <div className="panel-body">
                    <ul
                      style={{
                        listStyleType: "none",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                      }}
                    >
                      {hasSqft && <li>Square Feet: {this.props.order.sqft}</li>}
                      {hasYb && <li>Year Built: {this.props.order.yb}</li>}
                      {hasSalesPrice && (
                        <li>
                          Sale Price:{" "}
                          <Currency amount={this.props.order.sales_price} />
                        </li>
                      )}
                      {hasFoundationType && (
                        <li>Foundation: {foundationTypeLabel}</li>
                      )}
                      {this.props.order.controls.map((control, controlKey) => {
                        if (
                          [
                            "SquareFeet",
                            "YearBuilt",
                            "SalesPrice",
                            "FoundationType",
                          ].indexOf(control.type) === -1
                        ) {
                          return (
                            <li key={controlKey}>
                              {control.type === "CheckBox" && control.value && (
                                <span>
                                  <input
                                    type={"checkbox"}
                                    checked={true}
                                    readOnly={true}
                                  />
                                  &nbsp;{control.label}
                                </span>
                              )}
                              {control.type === "DropDown" && control.value && (
                                <span>
                                  {control.label}:{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: getDropDownValue(control),
                                    }}
                                  />
                                </span>
                              )}
                              {control.type !== "CheckBox" &&
                                control.type !== "DropDown" &&
                                control.value && (
                                  <span>
                                    {control.label}: {control.value}
                                  </span>
                                )}
                            </li>
                          );
                        }
                      })}
                      {this.props.settings.getSettingBool(
                        "ONLINE_SCHEDULER_V3_PROPERTY_INFO_NOTE"
                      ) && (
                        <li style={{ marginTop: "10px" }}>
                          <strong>Special instructions and notes:</strong>
                          <p>{specialNote}</p>
                        </li>
                      )}
                    </ul>
                    {!this.props.order.confirmed && (
                      <div
                        className="text-center"
                        style={{ marginTop: "15px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-info btn-xs"
                          onClick={() => {
                            this.props.router.history.push(
                              "/" +
                                this.props.company_key +
                                "/online-scheduler/property-info?t=" +
                                this.props.token +
                                "&office=" +
                                this.props.office_uuid
                            );
                          }}
                        >
                          Change
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {!userIsClient && this.props.order.client && (
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h5>
                      <i className="far fa-user" />
                      &nbsp;Client
                    </h5>
                  </div>
                  <div className="panel-body">
                    <ClientView
                      compact={true}
                      states={this.props.states}
                      fields={this.state.client_display_fields}
                      {...this.props.order.client}
                    />
                    {!this.props.order.confirmed && (
                      <div
                        className="text-center"
                        style={{ marginTop: "15px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-info btn-xs"
                          onClick={() => {
                            this.props.router.history.push(
                              "/" +
                                this.props.company_key +
                                "/online-scheduler/contacts?t=" +
                                this.props.token +
                                "&office=" +
                                this.props.office_uuid
                            );
                          }}
                        >
                          Change
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {!userIsBuyersAgent && this.props.order.buyers_agent && (
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h5>
                      <i className="far fa-user" />
                      &nbsp;Buyer's Agent
                    </h5>
                  </div>
                  <div className="panel-body">
                    <AgentView
                      compact={true}
                      states={this.props.states}
                      fields={this.state.ba_display_fields}
                      {...this.props.order.buyers_agent}
                    />
                    {!this.props.order.confirmed && (
                      <div
                        className="text-center"
                        style={{ marginTop: "15px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-info btn-xs"
                          onClick={() => {
                            this.props.router.history.push(
                              "/" +
                                this.props.company_key +
                                "/online-scheduler/contacts?t=" +
                                this.props.token +
                                "&office=" +
                                this.props.office_uuid
                            );
                          }}
                        >
                          Change
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {!userIsSellersAgent && this.props.order.sellers_agent && (
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h5>
                      <i className="far fa-user" />
                      &nbsp;Seller's Agent
                    </h5>
                  </div>
                  <div className="panel-body">
                    <AgentView
                      compact={true}
                      states={this.props.states}
                      fields={this.state.sa_display_fields}
                      {...this.props.order.sellers_agent}
                    />
                    {!this.props.order.confirmed && (
                      <div
                        className="text-center"
                        style={{ marginTop: "15px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-info btn-xs"
                          onClick={() => {
                            this.props.router.history.push(
                              "/" +
                                this.props.company_key +
                                "/online-scheduler/contacts?t=" +
                                this.props.token +
                                "&office=" +
                                this.props.office_uuid
                            );
                          }}
                        >
                          Change
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {!userIsSellersAgent && this.props.order.insurance_agent && (
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h5>
                      <i className="far fa-user" />
                      &nbsp;Insurance Agent
                    </h5>
                  </div>
                  <div className="panel-body">
                    <InsuranceAgentView
                      compact={true}
                      states={this.props.states}
                      fields={this.state.ia_display_fields}
                      {...this.props.order.insurance_agent}
                    />
                    {!this.props.order.confirmed && (
                      <div
                        className="text-center"
                        style={{ marginTop: "15px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-info btn-xs"
                          onClick={() => {
                            this.props.router.history.push(
                              "/" +
                                this.props.company_key +
                                "/online-scheduler/contacts?t=" +
                                this.props.token +
                                "&office=" +
                                this.props.office_uuid
                            );
                          }}
                        >
                          Change
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {this.props.order.contacts.map((contact, index) => {
              return (
                <div key={index} className="col-md-12">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h5>
                        <i className="far fa-user" />
                        &nbsp;{contact.type.name}
                      </h5>
                    </div>
                    <div className="panel-body">
                      <ContactView
                        compact={true}
                        fields={this.state.contacts_display_fields}
                        {...contact}
                        states={this.props.states}
                      />
                      {!this.props.order.confirmed && (
                        <div
                          className="text-center"
                          style={{ marginTop: "15px" }}
                        >
                          <button
                            type="button"
                            className="btn btn-info btn-xs"
                            onClick={() => {
                              this.props.router.history.push(
                                "/" +
                                  this.props.company_key +
                                  "/online-scheduler/contacts?t=" +
                                  this.props.token +
                                  "&office=" +
                                  this.props.office_uuid
                              );
                            }}
                          >
                            Change
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h5>
                    <i className="fas fa-shopping-cart" />
                    &nbsp;Shopping Cart
                  </h5>
                </div>
                <div className="panel-body">
                  <ul
                    style={{ listStyleType: "none", margin: "0", padding: "0" }}
                  >
                    {this.props.order.pricing.package && (
                      <li>
                        <div className="pull-right">
                          {!bHideFees && (
                            <Currency
                              amount={this.getPackageSubTotalBeforeDiscounts(
                                this.props.order.pricing.package
                              )}
                            />
                          )}
                        </div>
                        <div
                          className="pull-left"
                          title={this.props.order.pricing.package.name}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.props.order.pricing.package.name,
                            }}
                          />
                        </div>
                        <div style={{ clear: "both" }} />
                        {this.props.order.pricing.package.savings > 0 &&
                          !bHideFees && (
                            <div>
                              <div
                                className="pull-right"
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                }}
                              >
                                -
                                <Currency
                                  amount={
                                    this.props.order.pricing.package.savings
                                  }
                                />
                              </div>
                              <div
                                className="pull-left"
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                }}
                              >
                                Bundle Savings
                              </div>
                              <div style={{ clear: "both" }} />
                            </div>
                          )}
                      </li>
                    )}
                    {this.props.order.pricing.services.map(
                      (service, serviceKey) => {
                        if (
                          !serviceInPackage(
                            service.id,
                            this.props.order.pricing.package
                          )
                        ) {
                          return (
                            <li key={serviceKey}>
                              <div className="pull-right">
                                {!bHideFees && (
                                  <Currency
                                    amount={
                                      service.subtotal + (service.base || 0)
                                    }
                                  />
                                )}
                              </div>
                              <div className="pull-left" title={service.name}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: service.name,
                                  }}
                                />
                              </div>
                              <div style={{ clear: "both" }} />
                            </li>
                          );
                        }
                      }
                    )}
                    {this.props.order.pricing.additional.fees
                      .filter((fee) => {
                        return !bHideFees && parseFloat(fee.value) !== 0.0;
                      })
                      .map((fee, feeKey) => {
                        return (
                          <li key={feeKey}>
                            <div className="pull-right">
                              <Currency amount={fee.value} />
                            </div>
                            <div className="pull-left" title={fee.description}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: fee.description,
                                }}
                              />
                            </div>
                            <div style={{ clear: "both" }} />
                          </li>
                        );
                      })}
                    {this.props.order.pricing.taxes
                      .filter((tax) => {
                        return !bHideFees;
                      })
                      .map((tax, taxKey) => {
                        return (
                          <li key={taxKey}>
                            <div className="pull-right">
                              <Currency amount={tax.total} />
                            </div>
                            <div className="pull-left" title={tax.name}>
                              <span
                                dangerouslySetInnerHTML={{ __html: tax.name }}
                              />{" "}
                              (tax)
                            </div>
                            <div style={{ clear: "both" }} />
                          </li>
                        );
                      })}
                    {this.props.order.pricing.coupons.map(
                      (coupon, couponKey) => {
                        return (
                          <li key={couponKey}>
                            <div
                              className="pull-right"
                              style={{ fontStyle: "italic" }}
                            >
                              {!bHideFees && (
                                <span>
                                  -<Currency amount={coupon.amount} />
                                </span>
                              )}
                            </div>
                            <div
                              className="pull-left"
                              style={{ fontStyle: "italic" }}
                              title={coupon.name}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: coupon.name,
                                }}
                              />{" "}
                              (coupon)
                            </div>
                            <div style={{ clear: "both" }} />
                          </li>
                        );
                      }
                    )}
                    {!bHideFees && (
                      <li style={{ borderTop: "1px solid #ddd" }}>
                        <div className="pull-right">
                          <Currency
                            amount={this.props.order.pricing.grand_total}
                          />
                        </div>
                        <div className="pull-left">Total:</div>
                        <div style={{ clear: "both" }} />
                      </li>
                    )}
                  </ul>

                  {!this.props.order.confirmed && (
                    <div className="text-center" style={{ marginTop: "15px" }}>
                      <button
                        type="button"
                        className="btn btn-info btn-xs"
                        onClick={() => {
                          this.props.router.history.push(
                            "/" +
                              this.props.company_key +
                              "/online-scheduler/packages?t=" +
                              this.props.token +
                              "&office=" +
                              this.props.office_uuid
                          );
                        }}
                      >
                        Change
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div> : null}
          {!this.props.order.confirmed && (
            <div className="row" style={{ marginTop: "50px" }}>
              <div className="col-md-12 text-center">
                <div
                  className="btn-toolbar"
                  style={{ display: "inline-block", margin: "0 auto" }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.save}
                    disabled={this.state.saving}
                  >
                    {this.state.saving && (
                      <span>
                        <i className={"fas fa-spin fa-spinner"} />
                        &nbsp; Please wait
                      </span>
                    )}
                    {!this.state.saving && <span>Confirm My Inspection</span>}
                  </button>
                </div>
              </div>
            </div>
          )}
          </div> 
      );
    }

    return (
      <div className="review">
        {/* <Container classes={'container-fluid-full'}
                           bodyClasses={'col-xs-12 col-sm-6 col-sm-offset-3 col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2'}
                           title={title} {...this.props}>
                <div className="row" style={{marginBottom:'20px'}}>
                    <div className="col-md-12">
                    <h2>Thank you!</h2>
                    {this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CONFIRMATION_BANNER') && '' !== this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CONFIRMATION_BANNER')
                            ? <div style={{marginBottom:'10px'}} dangerouslySetInnerHTML={{__html: this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_CONFIRMATION_BANNER').replace(/\r?\n/g, '<br/>')}}/>
                            : <div>
                                <p>Your inspection order has been placed. Someone from our office will contact you soon for further confirmation.</p>
                                <p>If you need to contact us, you can email us at <a href={'mailto:'+this.props.office_email}>{this.props.office_email}</a> or
                                    call us at <a href={'tel:'+this.props.office_phone}>{this.props.office_phone}</a>.
                                </p>
                            </div>
                        }
                    </div>
                </div>             
                </Container> */}
        <Container
          classes="mg-25-p"
          bodyClasses={""
            // "col-xs-12 col-sm-6 col-sm-offset-3 col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2"
          }
          title={title}
          {...this.props}
        >
          {content}
        </Container>
        {this.state.modal.appointment_not_available.show && (
          <OnlineSchedulerAppointmentNotAvailableModalView
            order={this.props.order}
            close={() => {
              let modal = this.state.modal;
              modal.appointment_not_available.show = false;
              this.setState({ modal });
            }}
            settings={this.props.settings}
            selectAppointment={this.selectAppointment}
            datetime={this.props.order.datetime}
            users={this.props.users}
            appointments={
              this.state.modal.appointment_not_available.appointments
            }
            office_phone={this.props.office_phone}
          />
        )}
      </div>
    );
  }
}

/**
 *
 */
export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        router: ownProps,
        ...state.Office,
        order: state.Order,
        calendar: state.Calendar,
        nav: state.Nav,
        app: state.App,
      };
    },
    (dispatch) => {
      return {
        reviewActions: bindActionCreators(ReviewActions, dispatch),
        orderActions: bindActionCreators(OrderActions, dispatch),
        calendarActions: bindActionCreators(CalendarActions, dispatch),
        officeActions: bindActionCreators(OfficeActions, dispatch),
        propertyInfoActions: bindActionCreators(PropertyInfoActions, dispatch),
        setNavReview: (value) => dispatch(setNavReview(value)),
        setNavLoading: (value) => dispatch(setNavLoading(value)),
      };
    }
  )(ReviewContainer)
);
