import { setSaved, setPackages, setServices, setPricing, setPricingLoading, setDuration } from './Order.jsx'
import { setAvailability} from './Calendar.jsx'
import request from 'superagent'
import errorHandler from '../error.jsx'

export function addPackage(_package) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        let orderPackages = state.Order.packages;
        let orderServices = state.Order.services;

        if (orderPackages.indexOf(_package.id) === -1) {
            orderPackages.push(_package.id);

            for (let i = 0, l = _package.services.length; i < l; i++) {
                if (orderServices.indexOf(_package.services[i].id) === -1) {
                    orderServices.push(_package.services[i].id);
                }
            }
        }

        return request.post('/'+company_key+'/json/online_scheduler/set_packages')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(orderPackages)
            .then((res) => {
                if (res.body.hasOwnProperty('duration')) {
                    dispatch(setDuration(res.body.duration));
                }
                dispatch(setPackages(orderPackages));
                dispatch(setServices(orderServices));
                dispatch(setAvailability({}));
                dispatch(setSaved(true));
            });
    };
}

export function removePackage(_package) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        let orderPackages = state.Order.packages;
        let orderServices = state.Order.services;

        // remove the package from packages
        for (let i = 0, l = orderPackages.length; i < l; i++) {
            if (orderPackages[i] === _package.id) {
                orderPackages.splice(i, 1);
                break;
            }
        }

        // remove services associated with this package from services
        for (let i = 0, l = _package.services.length; i < l; i++) {
            for (let x = 0, y = orderServices.length; x < y; x++) {
                if (_package.services[i].id === orderServices[x]) {
                    orderServices.splice(x, 1);
                    break;
                }
            }
        }

        return request.post('/'+company_key+'/json/online_scheduler/set_packages')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(orderPackages)
            .then((res) => {
                if (res.body.hasOwnProperty('duration')) {
                    dispatch(setDuration(res.body.duration));
                }
                dispatch(setPackages(orderPackages));
                dispatch(setServices(orderServices));
                dispatch(setAvailability({}));
                dispatch(setSaved(true));
            });
    };
}

export function addService(service) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        let orderServices = state.Order.services;

        if (orderServices.indexOf(service.id) === -1) {
            orderServices.push(service.id);

            return request.post('/'+company_key+'/json/online_scheduler/add_service')
                .set('X-Auth-Token', token)
                .on('error', (err) => errorHandler(err, dispatch))
                .send(service.id)
                .then((res) => {
                    if (res.body.hasOwnProperty('duration')) {
                        dispatch(setDuration(res.body.duration));
                    }
                    dispatch(setServices(orderServices));
                    dispatch(setAvailability({}));
                    dispatch(setSaved(true));
                });
        }

        return Promise.reject('Service already exists in cart');
    };
}

export function removeService(service) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        let orderServices = state.Order.services;

        // remove service
        const index = orderServices.indexOf(service.id);
        if (index !== -1) {
            orderServices.splice(index, 1);

            return request.post('/'+company_key+'/json/online_scheduler/remove_service')
                .set('X-Auth-Token', token)
                .on('error', (err) => errorHandler(err, dispatch))
                .send(service.id)
                .then(() => {
                    dispatch(setServices(orderServices));
                    dispatch(setAvailability({}));
                    dispatch(setSaved(true));
                });
        }

        return Promise.reject('Service does not exist in cart');
    };
}

export function loadPricing(pacRemovedBecauseDisqualified = false) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        dispatch(setPricingLoading(true));
        return request.get('/'+company_key+'/json/online_scheduler/pricing')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .then((res) => {
                res.body.pac_removed = pacRemovedBecauseDisqualified;
                dispatch(setPricing(res.body));
                dispatch(setSaved(true));
                dispatch(setPricingLoading(false));
            });
    };
}

export function applyCoupon(coupon_code) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        return request.post('/'+company_key+'/json/online_scheduler/apply_coupon')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(coupon_code)
            .then((res) => {
                if (res.body.status === 'ok') {
                    dispatch(loadPricing());
                    dispatch(setSaved(true));
                }
                return res.body;
            });
    };
}

/**
 *
 * @returns {function(*, *)}
 */
export function save() {
    return (dispatch, getState) => {
        const state = getState();
        const order = state.Order;
        const { token, company_key } = state.Office;

        return request.post('/'+company_key+'/json/online_scheduler/set_inspectors')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(order.inspectors.map((item) => { return item.id }))
            .then(() => {
                dispatch(setSaved(true));
            });
    }
}