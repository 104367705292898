/* app */
export const SET_OFFICE_LOADING = 'SET_OFFICE_LOADING';
export const SET_ORDER_LOADING = 'SET_ORDER_LOADING';

/* office */
export const SET_OFFICE_ALL = 'SET_OFFICE_ALL';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_AUTHORIZED = 'SET_AUTHORIZED';
export const SET_COMPANY_KEY = 'SET_COMPANY_KEY';
export const SET_OFFICE_UUID = 'SET_OFFICE_UUID';
export const SET_OFFICE_NAME = 'SET_OFFICE_NAME';
export const SET_OFFICE_PHONE = 'SET_OFFICE_PHONE';
export const SET_OFFICE_EMAIL = 'SET_OFFICE_EMAIL';
export const SET_OFFICE_STATE = 'SET_OFFICE_STATE';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_USERS = 'SET_USERS';
export const SET_STATES = 'SET_STATES';
export const SET_CONTROLS = 'SET_CONTROLS';

/* nav */
export const SET_NAV_CALENDAR = 'SET_NAV_CALENDAR';
export const SET_NAV_ADDRESS = 'SET_NAV_ADDRESS';
export const SET_NAV_CONTACTS = 'SET_NAV_CONTACTS';
export const SET_NAV_PROPERTY_INFO = 'SET_NAV_PROPERTY_INFO';
export const SET_NAV_PACKAGES = 'SET_NAV_PACKAGES';
export const SET_NAV_REVIEW = 'SET_NAV_REVIEW';
export const SET_NAV_LOADING = 'SET_NAV_LOADING';

/* order */
export const SET_ORDER_ALL = 'SET_ORDER_ALL';
export const SET_SAVED = 'SET_SAVED';
export const SET_DATETIME = 'SET_DATETIME';
export const SET_DURATION = 'SET_DURATION';
export const SET_INSPECTORS = 'SET_INSPECTORS';
export const SET_ADDRESS1 = 'SET_ADDRESS1';
export const SET_ADDRESS2 = 'SET_ADDRESS2';
export const SET_CITY = 'SET_CITY';
export const SET_STATE = 'SET_STATE';
export const SET_POSTAL_CODE = 'SET_POSTAL_CODE';
export const SET_LATITUDE = 'SET_LATITUDE';
export const SET_LONGITUDE = 'SET_LONGITUDE';
export const SET_COUNTY = 'SET_COUNTY';
export const SET_SQFT = 'SET_SQFT';
export const SET_YB = 'SET_YB';
export const SET_FOUNDATION_TYPE = 'SET_FOUNDATION_TYPE';
export const SET_SALES_PRICE = 'SET_SALES_PRICE';
export const SET_TEXT_CONTROL = 'SET_YEAR_BUILT';
export const SET_TEXTBOX_CONTROL = 'SET_TEXTBOX_CONTROL';
export const SET_CHECKBOX_CONTROL = 'SET_CHECKBOX_CONTROL';
export const SET_DROPDOWN_CONTROL = 'SET_DROPDOWN_CONTROL';
export const SET_TRIGGERED_DROPDOWN_CONTROL = 'SET_TRIGGERED_DROPDOWN_CONTROL';
export const SET_TRIGGERED_TEXTBOX_CONTROL = 'SET_TRIGGERED_TEXTBOX_CONTROL';
export const SET_DATE_CONTROL = 'SET_DATE_CONTROL';
export const SET_CLOSING_DATE_CONTROL = 'SET_CLOSING_DATE_CONTROL';
export const SET_PACKAGES = 'SET_PACKAGES';
export const SET_SERVICES = 'SET_SERVICES';
export const SET_PRICING = 'SET_PRICING';
export const SET_PRICING_LOADING = 'SET_PRICING_LOADING';
export const SET_USER = 'SET_USER';
export const SET_BUYERS_AGENT = 'SET_BUYERS_AGENT';
export const SET_SELLERS_AGENT = 'SET_SELLERS_AGENT';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_INSURANCE_AGENT = 'SET_INSURANCE_AGENT';
export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_CONFIRMED = 'SET_CONFIRMED';
export const SET_PAY_URL = 'SET_PAY_URL';
export const SET_RUSH_RER = 'SET_RUSH_RER';
export const SET_REFER_REASON = 'SET_REFER_REASON';
export const SET_REFER_REASON_OTHER = 'SET_REFER_REASON_OTHER';

/* calendar */
export const CALENDAR_SET_AVAILABILITY = 'CALENDAR_SET_AVAILABILITY';
export const CALENDAR_SET_LOADING = 'CALENDAR_SET_LOADING';
export const CALENDAR_SET_LOADING_MORE = 'CALENDAR_SET_LOADING_MORE';
export const CALENDAR_SET_LOADING_TIME_AVAILABLE = 'CALENDAR_SET_LOADING_TIME_AVAILABLE';
export const CALENDAR_SET_TIME_AVAILABLE = 'CALENDAR_SET_TIME_AVAILABLE';
export const CALENDAR_SET_SELECTED_DATE = 'CALENDAR_SET_SELECTED_DATE';
export const CALENDAR_SET_SELECTED_APPOINTMENT = 'CALENDAR_SET_SELECTED_APPOINTMENT';
export const CALENDAR_SET_LIST_MODE_LAST_DATE = 'CALENDAR_SET_LIST_MODE_LAST_DATE';
export const CALENDAR_SERVICES_CHANGED = 'CALENDAR_SERVICES_CHANGED';

/* contacts */
export const CONTACTS_SET_USER = 'CONTACTS_SET_USER';
export const CONTACTS_SET_BUYERS_AGENT = 'CONTACTS_SET_BUYERS_AGENT';
export const CONTACTS_SET_SELLERS_AGENT = 'CONTACTS_SET_SELLERS_AGENT';
export const CONTACTS_SET_CLIENT = 'CONTACTS_SET_CLIENT';
export const CONTACTS_SET_CONTACTS = 'CONTACTS_SET_CONTACTS';
