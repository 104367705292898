import React from 'react'

export default (props) => (
    <button type={'button'} className={props.className}
            onClick={props.next}
            disabled={props.hasOwnProperty('disabled') && props.disabled}>
        {props.hasOwnProperty('children') && props.children && <div>{props.children}</div>}
        {(!props.hasOwnProperty('children') || !props.children) && <div>
            Next</div>}
    </button>
)