import React, { Component } from 'react'
import { withRouter } from 'react-router';
import FixedSideBar from './FixedSideBar.jsx'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import * as AddressActions from '../Actions/Address.jsx'
import * as OrderActions from '../Actions/Order.jsx'
import * as OfficeActions from '../Actions/Office.jsx'
import * as CalendarActions from '../Actions/Calendar.jsx'
import NextButton from './NextButton.jsx'
import BackButton from './BackButton.jsx'
import Container from './Container.jsx'
import Utils from '../../Common/Utils.jsx'
import { canSetupOnPageLoad, canSetupOnExistingState, getAnalyticsEventLabel} from '../util.jsx';
import { AppointmentList } from './Calendar.jsx'
import { setNavAddress, setNavLoading } from '../Actions/Nav.jsx'
import moment from 'moment'
import { app_state } from '../app_data.jsx'
import { DebounceInput } from 'react-debounce-input'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, FormHelperText, FormControl, InputLabel } from '@material-ui/core';
import "./contactinfo.css";
import './compstyles.css';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
    },
    dense: {
      marginTop: 16,
    },
    menu: {
      width: 200,
    },
    notchedOutline: { borderColor: '#a94442 !important' },
    resize:{
        fontSize:16,
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          // borderColor: '#0F75BC', 
          borderWidth: 1, 
        },
      },
    inputRoot: {
        fontSize: 22,
        background: "white",
        paddingRight: 5,
        position: "absolute",
        top: "-3px",
        fontFamily: 'Source Sans Pro',
        // '&.Mui-focused': { // Add the focused style here
        //  color: '#0F75BC',
        // },
    },
    icon: {
        // fill: theme.palette.primary.main,
        right: '12px',
        top: 'calc(42% - 12px)', // Adjust the position if needed
        width: '30px', // Increase or decrease the size as per your needs
        height: '30px', // Increase or decrease the size as per your needs
        position: 'absolute',
        pointerEvents: 'none',
      },
});
/**
 *
 */
export class DistanceConflictModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const excluded = this.props.datetime instanceof moment ? [this.props.datetime.format('YYYY-MM-DD HH:mm[:00]')] : [];

        const appointments = this.props.appointments.filter((apt) => {
            return excluded.indexOf(apt.start_datetime) === -1;
        });

        return <Modal show={true} onHide={this.props.close} bsSize="lg" className="appointment-substitute-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Sorry!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '5px 10px' }}>
                {this.props.datetime instanceof moment ?
                    <p>The appointment you have selected at <strong>{moment(this.props.datetime).format('M/D/YYYY [at] h:mmA')}</strong> is out of driving range.</p>
                    :
                    <p>The location you have entered is out of our service area.</p>
                }

                {appointments.length > 0
                    ? <div>
                        <p>Please select another appointment:</p>
                        <AppointmentList shortView={false}
                                         services={this.props.order.services}
                                         showInspectorNames={app_state.settings.getSettingBool('ONLINE_SCHEDULER_V3_SHOW_INSPECTOR_NAMES')}
                                         appointments={appointments}
                                         users={this.props.users}
                                         selectAppointment={this.props.selectAppointment}/>
                    </div>
                    : <div>
                        <p>There are no other appointments available. Please call
                            our office at <a href={"tel:"+this.props.office_phone}>{this.props.office_phone}</a> for
                            help scheduling your inspection.</p>
                    </div>}
            </Modal.Body>
            <Modal.Footer>
                <div className="pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default"
                                onClick={this.props.close}>Close</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>;
    }
}

/**
 *
 */
export class PostalConflictModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const excluded = this.props.datetime instanceof moment ? [this.props.datetime.format('YYYY-MM-DD HH:mm[:00]')] : [];

        const appointments = this.props.appointments.filter((apt) => {
            return excluded.indexOf(apt.start_datetime) === -1;
        });

        return <Modal show={true} onHide={this.props.close} bsSize="lg" className="postal-code-substitude-appointments">
            <Modal.Header closeButton>
                <Modal.Title>
                    Sorry!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '5px 10px' }}>
                {this.props.datetime instanceof moment ?
                    <p>The postal code initially entered may have changed. Because of this, the appointment you have selected at <strong>{moment(this.props.datetime).format('M/D/YYYY [at] h:mmA')}</strong> cannot be serviced by the assigned inspectors.</p>
                    :
                    <p>The appointment selected cannot be serviced by the assigned inspectors.</p>
                }

                {appointments.length > 0
                    ? <div>
                        <p>Please select another appointment:</p>
                        <AppointmentList shortView={false}
                                         services={this.props.order.services}
                                         showInspectorNames={app_state.settings.getSettingBool('ONLINE_SCHEDULER_V3_SHOW_INSPECTOR_NAMES')}
                                         appointments={appointments}
                                         users={this.props.users}
                                         selectAppointment={this.props.selectAppointment}/>
                    </div>
                    : <div>
                        <p>There are no other appointments available. Please call
                            our office at <a href={"tel:"+this.props.office_phone}>{this.props.office_phone}</a> for
                            help scheduling your inspection.</p>
                    </div>}
            </Modal.Body>
            <Modal.Footer>
                <div className="pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default"
                                onClick={this.props.close}>Close</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>;
    }
}

export class AddressContainer extends Component  {

    constructor(props) {
        super(props);

        this.updateAddress1 = this.updateAddress1.bind(this);
        this.updateAddress2 = this.updateAddress2.bind(this);
        this.updateCity = this.updateCity.bind(this);
        this.updateState = this.updateState.bind(this);
        this.updatePostalCode = this.updatePostalCode.bind(this);
        this.save = this.save.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.selectAppointment = this.selectAppointment.bind(this);
        this.findAndSuggestAddress = this.findAndSuggestAddress.bind(this);
        this.useSuggest = this.useSuggest.bind(this);
        this.dontUseSuggest = this.dontUseSuggest.bind(this);
        this.setup = this.setup.bind(this);
        this.clearCoordinates = this.clearCoordinates.bind(this);

        // local state
        this.state = {
            validation: {
                address1: null,
                city: null,
                postal_code: null
            },
            address1: props.order.address1,
            address2: props.order.address2,
            city: props.order.city,
            state: props.order.state,
            postal_code: props.order.postal_code,
            latitude: props.order.latitude,
            longitude: props.order.longitude,
            county: props.order.county,
            suggest: {
                no_results: true,
                address1: '',
                address2: '',
                city: '',
                state: '',
                postal_code: '',
                county: '',
                latitude: 0,
                longitude: 0
            },
            saving: false,
            zip_error: false,
            modal: {
                distance: {
                    show: false,
                    current_datetime: null,
                    appointments: []
                },
                postal: {
                    show: false,
                    current_datetime: null,
                    appointments: []
                }
            }
        };
    }

    findAndSuggestAddress() {
        let suggest = this.state.suggest;
        if (this.state.address1 && this.state.address1 !== '' && this.state.city && this.state.city !== '') {
            let address = this.state.address1;
            if (this.state.address2 && '' !== this.state.address2) {
                address += ' ' + this.state.address2;
            }
            address += ' ' + this.state.city;
            if (this.state.state) {
                const state = this.props.states.find((st) => {
                    return st.id === this.state.state;
                });
                if (state) {
                    address += ', ' + state.abbreviation;
                }
            }
            address += ' ' + this.state.postal_code;
            window.geocode_service.geocode({'address': address}, (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    const addressComponents = results[0].address_components;
                    let street_num = '';
                    let address1 = '';
                    let address2 = '';
                    let city = '';
                    let state = null;
                    let state_abbreviation = '';
                    let postal_code = '';
                    let county = '';
                    let latitude;
                    let longitude;

                    for (let i = 0, l = addressComponents.length; i < l; i++) {
                        let item = addressComponents[i];
                        for (let type in item.types) {
                            if (item.types.hasOwnProperty(type)) {
                                switch (item.types[type]) {
                                    case('subpremise'):
                                        address2 = item.long_name;
                                        break;
                                    case('street_number'):
                                        street_num = item.short_name;
                                        break;
                                    case('route'):
                                        address1 = item.short_name;
                                        break;
                                    case('administrative_area_level_1'):
                                        state_abbreviation = item.short_name;
                                        break;
                                    case('administrative_area_level_2'):
                                        county = item.short_name;
                                        break;
                                    case('locality'):
                                        city = item.short_name;
                                        break;
                                    case('administrative_area_level_3'):
                                        if (city === '') city = item.short_name;
                                        break;
                                    case('sublocality'):
                                        if (city === '') city = item.short_name;
                                        break;
                                    case('postal_code'):
                                        postal_code = item.short_name;
                                        break;
                                }
                            }
                        }
                    }

                    latitude = results[0].geometry.location.lat();
                    longitude = results[0].geometry.location.lng();

                    if ('' !== street_num) {
                        address1 = street_num + ' ' + address1;
                    }

                    if ('' !== state_abbreviation) {
                        state = this.props.states.find((st) => {
                            return st.abbreviation === state_abbreviation;
                        });
                    }

                    suggest.no_results = false;
                    suggest.address1 = address1;
                    suggest.address2 = address2;
                    suggest.city = city;
                    suggest.state = state;
                    suggest.postal_code = postal_code;
                    suggest.county = county;
                    suggest.latitude = latitude;
                    suggest.longitude = longitude;

                } else if (status === window.google.maps.GeocoderStatus.ZERO_RESULTS) {
                    suggest.no_results = true;
                } else {
                    suggest.no_results = true;
                    console.error(status);
                }

                this.setState({suggest});
            });
        } else {
            suggest.no_results = true;
            this.setState({suggest});
        }
    }

    useSuggest() {
        let suggest = this.state.suggest;
        suggest.no_results = true;
        this.setState({
            address1: suggest.address1,
            city: suggest.city,
            state: typeof suggest.state === 'object' ? suggest.state.id : this.state.state,
            postal_code: suggest.postal_code,
            latitude: suggest.latitude,
            longitude: suggest.longitude,
            county: suggest.county,
            suggest
        });
    }

    dontUseSuggest() {
        let suggest = this.state.suggest;
        suggest.no_results = true;
        this.clearCoordinates();
        this.setState({suggest});
    }

    clearCoordinates() {
        this.setState({
            latitude: 0,
            longitude: 0
        });
    }

    updateAddress1(event) {
        const value = event.target.value;
        let validation = this.state.validation;
        validation.address1 = value !== '';
        this.clearCoordinates();
        this.setState({validation,address1:value}, this.findAndSuggestAddress);
    }

    updateAddress2(event) {
        const value = event.target.value;
        this.setState({address2:value});
    }

    updateCity(event) {
        const value = event.target.value;
        let validation = this.state.validation;
        validation.city = value !== '';
        this.clearCoordinates();
        this.setState({validation,city:value}, this.findAndSuggestAddress);
    }

    updateState(event) {
        this.clearCoordinates();
        this.setState({state:event.target.value}, this.findAndSuggestAddress);
    }

    updatePostalCode(event) {
        const value = event.target.value;
        let validation = this.state.validation;
        validation.postal_code = value !== '';
        this.clearCoordinates();
        this.setState({validation,postal_code:value,zip_error:false}, this.findAndSuggestAddress);
    }

    prev() {
        this.props.router.history.push('/'+this.props.company_key+'/online-scheduler?t='+this.props.token + '&office=' + this.props.office_uuid);
    }

    next() {
        if (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST')) {
            if (window.ga) {
                window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'Online Scheduler',
                    eventAction: 'started',
                    eventLabel: getAnalyticsEventLabel(this.props.company_key, 'New Online Scheduler Order started')
                });
            }
        }
        this.setState({saving:false});
        this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/contacts?t=' + this.props.token + '&office=' + this.props.office_uuid);
    }

    selectAppointment(appointment) {
        const datetime = moment(appointment.start_datetime);
        this.props.calendarActions.setSelectedAppointment(appointment);
        this.props.orderActions.setDatetime(datetime);
        this.props.orderActions.setInspectors(appointment.users.map((inspector) => { return { id: inspector, requested: false } }));
        this.props.calendarActions.save()
            .then(this.save);
    }

    save() {
        const {
            setAddress1,
            setAddress2,
            setCity,
            setState,
            setPostalCode,
            setCounty,
            setLatitude,
            setLongitude
        } = this.props.orderActions;

        const saveCalendar = this.props.calendarActions.save;

        const { save, savePostalCodeLocalStorage } = this.props.addressActions;

        let validation = this.state.validation;

        validation.address1 = this.state.address1 !== '';
        validation.city = this.state.city !== '';
        validation.postal_code = this.state.postal_code !== '';

        this.setState({validation});

        if (validation.address1 && validation.city && validation.postal_code) {

            setAddress1(this.state.address1);
            setAddress2(this.state.address2);
            setCity(this.state.city);
            setState(this.state.state);
            setPostalCode(this.state.postal_code);
            setCounty(this.state.county);
            savePostalCodeLocalStorage(this.state.postal_code);
            setLatitude(this.state.latitude);
            setLongitude(this.state.longitude);

            this.props.setNavLoading(true);
            this.setState({saving:true});

            let saveCb = () => {
                save().then((res) => {
                    this.setState({saving:false},() => {
                        if (res.status === 'ok') {
                            this.next();
                        } else if (['ERROR_EXCEEDS_RADIUS','ERROR_EXCEEDS_NEXT_APT_RADIUS'].indexOf(res.message) !== -1) {
                            const dte = this.props.order.datetime instanceof moment ? this.props.order.datetime.clone() : null;
                            let modal = this.state.modal;
                            modal.distance.show = true;
                            modal.distance.current_datetime = dte;
                            modal.distance.appointments = res.hasOwnProperty('appointments') ? res.appointments : [];
                            this.setState({modal,saving:false});
                            this.props.setNavLoading(false);
                        } else if ('ERROR_NO_INSPECTORS_FOR_ZIP' === res.message) {
                            this.setState({zip_error:true,saving:false});
                            this.props.setNavLoading(false);
                        } else if ('ERROR_INSPECTOR_DOES_NOT_SERVICE_ZIP' === res.message) {
                            const dte = this.props.order.datetime instanceof moment ? this.props.order.datetime.clone() : null;
                            let modal = this.state.modal;
                            modal.postal.show = true;
                            modal.postal.current_datetime = dte;
                            modal.postal.appointments = res.hasOwnProperty('appointments') ? res.appointments : [];
                            this.setState({modal,saving:false});
                            this.props.setNavLoading(false);
                        }
                    });
                });
            };

            if (this.props.order.datetime && !this.props.order.saved) {
                saveCalendar()
                    .then(async () => {
                        await Utils.sleep(250);
                        saveCb();
                    });
            } else {
                saveCb();
            }
        }
    }

    setup() {
        Utils.scrollTop(500);
        this.props.setNavAddress(true);
        this.props.setNavLoading(false);
    }

    componentDidMount() {
        // run if we got here from an existing state
        if (canSetupOnPageLoad(this.props)) {
            this.setup();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (canSetupOnExistingState(this.props, nextProps)) {
            if (!this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST') && !nextProps.order.datetime) {
                this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/calendar?t=' + this.props.token + '&office=' + this.props.office_uuid);
            } else {
                this.setup();
                this.setState({
                    address1: nextProps.order.address1,
                    address2: nextProps.order.address2,
                    city: nextProps.order.city,
                    state: nextProps.order.state,
                    postal_code: nextProps.order.postal_code,
                    latitude: nextProps.order.latitude,
                    longitude: nextProps.order.longitude,
                    county: nextProps.order.county
                });
            }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
             <link rel="preconnect" href="https://fonts.googleapis.com"></link>
             <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
             <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap" rel="stylesheet"></link>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                {!this.props.app.office_loading && !this.props.app.order_loading && <FixedSideBar {...this.props}/>}
                <Container bodyClasse title="Where is this inspection located?" {...this.props}>

                    <div className="form-horizontal fh-cls">
                        <TextField
                           id="address1"
                           label="Address Line 1"
                           className={classes.textField}
                           type="text"
                           name="address1"
                           margin="normal"
                           variant="outlined"
                           autoComplete="off"
                           value={this.state.address1}
                           onChange={this.updateAddress1}
                           onKeyUp={(e) => { if (e.key === 'Enter') { this.save() } }}
                           style={{width:'95%'}}
                           helperText={"Required"}
                           InputProps={{
                            classes: {
                              input: classes.resize,
                              notchedOutline: this.state.validation.address1 === false ? classes.notchedOutline : '',
                            },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputRoot,
                                  },
                                  shrink: true
                              }}
                        />

                    <TextField
                       id="address2"
                       label="Address Line 2"
                       className={classes.textField}
                       type="text"
                       name="address2"
                       margin="normal"
                       variant="outlined"
                       autoComplete="off"
                       value={this.state.address2}
                       onChange={this.updateAddress2}
                       onKeyUp={(e) => { if (e.key === 'Enter') { this.save() } }}
                       style={{width:'95%'}}
                       InputProps={{
                        classes: {
                          input: classes.resize,
                          notchedOutline: this.state.validation.address2 === false ? classes.notchedOutline : '',
                        },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputRoot,
                              },
                              shrink: true
                          }}
                    />


                    <TextField
                       id="city"
                       label="City"
                       className={classes.textField}
                       type="text"
                       name="city"
                       margin="normal"
                       variant="outlined"
                       autoComplete="off"
                       value={this.state.city}
                       onChange={this.updateCity}
                       onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                       style={{width:'95%'}}
                       helperText={"Required"}
                       InputProps={{
                        classes: {
                          input: classes.resize,
                          notchedOutline: this.state.validation.city === false ? classes.notchedOutline : '',
                        },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputRoot,
                              },
                              shrink: true
                          }}
                    />

                    <FormControl variant="outlined" className='state-input-container' style={{ marginTop: 14, marginLeft: 7, width: '95%', marginBottom: '18px'}}>
                            <InputLabel style={{ fontSize: 22,background: "white",paddingRight: 5,position: "absolute",top: "-3px",fontFamily: 'Source Sans Pro',}} shrink>State</InputLabel>
                            <Select label={app_state.state_label}
                                value={this.state.state}
                                onChange={this.updateState}
                                style={{fontSize: "16px"}}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                  },
                                  getContentAnchorEl: null
                                }}
                                inputProps={{
                                    classes: {
                                      icon: classes.icon,
                                    },
                                  }}
                                >
                                {this.props.states?.map((state, key) => {
                                    return (
                                        <MenuItem style={{fontSize: "16px"}} key={key} value={state.id}>{state.abbreviation}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                    <TextField
                       id="pin"
                       label={app_state.country === 'United States' ? 'Zip Code' : 'Postal Code'}
                       className={classes.textField}
                       type="text"
                       name="address2"
                       margin="normal"
                       variant="outlined"
                       autoComplete="off"
                       value={this.state.postal_code}
                       onChange={this.updatePostalCode}
                       onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                       style={{width:'95%'}}
                       helperText={"Required"}
                       InputProps={{
                        classes: {
                          input: classes.resize,
                          notchedOutline: this.state.validation.postal_code === false ? classes.notchedOutline : '',
                        },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputRoot,
                              },
                              shrink: true
                          }}
                    />
                    </div>

                    {this.state.suggest && !this.state.suggest.no_results && this.state.suggest.address1 !== '' && this.state.suggest.city !== '' &&
                        <div className={'fh-cls'} style={{ marginTop: '25px'}} >
                            <div className={'alert alert-warning alert-tight verify-address-cls'}>
                                <div style={{ color: "#0B5284", fontWeight: 600, padding: "8px 16px", lineHeight: "20px" }}><span style={{ position:'relative', top:'6px' }} class="material-symbols-outlined iconclose">location_on</span> <span className={"alert-message"}>&nbsp;Verify Address, is this correct?</span></div>
                                <address  style={{ paddingTop: "16px", marginLeft: "17px", fontWeight: 400, color: "#454545",fontFamily:"Source Sans Pro", marginTop: "-10px",fontSize:"14px" }}>
                                    {this.state.suggest.address1}{this.state.suggest.address2 && this.state.suggest.address2 !== '' ? ' ' + this.state.suggest.address2 : ''}<br />
                                    {this.state.suggest.city}{this.state.suggest.state && typeof this.state.suggest.state === 'object' ? ', ' + this.state.suggest.state.abbreviation : ''}
                                    {this.state.suggest.postal_code && '' !== this.state.suggest.postal_code ? ' ' + this.state.suggest.postal_code : ''}
                                </address>
                                <div className={'btn-toolbar'} style={{ marginBottom: "8px" }}>
                                    <button type={'button'}  className={'verify-address-btn-y'} onClick={this.useSuggest}><span class="material-symbols-rounded iconclose">check</span><span class="verify-address-btn-txt">&nbsp;Yes</span></button>
                                    <button type={'button'}  className={'verify-address-btn-n'} onClick={this.dontUseSuggest}><span class="material-symbols-rounded iconclose">close</span><span class="verify-address-btn-txt">No, let me use the address i've entered</span></button>
                                </div>
                            </div>
                        </div>}

                        {this.state.zip_error && <div className={'alert alert-danger alert-tight zip-code-div'} style={{marginTop:'25px'}}>
                            <h4 style={{margin:'5px 0'}}>Sorry!</h4>
                            <p>We don't service that postal code. If you'd like further assistance, please call our office at <a href={"tel:"+this.props.office_phone}>{this.props.office_phone}</a>.</p>
                        </div>}


                    <div className="action-buttons-space-address">
                        {!this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST') && 
                        <BackButton className="back-btn-class" back={this.prev}/>}
                        <NextButton className={this.state?.saving ? 'hide-button' : 'next-btn-class'} next={this.save}  disabled={this.state.saving}/>
                        <button className={this.state?.saving ? 'next-btn-class show-button' : 'hide-button'} >&nbsp;<i className="fa fa-spinner fa-spin"></i>&nbsp;</button>
                    </div>

                </Container>
                {this.state.modal.distance.show && <DistanceConflictModal
                    datetime={this.state.modal.distance.current_datetime}
                    appointments={this.state.modal.distance.appointments}
                    selectAppointment={this.selectAppointment}
                    order={this.props.order}
                    users={this.props.users}
                    office_phone={this.props.office_phone}
                    close={() => {
                        let modal = this.state.modal;
                        modal.distance.show = false;
                        this.setState({modal});
                    }}/>}
                {this.state.modal.postal.show && <PostalConflictModal
                    datetime={this.state.modal.postal.current_datetime}
                    appointments={this.state.modal.postal.appointments}
                    selectAppointment={this.selectAppointment}
                    order={this.props.order}
                    users={this.props.users}
                    office_phone={this.props.office_phone}
                    close={() => {
                        let modal = this.state.modal;
                        modal.postal.show = false;
                        this.setState({modal});
                    }}/>}
            </div>);
    }
}

/**
 *
 */
export default withStyles(styles)(withRouter(connect(
    (state, ownProps) => {
        return { router: ownProps, ...state.Address, ...state.Office, order: state.Order, nav: state.Nav, app: state.App }
    },
    (dispatch) => {
        return {
            addressActions: bindActionCreators(AddressActions, dispatch),
            calendarActions: bindActionCreators(CalendarActions, dispatch),
            orderActions: bindActionCreators(OrderActions, dispatch),
            officeActions: bindActionCreators(OfficeActions, dispatch),
            setNavAddress: (value) => dispatch(setNavAddress(value)),
            setNavLoading: (value) => dispatch(setNavLoading(value))
        }
    }
)(AddressContainer)))


