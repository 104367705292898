import {
    CALENDAR_SET_LOADING,
    CALENDAR_SET_LOADING_MORE,
    CALENDAR_SET_TIME_AVAILABLE,
    CALENDAR_SET_SELECTED_DATE,
    CALENDAR_SET_SELECTED_APPOINTMENT,
    CALENDAR_SET_LIST_MODE_LAST_DATE,
    CALENDAR_SET_AVAILABILITY,
    CALENDAR_SERVICES_CHANGED
} from '../constants.jsx'
import request from 'superagent'
import moment from 'moment'
import { setSaved } from './Order.jsx'
import { getMaxDate } from '../util.jsx'
import errorHandler from '../error.jsx'

export function setLoading (value) {
    return { type: CALENDAR_SET_LOADING, value }
}
export function setLoadingMore (value) {
    return { type: CALENDAR_SET_LOADING_MORE, value }
}
export function setTimeAvailable (value) {
    return { type: CALENDAR_SET_TIME_AVAILABLE, value }
}
export function setSelectedDate (value) {
    return { type: CALENDAR_SET_SELECTED_DATE, value }
}
export function setSelectedAppointment (value) {
    return { type: CALENDAR_SET_SELECTED_APPOINTMENT, value }
}
export function setListModeLastDate (value) {
    return { type: CALENDAR_SET_LIST_MODE_LAST_DATE, value }
}
export function setAvailability (value) {
    return { type: CALENDAR_SET_AVAILABILITY, value }
}
export function setServicesChanged (value) {
    return { type: CALENDAR_SERVICES_CHANGED, value }
}

function localAppointmentExists(needle, haystack)
{
    for (let p in haystack) {
        if (haystack.hasOwnProperty(p)) {
            for (let i = 0, l = haystack[p].length; i < l; i++) {
                if (haystack[p][i].id === needle) {
                    return true;
                }
            }
        }
    }
    return false;
}

/**
 *
 * @param from
 * @param to
 * @param limit
 * @param duration
 * @param inspectors
 * @param services
 * @param postal_code
 * @returns {function(*, *)}
 */
export function getAvailability (from, to, limit = 0, duration = 0, inspectors = [], services = [], postal_code = '') {
    return (dispatch, getState) => {
        dispatch(setLoading(true));

        const state = getState();
        const { token, company_key } = state.Office;
        let current = from.clone();
        let availability = state.Calendar.availability;

        while (current <= to) {
            if (!availability.hasOwnProperty(current.format('YYYY-MM-DD'))) {
                availability[current.format('YYYY-MM-DD')] = [];
            }
            current.add(1, 'days');
        }

        let params = [
            '?from='+from.format('YYYY-MM-DD'),
            '&to='+to.format('YYYY-MM-DD')
        ];

        if (duration > 0) {
            params.push('&duration=' + duration);
        }

        if (limit > 0) {
            params.push('&limit=' + limit);
        }

        if (postal_code) {
            params.push('&postal_code='+postal_code);
        }

        if (inspectors.length > 0) {
            params.push('&inspectors=[' + inspectors.join(',') + ']');
        }

        if (services.length > 0) {
            params.push('&services=["' + services.join('","') + '"]');
        }

        return request.get('/'+company_key+'/json/online_scheduler/availability'+encodeURI(params.join('')))
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .then((res) => {
                if (res.body instanceof Array) {
                    if (res.body.length > 0) {
                        for (let i = 0, l = res.body.length; i < l; i++) {
                            if (!localAppointmentExists(res.body[i].id, availability)) {
                                if (typeof availability[res.body[i].date] === 'undefined') {
                                    availability[res.body[i].date] = [];
                                }
                                availability[res.body[i].date].push(res.body[i]);
                            }
                        }
                    } else {
                        for (let i in availability) {
                            if (availability.hasOwnProperty(i)) {
                                availability[i] = [];
                            }
                        }
                    }

                    let keys = Object.keys(availability).filter((key) => {
                        return availability[key].length > 0;
                    });
                    keys.sort().reverse();

                    let lastDate = keys.length > 0 ? moment(keys[0]) : to;

                    dispatch(setLoading(false));
                    dispatch(setAvailability(availability));
                    dispatch(setListModeLastDate(lastDate));
                }

                return availability;
            });
    };
}

/**
 *
 * @param limit
 * @param duration
 * @param inspectors
 * @param services
 * @param postal_code
 * @returns {function(*, *)}
 */
export function validateInspectorServices(limit = 0, duration = 0, inspectors = [], services = [], postal_code = '') {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        let params = ['?duration=' + duration];

        if (limit > 0) {
            params.push('&limit=' + limit);
        }

        if (postal_code) {
            params.push('&postal_code='+postal_code);
        }

        if (inspectors.length > 0) {
            params.push('&inspectors=[' + inspectors.join(',') + ']');
        }

        if (services.length > 0) {
            params.push('&services=["' + services.join('","') + '"]');
        }

        return request.get('/'+company_key+'/json/online_scheduler/validate_inspectors_services'+params.join(''))
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .then((res) => {
                return res.body;
            });
    };
}

/**
 *
 * @param from
 * @param to
 * @param duration
 * @param limit
 * @param inspectors
 * @param services
 * @param postal_code
 * @returns {function(*=, *)}
 */
export function isTimeAvailable(from, to, duration = 0, limit = 0, inspectors = [], services = [], postal_code = '') {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        let params = [
            '?from='+from.format('YYYY-MM-DDTHH:mm:00-00:00'),
            '&to='+to.format('YYYY-MM-DDTHH:mm:00-00:00'),
            '&duration='+duration
        ];

        if (limit > 0) {
            params.push('&limit=' + limit);
        }

        if (postal_code) {
            params.push('&postal_code='+postal_code);
        }

        if (inspectors.length > 0) {
            params.push('&inspectors=[' + inspectors.join(',') + ']');
        }
        
        if (services.length > 0) {
            params.push('&services=["' + services.join('","') + '"]');
        }

        return request.get('/'+company_key+'/json/online_scheduler/time_available'+params.join(''))
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .then((res) => {
                dispatch(setTimeAvailable(res.body.status === 'yes'));
                return res.body;
            });
    };
}

/**
 *
 * @param date
 * @returns {function(*, *)}
 */
export function onCalendarDateChange(date) {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            const state = getState();
            const { availability } = state.Calendar;
            const order = state.Order;
            let maxDate = getMaxDate(availability);
            let diffWeeks = Math.abs(maxDate.diff(date, 'weeks'));
            if (date > moment() && diffWeeks < 4) {
                let from = date.clone();
                let to = from.clone();
                to.add(6, 'weeks');
                dispatch(getAvailability(from, to, 0, order.duration, [], order.services, order.postal_code))
                    .then(resolve);
            } else {
                resolve();
            }
        });
    };
}

/**
 *
 * @returns {function(*, *)}
 */
export function save() {
    return (dispatch, getState) => {
        const state = getState();
        const order = state.Order;
        const { token, company_key } = state.Office;

        let payload = {
            datetime: order.datetime instanceof moment ? order.datetime.format() : null,
            duration: order.duration,
            inspectors: order.inspectors.map((item) => { return item.id })
        };

        return request.post('/'+company_key+'/json/online_scheduler/set_datetime')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(payload)
            .then(() => {
                dispatch(setSaved(true));
            })
    }
}