import { setSaved, setDuration } from './Order.jsx'
import request from 'superagent'
import errorHandler from '../error.jsx'

export function getSpecialNoteLocalStorage() {
    return (dispatch, getState) => {
        const state = getState();
        const { token } = state.Office;
        const key = 'isn-'+token+'-special_note';
        return localStorage.getItem(key);
    };
}

export function saveSpecialNoteLocalStorage(note) {
    return (dispatch, getState) => {
        const state = getState();
        const { token  } = state.Office;
        const key = 'isn-'+token+'-special_note';
        localStorage.setItem(key, note);
    };
}

/**
 *
 * @returns {function(*, *)}
 */
export function save() {
    return (dispatch, getState) => {
        const state = getState();
        const order = state.Order;
        const { token, company_key } = state.Office;
        const payload = {
            sqft: order.sqft,
            yb: order.yb,
            sales_price: order.sales_price,
            foundation_type: typeof order.foundation_type === 'object' ? order.foundation_type.id : '',
            refer_reason: order.refer_reason,
            refer_reason_other: order.refer_reason_other,
            controls: order.controls
        };
        return request.post('/'+company_key+'/json/online_scheduler/set_property_info')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(payload)
            .then((res) => {
                if (res.body.hasOwnProperty('duration')) {
                    dispatch(setDuration(res.body.duration));
                }
                dispatch(setSaved(true));
            });
    }
}