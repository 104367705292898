import React, { Component } from 'react'
import {app_state} from '../app_data.jsx'

export default class PersonViewForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const stateId = parseInt(this.props.state);

        const state = this.props.states.find((state) => {
            return state.id === stateId;
        });
        
        const fields = this.props.hasOwnProperty('fields') && this.props.fields instanceof Array ? this.props.fields : [];

        return (
            <div>
                {this.props.compact
                    ? <div>
                        {this.props.first} {this.props.last}<br/>
                        {fields.indexOf('mobile_phone') !== -1 && this.props.mobile_phone !== '' && <div>mobile: {this.props.mobile_phone}</div>}
                        {fields.indexOf('work_phone') !== -1 && this.props.work_phone !== '' && <div>work: {this.props.work_phone}</div>}
                        {fields.indexOf('home_phone') !== -1 && this.props.home_phone !== '' && <div>home: {this.props.home_phone}</div>}
                        {fields.indexOf('work_fax') !== -1 && this.props.work_fax !== '' && <div>work fax: {this.props.work_fax}</div>}
                        {fields.indexOf('home_fax') !== -1 && this.props.home_fax !== '' && <div>home fax: {this.props.home_fax}</div>}
                        {fields.indexOf('email') !== -1 && this.props.email !== '' && <div>{this.props.email}</div>}
                        {fields.indexOf('address1') !== -1 && this.props.address1 !== '' && <div>{this.props.address1}</div>}
                        {fields.indexOf('address2') !== -1 && this.props.address2 !== '' && <div>{this.props.address2}</div>}
                        {fields.indexOf('city') !== -1 && this.props.city !== '' && <span>{this.props.city}</span>}
                        {fields.indexOf('state') !== -1 && this.props.state !== '' && state ? ', ' + state.abbreviation : ''}
                        {fields.indexOf('postal_code') !== -1 && this.props.postal_code !== '' && <span>&nbsp;{this.props.postal_code}</span>}
                        {this.props.children}
                        <div style={{clear:'both'}}/>
                    </div>
                    : <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">First Name:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.first}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">Last Name:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.last}</p>
                            </div>
                        </div>
                        {fields.indexOf('email') !== -1 && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">Email:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.email}</p>
                            </div>
                        </div>}
                        {fields.indexOf('mobile_phone') !== -1 && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">Mobile Phone:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.mobile_phone}</p>
                            </div>
                        </div>}
                        {fields.indexOf('work_phone') !== -1 && '' !== this.props.work_phone && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">Work Phone:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.work_phone}</p>
                            </div>
                        </div>}
                        {fields.indexOf('home_phone') !== -1 && '' !== this.props.home_phone && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">Home Phone:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.home_phone}</p>
                            </div>
                        </div>}
                        {fields.indexOf('work_fax') !== -1 && '' !== this.props.work_fax && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">Work Fax:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.work_fax}</p>
                            </div>
                        </div>}
                        {fields.indexOf('home_fax') !== -1 && '' !== this.props.home_fax && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">Home Fax:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.home_fax}</p>
                            </div>
                        </div>}
                        {fields.indexOf('address1') !== -1 && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">Address:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.address1}</p>
                                {fields.indexOf('address2') !== -1 && '' !== this.props.address2 && <p>{this.props.address2}</p>}
                            </div>
                        </div>}
                        {fields.indexOf('city') !== -1 && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">City:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.city}</p>
                            </div>
                        </div>}
                        {fields.indexOf('state') !== -1 && state && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">{app_state.state_label}:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{state.abbreviation}</p>
                            </div>
                        </div>}
                        {fields.indexOf('postal_code') !== -1 && <div className="form-group">
                            <label className="col-sm-2 col-md-4 control-label">Postal Code:</label>
                            <div className="col-sm-10 col-md-8">
                                <p className="form-control-static">{this.props.postal_code}</p>
                            </div>
                        </div>}
                        {this.props.children}
                    </form>
                }
            </div>
        )
    }
}
