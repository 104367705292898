import { setSaved } from './Order.jsx'
import request from 'superagent'
import errorHandler from '../error.jsx'

/**
 *
 * @param datetime
 * @returns {function(*=, *)}
 */
export function getDistanceOnDateTime(datetime) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        return request.get('/'+company_key+'/json/online_scheduler/distance_on_datetime?datetime='+datetime.format())
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .then((res) => {
                return res.body;
            });
    };
}

export function getPostalCodeLocalStorage() {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        const postalCodeKey = company_key+'_'+token+'_postal_code';
        return localStorage.getItem(postalCodeKey);
    };
}

export function savePostalCodeLocalStorage(postalCode) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        const postalCodeKey = company_key+'_'+token+'_postal_code';
        localStorage.setItem(postalCodeKey, postalCode);
    };
}

/**
 *
 * @returns {function(*, *)}
 */
export function save() {
    return (dispatch, getState) => {
        const state = getState();
        const order = state.Order;
        const { token, company_key } = state.Office;

        const payload = {
            address1: order.address1,
            address2: order.address2,
            city: order.city,
            state: order.state,
            postal_code: order.postal_code,
            latitude: order.latitude,
            longitude: order.longitude,
            county: order.county
        };

        return request.post('/'+company_key+'/json/online_scheduler/set_address')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(payload)
            .then((res) => {
                dispatch(setSaved(true));
                return res.body;
            })
    }
}