import React, { Component, useState } from 'react'
import {app_state} from '../app_data.jsx'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, FormHelperText, FormControl, InputLabel } from '@material-ui/core';
import "./contactinfo.css";

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
    },
    dense: {
      marginTop: 16,
    },
    menu: {
      width: 200,
    },
    notchedOutline: { borderColor: '#a94442 !important' },
    resize:{
        fontSize:16,
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          // borderColor: '#0F75BC', 
          borderWidth: 1, 
        },
      },
    inputRoot: {
        fontSize: 22,
        background: "white",
        paddingRight: 5,
        position: "absolute",
        top: "-3px",
        fontFamily: 'Source Sans Pro',
    },
    icon: {
        // fill: theme.palette.primary.main,
        right: '12px',
        top: 'calc(42% - 12px)', // Adjust the position if needed
        width: '30px', // Increase or decrease the size as per your needs
        height: '30px', // Increase or decrease the size as per your needs
        position: 'absolute',
        pointerEvents: 'none',
      },
});



class PersonEditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        selected: ''
        };
    }

    setSelected = (value) => {
        this.setState({selected: value});
    }

    selectionChangeHandler = (event) => {
        setSelected(event.target.value);
      };

    formatPhoneNumber = (phoneNumber) => {
      if (!phoneNumber) return phoneNumber;
        const digitsOnly = phoneNumber.replace(/\D/g, '');

        if (digitsOnly.length <= 10) {
          return digitsOnly.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
        } else if (digitsOnly.length === 11 || digitsOnly.length > 11) {
          return digitsOnly.replace(/^(\d)(\d{3})(\d{3})(\d{0,14})$/, '1($2) $3-$4');
        } else {
          return digitsOnly.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
        }
      };
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={'form-group'+(this.props.validation.first === false ? ' has-error' : '')} style={{paddingRight: "2%"}}>

                    <div className="col-md-11 col-lg-5 person-edit-form-padding">
                        <TextField
                                id="name"
                                label="First Name"
                                className={classes.textField}
                                type="text"
                                name="name"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                               ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_first', el) }}
                               value={this.props.first.value}
                               onChange={this.props.first.update}
                               onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('first') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.first === false ? classes.notchedOutline : '',
                                },
                                }}
                              InputLabelProps={{
                                classes: {
                                    root: classes.inputRoot,
                                  },
                                shrink: true
                              }}
                            />
                    </div>
                    <div className={(this.props.validation.last === false ? ' has-error' : '')}>
                    <div className="col-md-11 col-lg-5" >
                         <TextField
                                id="last_name"
                                label="Last Name"
                                className={classes.textField}
                                type="text"
                                name="last_name"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                               ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_last', el) }}
                               value={this.props.last.value}
                               onChange={this.props.last.update}
                               onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                                helperText={this.props.validation.hasOwnProperty('last') && "Required"}
                                InputProps={{
                                    classes: {
                                      input: classes.resize,
                                      notchedOutline: this.props.validation.last === false ? classes.notchedOutline : '',
                                    },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                          },
                                          shrink: true
                                      }}
                            />
                    </div>
                </div>
                </div>
                             
                {this.props.fields.indexOf('email') !== -1 && <div className={'form-group'+(this.props.validation.email === false ? ' has-error' : '')}>
                    <div className="col-md-11 col-lg-10">
                        <TextField
                                id="email"
                                label="Email"
                                className={classes.textField}
                                type="email"
                                name="email"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_email', el) }}
                                value={this.props.email.value}
                                onChange={this.props.email.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                                style={{width:'95%'}}
                                helperText={this.props.validation.hasOwnProperty('email') && "Required"}
                                InputProps={{
                                    classes: {
                                      input: classes.resize,
                                      notchedOutline: this.props.validation.email === false ? classes.notchedOutline : '',
                                    },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                          },
                                          shrink: true
                                      }}
                            />
                    </div>
                </div>}
                {this.props.fields.indexOf('mobile_phone') !== -1 && <div className={'form-group'+(this.props.validation.mobile_phone === false ? ' has-error' : '')}>
                    <div className="col-md-11 col-lg-10">
                        <TextField
                                id="mobile"
                                label="Mobile Phone"
                                className={classes.textField}
                                type="text"
                                name="mobile"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_mobile_phone', el) }}
                                value={this.formatPhoneNumber(this.props.mobile_phone.value)}
                                onChange={this.props.mobile_phone.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('mobile_phone') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.mobile_phone === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.fields.indexOf('work_phone') !== -1 && <div className={'form-group'+(this.props.validation.work_phone === false ? ' has-error' : '')}>
                    <div className="col-md-11 col-lg-10">
                        <TextField
                                id="phone"
                                label="Work Phone"
                                className={classes.textField}
                                type="text"
                                name="work_phone"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_work_phone', el) }}
                                value={this.formatPhoneNumber(this.props.work_phone.value)}
                                onChange={this.props.work_phone.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('work_phone') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.work_phone === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.fields.indexOf('home_phone') !== -1 && <div className={'form-group'+(this.props.validation.home_phone === false ? ' has-error' : '')}>

                    <div className="col-md-11 col-lg-10">

                        <TextField
                                id="home_phone"
                                label="Home Phone"
                                className={classes.textField}
                                type="text"
                                name="home_phone"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_home_phone', el) }}
                                value={this.formatPhoneNumber(this.props.home_phone.value)}
                                onChange={this.props.home_phone.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('home_phone') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.home_phone === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.fields.indexOf('work_fax') !== -1 && <div className={'form-group'+(this.props.validation.work_fax === false ? ' has-error' : '')}>

                    <div className="col-md-11 col-lg-10">

                        <TextField
                                id="fax"
                                label="Work Fax"
                                className={classes.textField}
                                type="text"
                                name="work_fax"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_work_fax', el) }}
                               value={this.props.work_fax.value}
                               onChange={this.props.work_fax.update}
                               onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('work_fax') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.work_fax === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.fields.indexOf('home_fax') !== -1 && <div className={'form-group'+(this.props.validation.home_fax === false ? ' has-error' : '')}>
                    <div className="col-md-11 col-lg-10">
                        <TextField
                                id="fax"
                                label="Home Fax"
                                className={classes.textField}
                                type="text"
                                name="home_fax"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_home_fax', el) }}
                                value={this.props.home_fax.value}
                                onChange={this.props.home_fax.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('home_fax') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.home_fax === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.fields.indexOf('address1') !== -1 && <div className={'form-group'+(this.props.validation.address1 === false ? ' has-error' : '')}>
                    <div className="col-md-11 col-lg-10">
                        <TextField
                                id="address1"
                                label="Address Line 1"
                                className={classes.textField}
                                type="text"
                                name="address1"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_address1', el) }}
                                value={this.props.address1.value}
                                onChange={this.props.address1.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('address1') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.address1 === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.fields.indexOf('address2') !== -1 && <div className="form-group">
                    <div className="col-md-11 col-lg-10">
                                <TextField
                                id="address2"
                                label="Address Line 2"
                                className={classes.textField}
                                type="text"
                                name="address2"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_address2', el) }}
                                value={this.props.address2.value}
                                onChange={this.props.address2.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.address2 === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.fields.indexOf('city') !== -1 && <div className={'form-group'+(this.props.validation.city === false ? ' has-error' : '')}>
                    <div className="col-md-11 col-lg-10">

                        <TextField
                                id="city"
                                label="City"
                                className={classes.textField}
                                type="text"
                                name="city"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_city', el) }}
                                value={this.props.city.value}
                                onChange={this.props.city.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('city') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.city === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.fields.indexOf('state') !== -1 && <div className="form-group">
                    <div className="">
                        <FormControl variant="outlined" className='state-input-container' style={{ marginTop: 14, marginLeft: 22}}>
                            <InputLabel style={{ fontSize: 22,background: "white",paddingRight: 5,position: "absolute",top: "-3px",fontFamily: 'Source Sans Pro',}} shrink>State</InputLabel>
                            <Select name="state" label="State" ref={(el) => { this.props.setRef('ref_' + (this.props.ref_key || 'contact_form') + '_state', el) }}
                                value={this.props.state.value}
                                onChange={this.props.state.update}
                                style={{fontSize: "16px"}}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                  },
                                  getContentAnchorEl: null
                                }}
                                inputProps={{
                                    classes: {
                                      icon: classes.icon,
                                    },
                                  }}
                                >
                                {this.props.states.map((state, key) => {
                                    return (
                                        <MenuItem style={{fontSize: "16px"}} key={key} value={state.id}>{state.abbreviation}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>}
                {this.props.fields.indexOf('postal_code') !== -1 && <div className={'form-group'+(this.props.validation.postal_code === false ? ' has-error' : '')}>

                    <div className="col-md-11 col-lg-10">
                        <TextField
                                id="pin"
                                label="Postal Code"
                                className={classes.textField}
                                type="text"
                                name="pin"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_postal_code', el) }}
                                value={this.props.postal_code.value}
                                onChange={this.props.postal_code.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('postal_code') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.postal_code === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.hasOwnProperty('company') && <div className={'form-group'+(this.props.validation.company === false ? ' has-error' : '')}>

                    <div className="col-md-11 col-lg-10">
                        <TextField
                                id="company"
                                label="Company"
                                className={classes.textField}
                                type="text"
                                name="company"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                ref={(el) => { this.props.setRef('ref_'+(this.props.ref_key||'contact_form')+'_company', el) }}
                                value={this.props.company.value}
                                onChange={this.props.company.update}
                                onKeyUp={(e) => { if (e.key === 'Enter') { this.props.save() } }}
                               style={{width:'95%'}}
                               helperText={this.props.validation.hasOwnProperty('company') && "Required"}
                               InputProps={{
                                classes: {
                                  input: classes.resize,
                                  notchedOutline: this.props.validation.company === false ? classes.notchedOutline : '',
                                },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                      },
                                      shrink: true
                                  }}
                            />
                    </div>
                </div>}
                {this.props.children}
            </div>            
        )
    }
}
export default withStyles(styles)(PersonEditForm);