import React, { Component } from 'react'
import { withRouter } from 'react-router'
import FixedSideBar from './FixedSideBar.jsx'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as PropertyInfoActions from '../Actions/PropertyInfo.jsx'
import * as OrderActions from '../Actions/Order.jsx'
import * as OfficeActions from '../Actions/Office.jsx'
import * as PackagesActions from '../Actions/Packages.jsx'
import NextButton from './NextButton.jsx'
import BackButton from './BackButton.jsx'
import Container from './Container.jsx'
import Utils from '../../Common/Utils.jsx'
import { setNavPropertyInfo, setNavLoading } from '../Actions/Nav.jsx'
import { canSetupOnExistingState, canSetupOnPageLoad } from '../util.jsx';
import { Select, MenuItem, FormHelperText, FormControl, InputLabel, Tooltip} from '@material-ui/core';
import DatePicker from 'react-datetime';
import { withStyles } from '@material-ui/core/styles';
import 'react-datetime/css/react-datetime.css';
import './compstyles.css';


const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
    },
    dense: {
      marginTop: 16,
    },
    menu: {
      width: 200,
    },
    focusedSelect: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#a94442', // Replace 'red' with your desired focused border color
        },
      },
    icon: {
        // fill: theme.palette.primary.main,
        right: '12px',
        top: 'calc(42% - 12px)', // Adjust the position if needed
        width: '30px', // Increase or decrease the size as per your needs
        height: '30px', // Increase or decrease the size as per your needs
        position: 'absolute',
        pointerEvents: 'none',
      },
});

const StyledTooltip = withStyles({
    tooltip: {
      fontSize: "1.01em",
      fontFamily: 'Source Sans Pro',
      width: "auto"
    }
  })(Tooltip);
  

const IconWithTooltip = (props) => (
    <StyledTooltip title={props.label}>
       <span>{ props.label.substring(0,20) + '...' }</span>
    </StyledTooltip>
);

const RequiredText = () => (
    <span className='required' title={'required'}>Required</span>
);

const RequiredCheckBox = () => (
    <span className='required-checkbox' title={'required'}>Required</span>
);

const HiddenText = () => (
    <label>&nbsp;</label>
);

const FeeIncluded = () => (
    <span className="feeIncluded-text">May change price</span>
);

const FeeIncludedOptionalDash = () => (
    <span className="feeIncluded-text">&nbsp;{'-'}&nbsp;</span>
);

const ActiveLable = (props) => (
    <label  className="labelText activeLabal">{props.label !== '' && props.label.length > 25  ? <IconWithTooltip label={ props.label }/> : props.label}</label >
)

const ActiveLableAdditional = (props) => (
    <label className="labelTextAdditional activeLabal">{props.label !== '' && props.label.length > 25 ? <IconWithTooltip label={ props.label } /> : ''}</label>
)

const YearBuilt = (props) => (

    <div className={'activeDiv'+(props.validated === false  ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
        <ActiveLable label={props.label !== '' ? props.label + ' ' : ''}/>
        <input type="text" className="form-control property-textbox activeLabal"
               size={props.length || 24} maxLength={props.length || 4} value={props.value} 
               onChange={(e) => { props.onChange(e.target.value, props.slug, props.id) }}
               onKeyUp={(e) => { if (e.key === 'Enter') { props.save() } }}
               ref={(el) => { props.setRef(props.slug, el) }}/>
               {props.required == true ? <RequiredText/> : ''}

    </div>

);

const SalesPrice = (props) => (

    <div className={'activeDiv'+(props.validated === false  ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
        <ActiveLable label={props.label !== '' ? props.label + ' ' : ''}/>
        <input type="text" className="form-control property-textbox activeLabal"
           size={props.length || 24} maxLength="12" value={props.value}
           onChange={(e) => { props.onChange(e.target.value, props.slug, props.id) }}
           onKeyUp={(e) => { if (e.key === 'Enter') { props.save() } }}
           ref={(el) => { props.setRef(props.slug, el) }}/>
           {props.required == true ? <RequiredText/> : ''}

    </div>

);

const SquareFeet = (props) => (
    <div className={'activeDiv'+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '') }>
        <ActiveLable label={props.label !== '' ? props.label + ' ' : ''}/>
        <input type="text" className="form-control property-textbox activeLabal" 
           size={props.length || 24} maxLength="12" value={props.value}
           onChange={(e) => { props.onChange(e.target.value, props.slug, props.id) }}
           onKeyUp={(e) => { if (e.key === 'Enter') { props.save() } }}
           ref={(el) => { props.setRef(props.slug, el) }}/>
            {props.required == true ? <RequiredText/> : ''}
    </div>
);


const FoundationType = (props) => (
    <div className={'activeDiv dropdownArrow control-spacing'+(props.validated === false ? ' has-error' : '') }>
            <FormControl variant="outlined" className='state-input-container' style={{ marginTop: 14, width: '100%'}}>
                            <InputLabel style={{ pointerEvents:"auto", fontSize: 22,background: "white",paddingRight: 5,position: "absolute",top: "-3px",fontFamily: 'Source Sans Pro',}} shrink>{props.label !== '' && props.label.length > 23 ? <IconWithTooltip label={ props.label } /> : props.label}</InputLabel>
                            <Select ref={(el) => { props.setRef(props.slug, el) }}
                                value={typeof props.value === 'object' ? props.value.id : (props.required ? '' : (props.options.length > 0 ? props.options[0].id : ''))}
                                onChange={(e) => { props.onChange(e.target.value, props.slug, props.id) }}
                                style={{fontSize: "16px",marginBottom: "2px"}}
                                className={ props.validated === false ? props.classStyle.focusedSelect : ''}
                                displayEmpty
                                MenuProps={{
                                    autoFocus: false,
                                    disableAutoFocusItem: true,
                                    disableEnforceFocus: true,
                                    disableAutoFocus: true,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                disableAutoFocus
                                disableAutoFocusItem
                                inputProps={{
                                    classes: {
                                      icon: props.classStyle.icon,
                                    },
                                  }}
                                >
                                {props.required == true ? (<MenuItem style={{fontSize: "16px"}} value="">
                                    Select
                                </MenuItem>) : null }
                                {props.options.map((option, key) => {
                                    return (
                                        <MenuItem style={{fontSize: "16px"}} key={key} value={option.id}>{option.value}</MenuItem>
                                    )
                                })}
                            </Select>
                            {/* <FormHelperText>Select a month</FormHelperText> */}
                        </FormControl>
                            {props.required == true ? <RequiredText style={{paddingTop: 2}}/> : ''}
                            {props.fee && !props.hideFees && props.required == true ? <FeeIncludedOptionalDash style={{paddingTop: 2}}/>: ''}
                            {props.fee && !props.hideFees && <FeeIncluded style={{paddingTop: 2}}/>}

    </div>
);
    
const TextBox = (props) => (
    <div className={'activeDiv'+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
            <ActiveLable label={props.label !== '' ? props.label + ' ' : ''}/>
            <input type="text" className="form-control property-textbox activeLabal" 
                   size={props.length || 24} maxLength={props.length || 24} value={props.value} onChange={(e) => { props.onChange(e.target.value, props.slug, props.id) }}
                   onKeyUp={(e) => { if (e.key === 'Enter') { props.save() } }}
                   ref={(el) => { props.setRef(props.slug, el) }}/>
            {props.required == true ? <RequiredText/> : ''}
            {props.fee && !props.hideFees && props.required == true ? <FeeIncludedOptionalDash/>: ''}
            {props.fee && !props.hideFees && <FeeIncluded/>}
    </div>
);

const Text = (props) => (
    <div className={'activeDiv'+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
        <ActiveLableAdditional label={props.label !== '' ? props.label + ' ' : ''}/>
        <div class="vDivider"></div>
        <textarea rows="3" className="form-control property-textbox activeLabal"
                  value={props.value}
                  maxLength={props.length > 1 ? props.length : 255}
                  onChange={(e) => { props.onChange(e.target.value, props.slug, props.id) }}
                  ref={(el) => { props.setRef(props.slug, el) }}/>
                  {props.required == true ? <RequiredText/> : ''}
                  {props.fee && !props.hideFees && props.required == true ? <FeeIncludedOptionalDash/>: ''}
                  {props.fee && !props.hideFees && <FeeIncluded/>}
    </div>
);

const DropDown = (props) => (
    <div className={'activeDiv dropdownArrow control-spacing'+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
             <FormControl variant="outlined" className='state-input-container' style={{ marginTop: 14, width: '100%'}}>
                            <InputLabel style={{ pointerEvents: "auto", fontSize: 22,background: "white",paddingRight: 5,position: "absolute",top: "-3px",fontFamily: 'Source Sans Pro'}} shrink>{props.label !== '' && props.label.length > 23 ?  <IconWithTooltip label={ props.label } /> : props.label} </InputLabel>
                            <Select ref={(el) => { props.setRef(props.slug, el) }}
                                value={props.value || (props.required ? '' : (props.options.length > 0 ? props.options[0].id : ''))} 
                                onChange={(e) => { props.onChange(e.target.value, props.slug, props.id) }}
                                style={{fontSize: "16px",marginBottom: "2px"}}
                                className={ props.validated === false ? props.classStyle.focusedSelect : ''}
                                displayEmpty
                                MenuProps={{
                                    autoFocus: false,
                                    disableAutoFocusItem: true,
                                    disableEnforceFocus: true,
                                    disableAutoFocus: true,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                disableAutoFocus
                                disableAutoFocusItem
                                inputProps={{
                                    classes: {
                                      icon: props.classStyle.icon,
                                    },
                                  }}
                                >
                                {props.required == true ? (<MenuItem style={{fontSize: "16px"}} value="">
                                    Select
                                </MenuItem>) : null }
                                {props.options.map((option, key) => {
                                    return (
                                        <MenuItem style={{fontSize: "16px"}} key={key} value={option.id}>{option.value}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                            {props.required == true ? <RequiredText style={{paddingTop: 2}}/> : ''}
                            {props.fee && !props.hideFees && props.required == true ? <FeeIncludedOptionalDash style={{paddingTop: 2}}/>: ''}
                            {props.fee && !props.hideFees && <FeeIncluded style={{paddingTop: 2}}/>}
        
    
    </div>
    
);

const CheckBox = (props) => (
    <div className={'activeDiv  mt-30'+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
        <div className="checkbox"> 
            <label>
                <input type="checkbox" className="checkboxWH" checked={props.value} onChange={(e) => { props.onChange(e.target.checked, props.slug, props.id) }}
                       ref={(el) => { props.setRef(props.slug, el) }}/>
                <span className="p-checkbox-text">{props.label !== '' ? ' ' + props.label : ''}</span>
            </label>
            <br />
            {props.required == true ? <RequiredCheckBox/> : ''}
            {props.fee && !props.hideFees && props.required == true ? <FeeIncludedOptionalDash/>: ''}
            {props.fee && !props.hideFees && <FeeIncluded/>}
        </div>
    </div>
);

const TriggeredDropDown = (props) => (
    <div className={'activeDiv mt-30' +(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '') }
        >
        <div className="checkbox">
            <label>
                <input type="checkbox" className="checkboxWH" checked={props.checked} onChange={(e) => { props.onCheck(e.target.checked, props.slug, props.id) }}
                       ref={(el) => { props.setRef(props.slug, el) }}/>
                <span className="p-checkbox-text">{props.label !== '' ? ' ' + props.label : ''}</span>
            </label>
            <br />
            {props.required == true ? <RequiredCheckBox/> : ''}
        </div>
        {props.checked && <div className="activeDiv triggeredDropDown control-spacing" >
                        <FormControl variant="outlined" className='state-input-container' style={{ marginTop: 14, width: '100%'}}>
                            <InputLabel style={{ pointerEvents: "auto", fontSize: 22,background: "white",paddingRight: 5,position: "absolute",top: "-3px",fontFamily: 'Source Sans Pro',}} shrink>{props.label !== '' && props.label.length > 23 ?  <IconWithTooltip label={ props.label } />  : props.label}</InputLabel>
                            <Select ref={(el) => { props.setRef(props.slug, el) }}
                                value={props.value || (props.required ? '' : (props.options.length > 0 ? props.options[0] : ''))} 
                                onChange={(e) => { props.onChange(e.target.value, props.checked, props.slug, props.id) }}
                                style={{fontSize: "16px",marginBottom: "2px"}}
                                className={ props.validated === false ? props.classStyle.focusedSelect : ''}
                                displayEmpty
                                MenuProps={{
                                    autoFocus: false,
                                    disableAutoFocusItem: true,
                                    disableEnforceFocus: true,
                                    disableAutoFocus: true,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                disableAutoFocus
                                disableAutoFocusItem
                                inputProps={{
                                    classes: {
                                      icon: props.classStyle.icon,
                                    },
                                  }}
                                >
                                {props.required == true ? (<MenuItem style={{fontSize: "16px"}} value="">
                                    Select
                                </MenuItem>) : null }
                                {props.options.map((option, key) => {
                                    return (
                                        <MenuItem style={{fontSize: "16px"}} key={key} value={option}>{option}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                            {props.required == true ? <RequiredText style={{paddingTop: 2}}/> : ''}
                            {props.fee && !props.hideFees && props.required == true ? <FeeIncludedOptionalDash style={{paddingTop: 2}}/>: ''}
                            {props.fee && !props.hideFees && <FeeIncluded style={{paddingTop: 2}}/>}
        </div>}
    </div>
);

const TriggeredTextBox = (props) => (
    <div>
     <div className={'activeDiv mt-30 '+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
            <div className="checkbox">
                <label>
                    <input type="checkbox" className="checkboxWH" checked={props.checked} onChange={(e) => { props.onCheck(e.target.checked, props.slug, props.id) }}
                           ref={(el) => { props.setRef(props.slug, el) }}/>                
                    <span className="p-checkbox-text">{props.textBoxLabel !== '' ? ' ' + props.textBoxLabel : ''}</span>
                </label>
                <br />
                {props.required == true ? <RequiredCheckBox/> : ''}

            </div>
        </div>
        <div className={'activeDiv'+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
            {props.checked && <div className="activeDiv" style={{marginTop:'-21px'}}>
                <ActiveLable label={props.description !== '' ? props.description + ' ' : ''}/>
                <input type="text" className="form-control property-textbox activeLabal"
                       size={props.length || 24} maxLength={props.length || 24} value={props.value} 
                       onChange={(e) => { props.onChange(e.target.value, props.checked, props.slug, props.id) }}
                       onKeyUp={(e) => { if (e.key === 'Enter') { props.save() } }}
                       ref={(el) => { props.setRef(props.slug, el) }}/>                   
            </div>}
        </div>
    </div>
);

const ReferReason = (props) => (

    <div className={'activeDiv dropdownArrow control-spacing'+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
            <FormControl variant="outlined" className='state-input-container' style={{ marginTop: 14, width: '100%'}}>
                            <InputLabel style={{ pointerEvents:"auto" ,fontSize: 22,background: "white",paddingRight: 5,position: "absolute",top: "-3px",fontFamily: 'Source Sans Pro',}} shrink>{props.label !== '' && props.label.length > 23 ?  <IconWithTooltip label={ props.label } />  : props.label}</InputLabel>
                            <Select ref={(el) => { props.setRef(props.slug, el) }}
                              value={props.value || (props.required ? '' : (props.options.length > 0 ? props.options[0].id : ''))} // Set the default value to the ID of the first item in options
                                onChange={(e) => { props.onChange(e.target.value, props.slug, props.id) }}
                                style={{fontSize: "16px",marginBottom: "2px"}}
                                className={ props.validated === false ? props.classStyle.focusedSelect : ''}
                                displayEmpty
                                MenuProps={{
                                    autoFocus: false,
                                    disableAutoFocusItem: true,
                                    disableEnforceFocus: true,
                                    disableAutoFocus: true,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                disableAutoFocus
                                disableAutoFocusItem
                                inputProps={{
                                    classes: {
                                      icon: props.classStyle.icon,
                                    },
                                  }}
                                >
                                {props.required == true ? (<MenuItem style={{fontSize: "16px"}} value="">
                                    Select
                                </MenuItem>) : null }
                                {props.options.map((option, key) => {
                                    return (
                                        <MenuItem style={{fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px", /* 150% */
                                        letterSpacing: "0.15px",}} key={key} value={option.id}>{option.value}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                            {props.required == true ? <RequiredText style={{paddingTop: 2}}/> : ''}


        {props.value === 'other' && <textarea className={'form-control'} autoFocus value={props.otherValue}
                                              onChange={(e) => props.onOtherValueChange(e.target.value)} />}
    </div>
);

const DatePick = (props) => (
    <div className={'activeDiv'+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
        <ActiveLable label={props.label !== '' ? props.label + ' ' : ''}/>
        <DatePicker inputProps={{placeholder:props.label !== '' ? props.label + ' ' : '', 
                                 className: 'activeLabal form-control property-textbox'}}  
                    timeFormat={false} closeOnSelect={true} 
                    dateFormat={props.format} value={props.value}
                    onChange={m => props.onChange(m ? m.format(props.format) : '', props.slug, props.id)}/>
        {props.required == true ? <RequiredText/> : ''}
    </div>
);


const ClosingDatePick = (props) => (
    <div className={'activeDiv'+(props.validated === false ? ' has-error' : '') + (props.required == false ? ' form-group' : '')}>
        <ActiveLable label={props.label !== '' ? props.label + ' ' : ''}/>
        <DatePicker  inputProps={{placeholder:props.label !== '' ? props.label + ' ' : '', 
                                  className: 'activeLabal form-control property-textbox'}}
                     timeFormat={false} closeOnSelect={true} dateFormat={props.format} value={props.value}
                     onChange={m => props.onChange(m ? m.format(props.format) : '', props.slug, props.id)}/>
        {props.required == true ? <RequiredText/> : ''}
    </div>
);

export function getDropDownValue(control) {
    const valueId = parseInt(control.value);
    const value = control.options.find((item) => {
        return item.id === valueId;
    });
    return value ? value.value : '';
}

export function getFoundationTypeLabel(order) {
    const foundationType = order.controls.find((control) => {
        return control.type === 'FoundationType';
    });
    const id = typeof order.foundation_type === 'object' ? order.foundation_type.id : 0;
    if (foundationType) {
        for (let i = 0, l = foundationType.options.length; i < l; i++) {
            if (foundationType.options[i].id === id) {
                return foundationType.options[i].value;
            }
        }
    }
    return '';
}

class PropertyInfoFormView extends Component {
    constructor(props) {
        super(props);

        this.buildControl = this.buildControl.bind(this);
    }

    buildControl(control, key) {
        let el;
        switch (control.type) {
            case 'SquareFeet':
                el = <SquareFeet key={key}
                                   validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                                   required={this.props.validation.hasOwnProperty(control.slug)}
                                   label={control.label}
                                   length={control.length}
                                   value={this.props.sqft}
                                   slug={control.slug}
                                   id={control.id}
                                   onChange={this.props.updateSqft}
                                   save={this.props.save}
                                   setRef={this.props.setRef}/>;
                break;
            case 'YearBuilt':
                el = <YearBuilt key={key}
                                  validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                                  required={this.props.validation.hasOwnProperty(control.slug)}
                                  label={control.label}
                                  length={control.length}
                                  value={this.props.yb||''}
                                  slug={control.slug}
                                  id={control.id}
                                  onChange={this.props.updateYb}
                                  save={this.props.save}
                                  setRef={this.props.setRef}/>;
                break;
            case 'SalesPrice':
                el = <SalesPrice key={key}
                                   validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                                   required={this.props.validation.hasOwnProperty(control.slug)}
                                   label={control.label}
                                   length={control.length}
                                   value={this.props.sales_price}
                                   slug={control.slug}
                                   id={control.id}
                                   onChange={this.props.updateSalesPrice}
                                   save={this.props.save}
                                   setRef={this.props.setRef}/>;
                break;
            case 'FoundationType':
                el = <FoundationType key={key}
                                       validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                                       required={this.props.validation.hasOwnProperty(control.slug)}
                                       label={control.label}
                                       length={control.length}
                                       value={this.props.foundation_type}
                                       options={control.options}
                                       fee={control.fee}
                                       hideFees={this.props.hideFees}
                                       slug={control.slug}
                                       id={control.id}
                                       onChange={this.props.updateFoundationType}
                                       save={this.props.save}
                                       setRef={this.props.setRef}
                                       classStyle={this.props.classStyle}/>;
                break;
            case 'TextBox':
                el = <TextBox key={key}
                                validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                                required={this.props.validation.hasOwnProperty(control.slug)}
                                label={control.label}
                                length={control.length}
                                value={control.value}
                                slug={control.slug}
                                id={control.id}
                                fee={control.fee}
                                hideFees={this.props.hideFees}
                                onChange={this.props.updateTextbox}
                                save={this.props.save}
                                setRef={this.props.setRef}/>;
                break;
            case 'Text':
                el = <Text key={key}
                             validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                             required={this.props.validation.hasOwnProperty(control.slug)}
                             label={control.label}
                             length={control.length}
                             value={control.value}
                             slug={control.slug}
                             id={control.id}
                             fee={control.fee}
                             hideFees={this.props.hideFees}
                             onChange={this.props.updateText}
                             save={this.props.save}
                             setRef={this.props.setRef}/>;
                break;
            case 'CheckBox':
                el = <CheckBox key={key}
                                 validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                                 required={this.props.validation.hasOwnProperty(control.slug)}
                                 label={control.label}
                                 value={control.value}
                                 slug={control.slug}
                                 id={control.id}
                                 fee={control.fee}
                                 hideFees={this.props.hideFees}
                                 onChange={this.props.updateCheckbox}
                                 save={this.props.save}
                                 setRef={this.props.setRef}/>;
                break;
            case 'DropDown':
                el = <DropDown key={key}
                                 validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                                 required={this.props.validation.hasOwnProperty(control.slug)}
                                 label={control.label}
                                 length={control.length}
                                 value={control.value}
                                 options={control.options}
                                 slug={control.slug}
                                 id={control.id}
                                 fee={control.fee}
                                 hideFees={this.props.hideFees}
                                 onChange={this.props.updateDropdown}
                                 save={this.props.save}
                                 setRef={this.props.setRef}
                                 classStyle={this.props.classStyle}/>;
                break;
            case 'TriggeredDropDown':
                el = <TriggeredDropDown key={key}
                               validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                               required={this.props.validation.hasOwnProperty(control.slug)}
                               label={control.label}
                               length={control.length}
                               value={control.value}
                               checked={control.checked}
                               options={control.options}
                               slug={control.slug}
                               id={control.id}
                               fee={control.fee}
                               hideFees={this.props.hideFees}
                               onCheck={this.props.updateTriggeredDropdownCheckbox}
                               onChange={this.props.updateTriggeredDropdown}
                               save={this.props.save}
                               setRef={this.props.setRef}
                               classStyle={this.props.classStyle}/>;
                break;
            case 'TriggeredTextBox':
                el = <TriggeredTextBox key={key}
                              validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                              required={this.props.validation.hasOwnProperty(control.slug)}
                              label={control.label}
                              textBoxLabel={control.textBoxLabel}
                              length={control.length}
                              value={control.value}
                              checked={control.checked}
                              slug={control.slug}
                              id={control.id}
                              fee={control.fee}
                              description={control.description}
                              hideFees={this.props.hideFees}
                              onCheck={this.props.updateTriggeredTextboxCheckbox}
                              onChange={this.props.updateTriggeredTextbox}
                              save={this.props.save}
                              setRef={this.props.setRef}/>;
                break;
            case 'ReferReason':
                el = <ReferReason key={key}
                               validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                               required={this.props.validation.hasOwnProperty(control.slug)}
                               label={control.label}
                               length={control.length}
                               value={this.props.refer_reason}
                               otherValue={this.props.refer_reason_other}
                               options={control.options}
                               slug={control.slug}
                               id={control.id}
                               fee={control.fee}
                               hideFees={this.props.hideFees}
                               onChange={this.props.updateReferReason}
                               onOtherValueChange={this.props.updateReferReasonOther}
                               save={this.props.save}
                               setRef={this.props.setRef}
                               classStyle={this.props.classStyle}/>;
                break;
            case 'Date':
                el = <DatePick key={key}
                              validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                              required={this.props.validation.hasOwnProperty(control.slug)}
                              label={control.label}
                              length={control.length}
                              value={control.value}
                              slug={control.slug}
                              id={control.id}
                              fee={control.fee}
                              hideFees={this.props.hideFees}
                              format={this.props.dateFormat}
                              onChange={this.props.updateTextbox}
                              save={this.props.save}
                              setRef={this.props.setRef}/>;
                break;
            case 'ClosingDate':
                el = <ClosingDatePick key={key}
                               validated={this.props.validation.hasOwnProperty(control.slug) ? this.props.validation[control.slug] : true}
                               required={this.props.validation.hasOwnProperty(control.slug)}
                               label={control.label}
                               length={control.length}
                               value={control.value}
                               slug={control.slug}
                               id={control.id}
                               fee={control.fee}
                               hideFees={this.props.hideFees}
                               format={this.props.dateFormat}
                               onChange={this.props.updateTextbox}
                               save={this.props.save}
                               setRef={this.props.setRef}/>;
                break;
        }

        return el;
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                {this.props.controls.map(this.buildControl)}
            </div>
        );
    }
}

export class PropertyInfoContainer extends Component  {

    constructor(props) {
        super(props);

        this.updateSqft = this.updateSqft.bind(this);
        this.updateYb = this.updateYb.bind(this);
        this.updateFoundationType = this.updateFoundationType.bind(this);
        this.updateSalesPrice = this.updateSalesPrice.bind(this);
        this.updateTextbox = this.updateTextbox.bind(this);
        this.updateText = this.updateText.bind(this);
        this.updateCheckbox = this.updateCheckbox.bind(this);
        this.updateDropdown = this.updateDropdown.bind(this);
        this.updateTriggeredDropdown = this.updateTriggeredDropdown.bind(this);
        this.updateTriggeredDropdownCheckbox = this.updateTriggeredDropdownCheckbox.bind(this);
        this.updateTriggeredTextbox = this.updateTriggeredTextbox.bind(this);
        this.updateTriggeredTextboxCheckbox = this.updateTriggeredTextboxCheckbox.bind(this);
        this.updateSpecialNote = this.updateSpecialNote.bind(this);
        this.updateControlValue = this.updateControlValue.bind(this);
        this.updateControlChecked = this.updateControlChecked.bind(this);
        this.updateReferReason = this.updateReferReason.bind(this);
        this.updateReferReasonOther = this.updateReferReasonOther.bind(this);
        this.save = this.save.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.validate = this.validate.bind(this);
        this.setRef = this.setRef.bind(this);
        this.setup = this.setup.bind(this);
        this.hasControlType = this.hasControlType.bind(this);

        const specialNote = this.props.propertyInfoActions.getSpecialNoteLocalStorage();

        // local state
        this.state = {
            validation: {},
            sqft: props.order.sqft,
            yb: props.order.yb,
            foundation_type: props.order.foundation_type,
            sales_price: props.order.sales_price,
            refer_reason: props.order.refer_reason,
            refer_reason_other: props.order.refer_reason_other,
            controls: props.order.controls,
            special_note: specialNote || props.order.special_note,
            saving: false,
            setup: false
        };
    }

    validate(slug, type, value, checked) {
        if (this.state.validation.hasOwnProperty(slug)) {
            let valid = false;
            let validation = this.state.validation;
            switch (type) {
                case 'SquareFeet':
                    valid = value > 0;
                    break;
                case 'YearBuilt':
                    valid = (value > 1200 && value <= (new Date()).getFullYear());
                    break;
                case 'SalesPrice':
                    valid = value !== 0;
                    break;
                case 'CheckBox':
                    valid = value;
                    break;
                case 'Text':
                case 'TextBox':
                case 'Date':
                case 'ClosingDate':
                    valid = value !== '';
                    break;
                case 'ReferReason':
                case 'DropDown':
                case 'TriggeredTextBox':
                case 'TriggeredDropDown':
                case 'FoundationType':
                    valid = value > 0;
                    break;
            }
            validation[slug] = valid;
            this.setState({validation});
        }
    }

    updateSqft(value, slug) {
        value = value.replace(/[^[0-9]/g, '');
        this.setState({sqft:value});
        this.validate(slug, 'SquareFeet', value);
    }

    updateYb(value, slug) {
        this.setState({yb:value});
        this.validate(slug, 'YearBuilt', value);
    }

    updateFoundationType(value, slug) {
        let foundation_type = '';
        value = parseInt(value);
        if (value > 0) {
            const control = this.state.controls.find((item) => {
                return item.type === 'FoundationType';
            });
            foundation_type = { ...control.options.find((item) => {
                    return item.id === value;
                })
            };
        }
        this.setState({foundation_type});
        this.validate(slug, 'FoundationType', value);
    }

    updateSalesPrice(value, slug) {
        this.setState({sales_price:value});
        this.validate(slug, 'SalesPrice', value);
    }

    updateControlChecked(control_id, checked) {
        let controls = this.state.controls;
        for (let i = 0, l = controls.length; i < l; i++) {
            if (controls[i].id === control_id) {
                controls[i].checked = checked;
                break;
            }
        }
        this.setState({controls});
    }

    updateControlValue(control_id, value) {
        let controls = this.state.controls;
        for (let i = 0, l = controls.length; i < l; i++) {
            if (controls[i].id === control_id) {
                controls[i].value = value;
                break;
            }
        }
        this.setState({controls});
    }

    updateTextbox(value, slug, control_id) {
        this.updateControlValue(control_id, value);
        this.validate(slug, 'TextBox', value);
    }

    updateText(value, slug, control_id) {
        this.updateControlValue(control_id, value);
        this.validate(slug, 'Text', value);
    }

    updateCheckbox(value, slug, control_id) {
        this.updateControlValue(control_id, value);
        this.validate(slug, 'CheckBox', value);
    }

    updateDropdown(value, slug, control_id) {
        this.updateControlValue(control_id, value);
        this.validate(slug, 'DropDown', value);
    }

    updateTriggeredDropdown(value, checked, slug, control_id) {
        this.updateControlValue(control_id, value);
        // console.log('checked', checked, value, slug, control_id);
        if (checked) {
            this.validate(slug, 'TriggeredDropDown', value);
        }
    }

    updateTriggeredDropdownCheckbox(value, slug, control_id) {
        this.updateControlChecked(control_id, value);
    }

    updateTriggeredTextbox(value, checked, slug, control_id) {
        this.updateControlValue(control_id, value);
        if (checked) {
            this.validate(slug, 'TriggeredTextBox', value);
        }
    }

    updateTriggeredTextboxCheckbox(value, slug, control_id) {
        this.updateControlChecked(control_id, value);
    }

    updateReferReason(value, slug) {
        this.setState({refer_reason:value});
        this.validate(slug, 'ReferReason', value);
    }

    updateReferReasonOther(value, slug) {
        this.setState({refer_reason_other:value});
    }

    updateSpecialNote(e) {
        const value = e.target.value;
        this.setState({
            special_note: value
        });
        this.props.propertyInfoActions.saveSpecialNoteLocalStorage(value);
    }

    prev() {
        this.props.router.history.push('/'+this.props.company_key+'/online-scheduler/contacts?t='+this.props.token + '&office=' + this.props.office_uuid);
    }

    next() {
        this.props.router.history.push('/'+this.props.company_key+'/online-scheduler/packages?t='+this.props.token + '&office=' + this.props.office_uuid);
    }

    save() {
        this.props.setNavLoading(true);

        const getControl = (id) => {
            return this.state.controls.find((control) => {
                if (typeof control === 'object') {
                    return control.id === id;
                }
                return false;
            });
        };

        // run validation on all required controls
        const required = this.state.controls.filter((control) => {
            return control.required;
        });

        for (let i = 0, l = required.length, control = null, value = ''; i < l; i++) {
            control = getControl(required[i].id);
            if (required[i].type === 'TriggeredDropDown' || required[i].type === 'TriggeredTextBox') {
                this.validate(required[i].slug, required[i].type, control.value, control.checked);
            } else {
                if (required[i].type === 'SquareFeet') {
                    value = this.state.sqft;
                } else if (required[i].type === 'YearBuilt') {
                    value = this.state.yb;
                } else if (required[i].type === 'SalesPrice') {
                    value = this.state.sales_price;
                } else if (required[i].type === 'FoundationType') {
                    value = typeof this.state.foundation_type === 'object' ? this.state.foundation_type.id : '';
                } else if (required[i].type === 'ReferReason') {
                    value = this.state.refer_reason;
                } else {
                    value = control.value;
                }
                this.validate(required[i].slug, required[i].type, value);
            }
        }

        const {
            setSqft,
            setYb,
            setFoundationType,
            setSalesPrice,
            setTextControl,
            setTextboxControl,
            setCheckboxControl,
            setDropdownControl,
            setTriggeredDropdownControl,
            setTriggeredTextboxControl,
            setDateControl,
            setClosingDateControl,
            setReferReason,
            setReferReasonOther
        } = this.props.orderActions;

        const { save } = this.props.propertyInfoActions;
        const keys = Object.keys(this.state.validation);
        const validated = keys.filter((key) => {
            return this.state.validation[key] === true;
        });

        if (validated.length === keys.length) {

            if (this.hasControlType('SquareFeet')) {
                setSqft(this.state.sqft);
            }

            if (this.hasControlType('YearBuilt')) {
                setYb(this.state.yb);
            }

            if (this.hasControlType('FoundationType')) {
                let foundation_type = this.state.foundation_type;
                if (!foundation_type) {
                    // select first
                    const foundation_types = this.state.controls.find((control) => {
                        return control.type === 'FoundationType';
                    });
                    if (foundation_types && foundation_types.options.length > 0) {
                        foundation_type = foundation_types.options[0];
                    }
                }
                setFoundationType(foundation_type);
            }

            if (this.hasControlType('SalesPrice')) {
                setSalesPrice(this.state.sales_price);
            }

            if (this.hasControlType('ReferReason')) {
                setReferReason(this.state.refer_reason);
                setReferReasonOther(this.state.refer_reason_other);
            }

            for (let i = 0, l = this.state.controls.length, callable = null; i < l; i++) {
                switch (this.state.controls[i].type) {
                    case 'Text':
                        callable = setTextControl;
                        break;
                    case 'TextBox':
                        callable = setTextboxControl;
                        break;
                    case 'DropDown':
                        callable = setDropdownControl;
                        break;
                    case 'CheckBox':
                        callable = setCheckboxControl;
                        break;
                    case 'TriggeredDropDown':
                        callable = setTriggeredDropdownControl;
                        break;
                    case 'TriggeredTextBox':
                        callable = setTriggeredTextboxControl;
                        break;
                    case 'Date':
                        callable = setDateControl;
                        break;
                    case 'ClosingDate':
                        callable = setClosingDateControl;
                        break;
                }
                if (callable) {
                    callable.apply(undefined, [this.state.controls[i].value, this.state.controls[i].id, this.state.controls[i].checked]);
                }
            }

            this.setState({saving:true});

            save()
                .then(() => {
                    this.setState({saving:false});
                    this.next();
                });

        } else {
            this.props.setNavLoading(false);

            // focus failed ref
            for (let p in this.state.validation) {
                if (this.state.validation.hasOwnProperty(p) && validated.indexOf(p) === -1 && typeof this['ref_'+p] !== 'undefined' && this['ref_'+p]) {
                    if (['text','textarea'].indexOf(this['ref_'+p].type) === -1) {
                        document.body.scrollTop = document.documentElement.scrollTop = this['ref_'+p].offsetTop;
                    } else {
                        this['ref_' + p].focus();
                    }
                    break;
                }
            }
        }
    }

    setRef(name, el) {
        this['ref_'+name] = el;
    }

    hasControlType(type) {
        for (let i = 0, l = this.props.order.controls.length; i < l; i++) {
            if (this.props.order.controls[i].type === type) {
                return true;
            }
        }
        return false;
    }

    setup() {
        if (!this.state.setup) {
            const required = this.state.controls.filter((control) => {
                return control.required;
            });

            let validation = {};

            for (let i = 0, l = required.length; i < l; i++) {
                validation[required[i].slug] = null;
            }

            this.setState({validation,setup:true});
        }

        Utils.scrollTop(500);
        this.props.setNavPropertyInfo(true);
        this.props.setNavLoading(false);
    }

    componentDidMount() {
        // run if we got here from an existing state
        if (canSetupOnPageLoad(this.props)) {
            this.setup();
        }
    }

    componentWillReceiveProps(nextProps) {
        // run if we got here from a new state (i.e. new page load)
        if (canSetupOnExistingState(this.props, nextProps)) {
            this.setState({
                sqft: nextProps.order.sqft,
                yb: nextProps.order.yb,
                foundation_type: nextProps.order.foundation_type,
                sales_price: nextProps.order.sales_price,
                refer_reason: nextProps.order.refer_reason,
                refer_reason_other: nextProps.order.refer_reason_other,
                controls: nextProps.order.controls,
                special_note: this.state.special_note || nextProps.order.special_note
            }, this.setup);
        }
    }

    render() {

        const { classes } = this.props;

        let content = null;

        if (this.state.setup) {
            const countRequired = this.state.controls.filter((control) => {
                return this.state.validation.hasOwnProperty(control.slug);
            }).length;
            const countFees = this.state.controls.filter((control) => {
                return control.fee;
            }).length;

            content = <div>
                {this.props.order.services.length > 0 && !this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_ALL_FEES') && <div className="alert alert-info bannerNotification">
                    Changing property information may affect the pricing of items in your shopping cart.
                </div>}

                 <link rel="preconnect" href="https://fonts.googleapis.com"></link>
                 <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
                 <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap" rel="stylesheet"></link>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                <PropertyInfoFormView sqft={this.state.sqft}
                                      yb={this.state.yb}
                                      foundation_type={this.state.foundation_type}
                                      sales_price={this.state.sales_price}
                                      refer_reason={this.state.refer_reason}
                                      refer_reason_other={this.state.refer_reason_other}
                                      controls={this.state.controls}
                                      updateSqft={this.updateSqft}
                                      updateYb={this.updateYb}
                                      updateFoundationType={this.updateFoundationType}
                                      updateSalesPrice={this.updateSalesPrice}
                                      updateTextbox={this.updateTextbox}
                                      updateText={this.updateText}
                                      updateCheckbox={this.updateCheckbox}
                                      updateDropdown={this.updateDropdown}
                                      updateReferReason={this.updateReferReason}
                                      updateReferReasonOther={this.updateReferReasonOther}
                                      updateTriggeredDropdownCheckbox={this.updateTriggeredDropdownCheckbox}
                                      updateTriggeredDropdown={this.updateTriggeredDropdown}
                                      updateTriggeredTextboxCheckbox={this.updateTriggeredTextboxCheckbox}
                                      updateTriggeredTextbox={this.updateTriggeredTextbox}
                                      setRef={this.setRef}
                                      validation={this.state.validation}
                                      save={this.save}
                                      hideFees={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_ALL_FEES')}
                                      dateFormat={this.props.settings.getSettingValue('DATE_FORMAT')}
                                      classStyle={classes}
                />
                {this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_PROPERTY_INFO_NOTE') && 
                <div className={'activeDiv form-group'} style={{marginTop:'-15px'}}>
                    <label className="labelTextAdditional">Special instructions and notes</label>
                    <div class="vDivider"></div>
                    <textarea className={'form-control property-textbox activeLabal'} value={this.state.special_note}
                              onChange={this.updateSpecialNote} rows="4"/> 
                </div>}

                <div className="action-buttons-space-property">
                    <BackButton back={this.prev} className="back-btn-class" />
                    <NextButton className={this.state?.saving ? 'next-btn-class hide-button' : 'next-btn-class'} next={this.save}  disabled={this.state.saving}/>
                    <button className={this.state?.saving ? 'next-btn-class show-button' : 'hide-button'} >&nbsp;<i className="fa fa-spinner fa-spin"></i>&nbsp;</button>
                </div>

            </div>;
        }

        return (
            <div>
                {!this.props.app.office_loading && !this.props.app.order_loading && <FixedSideBar {...this.props}/>}
                <Container bodyClasses={'property-info'} title="Please provide information about the property" {...this.props}>
                    {content}
                </Container>
            </div>);
    }
}

/**
 *
 */
export default withStyles(styles)(
    withRouter(
      connect(
        (state, ownProps) => ({
          router: ownProps,
          ...state.Office,
          order: state.Order,
          nav: state.Nav,
          app: state.App,
        }),
        (dispatch) => ({
          propertyInfoActions: bindActionCreators(PropertyInfoActions, dispatch),
          orderActions: bindActionCreators(OrderActions, dispatch),
          officeActions: bindActionCreators(OfficeActions, dispatch),
          packagesActions: bindActionCreators(PackagesActions, dispatch),
          setNavPropertyInfo: (value) => dispatch(setNavPropertyInfo(value)),
          setNavLoading: (value) => dispatch(setNavLoading(value)),
        })
      )(PropertyInfoContainer)
    )
  );
  
