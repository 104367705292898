import moment from 'moment'

export function getMinDate(dates) {
    let _dates = Object.keys(dates).map((item) => {
        return moment(String(item));
    });
    let now = moment();
    let minDate = null;
    for (let i = 0, l = _dates.length; i < l; i++) {
        minDate = _dates[i] < minDate ? _dates[i] : minDate;
    }
    if (minDate < now) {
        minDate = now;
    }
    return minDate;
}

export function getMaxDate(dates) {
    let _dates = Object.keys(dates).map((item) => {
        return moment(String(item));
    });
    let maxDate = null;
    for (let i = 0, l = _dates.length; i < l; i++) {
        maxDate = _dates[i] > maxDate ? _dates[i] : maxDate;
    }
    return maxDate;
}

export function intersect(a, b) {
    let t;
    if (b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
}

export function copyObject(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function canSetupOnPageLoad(props) {
    return !props.app.office_loading && !props.app.order_loading && props.settings.loaded;
}

export function canSetupOnExistingState(prevProps, props) {
    return props.settings.loaded &&
        !props.app.office_loading && !props.app.order_loading
        && (prevProps.app.office_loading || prevProps.app.order_loading);
}

export function getAnalyticsEventLabel(companykey, defaultLabel) {
   return (companykey && companykey.substr(0, 10) === 'amerispec-') ? companykey : defaultLabel;
};
