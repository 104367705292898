import React, {Component} from 'react'
import Currency from '../../Common/Currency.jsx'
import {Swipeable} from 'react-touch'
import CompanyContactFooter from './CompanyContactFooter.jsx'
import {getDropDownValue, getFoundationTypeLabel} from './PropertyInfo.jsx'
import {serviceInPackage} from './Packages.jsx'
import Coupon from './Coupon.jsx';
import * as PackagesActions from '../Actions/Packages.jsx'
import * as OrderActions from '../Actions/Order.jsx'
import * as OfficeActions from '../Actions/Office.jsx'
import * as CalendarActions from '../Actions/Calendar.jsx'
import {setNavPackages, setNavLoading} from '../Actions/Nav.jsx'
import {withRouter} from 'react-router'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {getPackageServices} from "./Packages";
import Chip from '@material-ui/core/Chip';
import "./OnlineScheduler.css"

/**
 * FixedSidebar class.
 */
class FixedSideBar extends Component {
    /**
     * FixedSidebar constructor.
     *
     * @param props
     */
    constructor(props) {
        super(props);

        const calculateContentHeight = () => {
            return Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 100;
        };

        this.state = {
            open: Math.max(document.documentElement.clientWidth, window.innerWidth || 0) >= 800,
            max_content_height: calculateContentHeight()
        };

        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.openSidebar = this.openSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.resize = this.resize.bind(this);
        this.calculateContentHeight = calculateContentHeight.bind(this);
    }

    /**
     * Returns the total number of ancillary services.
     *
     * @returns {number}
     */
    orderAncillaryServiceCount() {
        const {props} = this;
        const {pricingServices} = props;

        return (pricingServices || []).filter(
            service => service.hasOwnProperty('ancillary') && service['ancillary'] === true
        ).length;
    }


    /**
     * Indicates whether or not the inspection has an ancillary service.
     *
     * @returns {boolean}
     */
    orderHasAncillaryService() {
        return this.orderAncillaryServiceCount() > 0;
    }


    /**
     * Returns the total number of services assigned to the order.
     *
     * @param includePackageServices
     * @returns {number}
     */
    orderPrimaryServiceCount(includePackageServices = true) {
        const {props} = this;
        const {pricingServices, currentPackages, packages} = props;

        const primaryServicesLength = (pricingServices || []).filter(
            service => service.hasOwnProperty('ancillary') && service['ancillary'] === false && !service['is_pac']
        ).length;

        if (!includePackageServices) {
            return primaryServicesLength
        }

        const packageServices = getPackageServices(currentPackages, packages)

        const primaryServicesFromPackagesLength = (packageServices || []).filter(service => {
            return service.hasOwnProperty('ancillary') && service['ancillary'] === false
        }).length;

        return primaryServicesLength + primaryServicesFromPackagesLength
    }


    /**
     * Removes as package from the inspection.
     */
    removePackage(pkg) {
        const {props} = this;
        const {settings, removePackage} = props;

        // Determine whether or not we need to check ancillaries first.
        const settingRequirePrimaryService = settings.getSettingBool('ONLINE_SCHEDULER_V3_REQUIRE_PRIMARY_SERVICE_BEFORE_ANCILLARY');
        const singlePrimaryServices = this.orderPrimaryServiceCount() < 2;

        // Verify that we can remove this package.
        if (settingRequirePrimaryService && singlePrimaryServices && this.orderHasAncillaryService()) {
            alert('At least one primary service is required. Please remove any ancillary services before removing this package.');
            return;
        }

        removePackage(pkg);
    }


    /**
     * Removes a service from the inspection.
     */
    removeService(service) {
        const {props} = this;
        const {settings, removeService} = props;

        // Determine whether or not we need to check ancillaries first.
        const settingRequirePrimaryService = settings.getSettingBool('ONLINE_SCHEDULER_V3_REQUIRE_PRIMARY_SERVICE_BEFORE_ANCILLARY');
        const singlePrimaryServices = this.orderPrimaryServiceCount() < 2;

        // Verify that we can remove this service.
        if (settingRequirePrimaryService && singlePrimaryServices && this.orderHasAncillaryService() && !service.ancillary) {
            alert('At least one primary service is required. Please remove any ancillary services before removing this service.');
            return;
        }

        removeService(service);
    }


    /**
     * Reveals / hides the sidebar.
     */
    toggleSidebar() {
        this.setState({
            open: !this.state.open
        });
    }


    /**
     * Reveals the sidebar.
     */
    openSidebar() {
        this.setState({
            open: true
        });
    }


    /**
     * Hides the sidebar.
     */
    closeSidebar() {
        this.setState({
            open: false
        });
    }


    /**
     * Calculates the appropriate height / width of the sidebar.
     */
    resize() {
        this.setState({
            open: Math.max(document.documentElement.clientWidth, window.innerWidth || 0) >= 800,
            max_content_height: this.calculateContentHeight()
        });
    }


    /**
     * Determines the total price of a package without discounts applied.
     */
    getPackageSubTotalBeforeDiscounts(pkg) {
        let subtotal = pkg.base || 0.00;
        if (pkg && pkg.hasOwnProperty('services')) {
            for (let i = 0, l = pkg.services.length; i < l; i++) {
                for (let x = 0, y = pkg.services[i].fees.length; x < y; x++) {
                    if (pkg.services[i].fees[x].gross) {
                        subtotal += pkg.services[i].fees[x].gross;
                    }
                }
            }
        }
        return subtotal;
    }


    /**
     * Returns the total value of a service before applying any discounts.
     */
    getServiceSubTotalBeforeDiscounts(service) {
        let subtotal = service.base || 0.00;
        if (service && service.hasOwnProperty('fees') && service.fees.length) {
            subtotal += service.fees.map(f => f.value).reduce((a, b) => a + b)
        }

        return subtotal;
    }


    /**
     * The post-mount event for this component.
     */
    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }


    /**
     * The pre-delete event for this component.
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }


    /**
     * The render action for the sidebar.
     */
    render() {
        const pathParts = this.props.router.location.pathname.split('/').filter((part) => {
            return part !== '';
        });
        const action = typeof pathParts[2] !== 'undefined' ? pathParts[2] : '';
        const {order} = this.props;
        const order_state = this.props.states.find((state) => {
            return state.id === order.state;
        });

        const hasSqft = order.controls.find((control) => {
            return control.type === 'SquareFeet';
        });

        const hasYb = order.controls.find((control) => {
            return control.type === 'YearBuilt';
        });

        const hasSalesPrice = order.controls.find((control) => {
            return control.type === 'SalesPrice';
        });

        const hasFoundationType = order.controls.find((control) => {
            return control.type === 'FoundationType';
        });

        const foundationTypeLabel = getFoundationTypeLabel(order);

        let contactCount = order.client !== null ? 1 : 0;
        contactCount += order.buyers_agent !== null ? 1 : 0;
        contactCount += order.sellers_agent !== null ? 1 : 0;
        contactCount += order.insurance_agent !== null ? 1 : 0;
        contactCount += order.contacts.length;

        const bCalendarLast = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST');
        const bHideFees = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_ALL_FEES');
        const bHideTaxes = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_DO_NOT_SHOW_TAX_IN_PACKAGES');
        const separatorStyle = {borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '2px'};

        const DateTimePanel = (props) => (<li {...props}>
            <div style={{borderLeft: action==='calendar'?"#0F75BC solid 4px":"#b9b3b3 solid 4px", marginLeft: "-10px", paddingTop: "20px"}}>
            {this.props.nav.calendar && !((!bCalendarLast && action === '') || action === 'calendar') && order.datetime &&
            <div className="pull-right">
                <button type="button" className="btn btn-default btn-sm sidebar-change-btn edit-button"
                        onClick={() => {
                            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler' + (bCalendarLast ? '/calendar' : '') + '?t=' + this.props.token);
                        }}>
                    Edit
                </button>
            </div>}
            <div className="text-style">
            <span style={{color: action==='calendar'? "#0F75BC" : "#757575"}} class="material-symbols-outlined icon-font-style">calendar_month</span>&nbsp;<span style={{color: action==='calendar'? "#0F75BC" : "#757575"}} className="text-font-style">Date & Time</span>
            </div>
            {/* <div style={separatorStyle}> */}
            <div className="text-value-style">
                {order.datetime && <><div><b>Date: </b>{order.datetime.format('M/D/YYYY')}</div>
                <div><b>Time: </b>{order.datetime.format('h:mm A')}</div>
                </>}
            </div>
            </div>
        </li>);

        const AddressPanel = (props) => (<li {...props}>
            <div style={{borderLeft: action==='address'?"#0F75BC solid 4px":"#b9b3b3 solid 4px", marginLeft: "-10px", paddingTop: "20px"}}>
            {this.props.nav.address && !((bCalendarLast && action === '') || action === 'address') &&
           <div className="pull-right">
                <button type="button" className="btn btn-default btn-sm sidebar-change-btn edit-button"
                        onClick={() => {
                            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/address?t=' + this.props.token);
                        }}>
                    Edit
                </button>
            </div>}
            <div className="text-style">
            <span style={{color: action==='address'? "#0F75BC" : "#757575"}} class="material-symbols-outlined icon-font-style">location_on</span>&nbsp;<span style={{color: action==='address'? "#0F75BC" : "#757575"}} className="text-font-style">Address</span>
            </div>
            <div className="text-value-style">
                {order.address1 !== '' && <span>
                    {order.address1}
                    {order.address2 !== '' ? ', ' + order.address2 : ''}
                    {order.city !== '' ? ', ' + order.city : ''}
                    {order_state ? ', ' + order_state.abbreviation : ''}
                    {order.postal_code !== '' ? ' ' + order.postal_code : ''}</span>}
            </div>
            </div>
        </li>);

        const ContactsPanel = (props) => (<li {...props}>
            <div style={{borderLeft: action==='contacts'?"#0F75BC solid 4px":"#b9b3b3 solid 4px", marginLeft: "-10px", paddingTop: "20px"}}>
            {this.props.nav.contacts && action !== 'contacts' && <div className="pull-right">
                <button type="button" className="btn btn-default btn-sm sidebar-change-btn edit-button"
                        onClick={() => {
                            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/contacts?t=' + this.props.token);
                        }}>
                Edit
                </button>
            </div>}
            <div className="text-style">
            <span style={{color: action==='contacts'? "#0F75BC" : "#757575"}} class="material-symbols-outlined icon-font-style">contact_page</span>&nbsp;<span style={{color: action==='contacts'? "#0F75BC" : "#757575"}} className="text-font-style">{order.user ? order.user.text : 'Contact Info'}</span>
            </div>
            <div className="text-value-style">
                {contactCount > 0 &&
                <span>{contactCount} contact{contactCount > 1 ? 's' : ''}</span>}
            </div>
            </div>
        </li>);

        const PropertyInfoPanel = (props) => (<li {...props}>
            <div style={{borderLeft: action==='property-info'?"#0F75BC solid 4px":"#b9b3b3 solid 4px", marginLeft: "-10px", paddingTop: "20px"}}>
            {this.props.nav.property_info && action !== 'property-info' && <div className="pull-right">
                <button type="button" className="btn btn-default btn-sm sidebar-change-btn edit-button"
                        onClick={() => {
                            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/property-info?t=' + this.props.token);
                        }}>
                    Edit
                </button>
            </div>}
            <div className="text-style">
            <span style={{color: action==='property-info'? "#0F75BC" : "#757575"}} class="material-symbols-outlined icon-font-style">home_work</span>&nbsp;<span style={{color: action==='property-info'? "#0F75BC" : "#757575"}} className="text-font-style">Property Info</span>
            </div>
            <div className="text-value-style">
                {this.props.nav.property_info && <ul style={{
                    listStyleType: 'none',
                    margin: '0',
                    padding: '0',
                    // width: '100%',
                    // maxHeight: '200px',
                    // overflow: 'auto',
                    // fontSize: '85%'
                }}>
                    {hasSqft && <li><b>Square Feet: </b>{order.sqft}</li>}
                    {hasYb && <li><b>Year Built: </b>{order.yb}</li>}
                    {hasSalesPrice && <li><b>Sale Price: </b><Currency amount={order.sales_price}/></li>}
                    {hasFoundationType && <li><b>Foundation: </b>{foundationTypeLabel}</li>}
                    {order.controls.map((control, controlKey) => {
                        if (['SquareFeet', 'YearBuilt', 'SalesPrice', 'FoundationType'].indexOf(control.type) === -1) {
                            return (
                                <li key={controlKey}>
                                    {control.type === 'CheckBox' && control.value && <span>
                                                                <input type={'checkbox'} checked={true}
                                                                       readOnly={true}/>
                                        &nbsp;{control.label}
                                                            </span>}
                                    {control.type === 'DropDown' && control.value && <span><b>{control.label}:</b> <span
                                        dangerouslySetInnerHTML={{__html: getDropDownValue(control)}}/></span>}
                                    {control.type === 'TriggeredDropDown' && control.value &&
                                    <span><b>{control.label}:</b> <span
                                        dangerouslySetInnerHTML={{__html: control.value}}/></span>}
                                    {['CheckBox', 'DropDown', 'TriggeredDropDown'].indexOf(control.type) === -1 && control.value &&
                                    <span><b>{control.label}:</b> {control.value}</span>}
                                </li>
                            )
                        }
                    })}
                </ul>}
            </div>
            </div>
        </li>);

        const shoppingItemLabelWidth = '82%';
        const pacServiceUuid = app_state.pac_service_uuid;
        const orderHasNonPacServices = order.services.filter((serviceUuid) => {
            return serviceUuid !== pacServiceUuid;
        }).length > 0;

        const ShoppingCartPanel = (props) => (<li {...props}>
            <div style={{borderLeft: action==='packages'?"#0F75BC solid 4px":"#b9b3b3 solid 4px", marginLeft: "-10px", paddingTop: "20px"}}>
            {this.props.nav.packages && action !== 'packages' && <div className="pull-right">
                <button type="button" className="btn btn-default btn-sm sidebar-change-btn edit-button"
                        onClick={() => {
                            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/packages?t=' + this.props.token);
                        }}>
                    Edit
                </button>
            </div>}
            <div className="text-style">
            <span style={{color: action==='packages'? "#0F75BC" : "#757575"}}  class="material-symbols-outlined icon-font-style">fact_check</span>&nbsp;<span style={{color: action==='packages'? "#0F75BC" : "#757575"}} className="text-font-style">Services</span>
            </div>
            <div className="text-pricing-value-style">
                {order.pricing_loading && <div className={'text-center'}>
                    <i className={'fas fa-spinner-third fa-spin fa-2x'}/>
                </div>}
                {!order.pricing_loading && orderHasNonPacServices && <ul className={'os-pricing-list'} style={{
                    listStyleType: 'none',
                    margin: '0',
                    padding: '0',
                    position: 'relative'
                }}>
                    {order.pricing.package && <li className="sidebar-package">
                        <div className="pull-right package-number-style">
                            {!bHideFees &&
                            <Currency amount={this.getPackageSubTotalBeforeDiscounts(order.pricing.package)}/>}
                        </div>
                        <div className="pull-left" style={{width: shoppingItemLabelWidth}}
                             title={order.pricing.package.name}>
                            <span dangerouslySetInnerHTML={{__html: order.pricing.package.name}}/>
                        </div>
                        {/* <div className={'os-remove-row'} onClick={() => this.removePackage(order.pricing.package)}>
                            <i className={'fa fa-times'}/>
                        </div> */}
                        <div style={{clear: 'both'}}/>
                        {order.pricing.package.savings > 0 && !bHideFees && <div>
                            <div className="pull-right" style={{fontStyle: 'italic', fontWeight: 'bold'}}>
                                -<Currency amount={order.pricing.package.savings}/>
                            </div>
                            <div className="pull-left" style={{fontStyle: 'italic', fontWeight: 'bold'}}>
                                Bundle Savings
                            </div>
                            <div style={{clear: 'both'}}/>
                        </div>}
                    </li>}
                    {order.pricing.services.map((service, serviceKey) => {
                        if (service.is_pac) {
                            return null;
                        }

                        if (!serviceInPackage(service.id, order.pricing.package)) {
                            return (
                                <li key={serviceKey} className="sidebar-service">
                                    <div className="pull-right">
                                        {!bHideFees &&
                                        <Currency amount={this.getServiceSubTotalBeforeDiscounts(service)}/>}
                                    </div>
                                    <div className="pull-left" style={{width: shoppingItemLabelWidth}}
                                         title={service.name}>
                                        <span dangerouslySetInnerHTML={{__html: service.name}}/>
                                    </div>
                                    {/* <div className={'os-remove-row'} onClick={() => this.removeService(service)}>
                                        <i className={'fa fa-times'}/>
                                    </div> */}
                                    <div style={{clear: 'both'}}/>
                                    {service.modifier_savings > 0 && !bHideFees && <div>
                                        <div className="pull-right" style={{fontStyle: 'italic', fontWeight: 'bold'}}>
                                            -<Currency amount={service.modifier_savings}/>
                                        </div>
                                        <div className="pull-left" style={{fontStyle: 'italic', fontWeight: 'bold'}}>
                                            Savings
                                        </div>
                                        <div style={{clear: 'both'}}/>
                                    </div>}
                            </li>
                            )
                        }
                    })}
                    {order.pricing.additional.fees.filter((fee) => {
                        return !bHideFees && parseFloat(fee.value) !== 0.00;
                    }).map((fee, feeKey) => {
                        return (
                            <li key={feeKey} className="sidebar-service">
                                <div className="pull-right">
                                    <Currency amount={fee.value}/>
                                </div>
                                <div className="pull-left" style={{width: shoppingItemLabelWidth}}
                                     title={fee.description}>
                                    <span dangerouslySetInnerHTML={{__html: fee.description}}/>
                                </div>
                                <div style={{clear: 'both'}}/>
                            </li>
                        )
                    })}
                    {order.pricing.taxes.filter((tax) => {
                        return !bHideFees && !bHideTaxes
                    }).map((tax, taxKey) => {
                        return (
                            <li key={taxKey} className="sidebar-service">
                                <div className="pull-right">
                                    <Currency amount={tax.total}/>
                                </div>
                                <div className="pull-left" style={{width: shoppingItemLabelWidth}} title={tax.name}>
                                    <span dangerouslySetInnerHTML={{__html: tax.name}}/> (tax)
                                </div>
                                <div style={{clear: 'both'}}/>
                            </li>
                        )
                    })}
                    {order.pricing.coupons.map((coupon, couponKey) => {
                        return (
                            <li key={couponKey} className="sidebar-service">
                                <div className="pull-right" style={{fontStyle: 'italic'}}>
                                    {!bHideFees && <span>-<Currency amount={coupon.amount}/></span>}
                                </div>

                                {/* <div className="pull-left" style={{ width: shoppingItemLabelWidth}}>
                                <Chip label={`Coupon ${coupon.name}`} className="coupon-chip" color="primary" variant="outlined" />
                                </div> */}

                                <div className="pull-left" style={{width: shoppingItemLabelWidth}}>
                                    <span/> Coupon {coupon.name}
                                </div>
                                <div style={{clear: 'both'}}/>
                            </li>
                        )
                    })}
                    {!bHideFees && <li style={{borderTop: '1px solid #ddd', marginTop: '12px', fontSize:"13px", fontWeight: 600, paddingTop: "10px", color: "#757575"}} className="sidebar-service">
                        <div className="pull-right">
                            <Currency amount={bHideTaxes ? order.pricing.subtotal : order.pricing.grand_total}/>
                        </div>
                        <div style={{fontWeight: 600}} className="pull-left package-total">
                            Total:
                        </div>
                        { order.pricing.convenience_fee_message && order.services.filter((service) => service === pacServiceUuid).length <= 0 && <div className="alert alert-info" style={{marginTop: '4px'}}>
                            {order.pricing.convenience_fee_message}
                        </div>}
                        <div style={{clear: 'both'}}/>
                    </li>}
                        {this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_COUPON_LIMIT') > 0
                            && this.props.nav.packages
                            && order.services.length > 0
                            && (!this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_COUPON_AFTER_PACKAGE_ADDED') || order.packages.length === 0)
                            && this.props.hasOwnProperty('applyCoupon') && this.props.applyCoupon &&
                                      <li style={{paddingTop: '0px'}}>
                                         <Coupon type={'sidebar'} order={order} apply={this.props.applyCoupon} size={'small'}/>
                                      </li>
                        }
                    {order.pricing.pac_removed && (
                        <li className="sidebar-service" style={{ marginTop: '12px' }}>
                            <div>
                            FlexFund is no longer available for this order and has been removed.
                            </div>
                        </li>
                    )}
                </ul>}
            </div>
            </div>
        </li>);
        return (
            <>
            <link rel="preconnect" href="https://fonts.googleapis.com"></link>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
            <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap" rel="stylesheet"></link>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
              <Swipeable onSwipeLeft={this.openSidebar} onSwipeRight={this.closeSidebar}>
                <div style={{paddingTop: "0px"}}className={'sidebar' + (this.state.open ? ' sidebar-open' : ' sidebar-closed')}>
                    <div className="sidebar-toggle">
                        {!this.state.open && <div onClick={this.toggleSidebar}>
                            <i className="fas fa-angle-double-left"/>
                        </div>}
                        {this.state.open && <div className="sidebar-toggle-close" onClick={this.toggleSidebar}>
                            <i className="fas fa-angle-double-right"/>
                        </div>}
                    </div>
                    {this.state.open &&
                    <div className="sidebar-content"
                         style={{maxHeight: this.state.max_content_height, overflowY: 'auto', marginLeft: "-8px"}}>
                        <div>
                        <ul style={{listStyleType: 'none', margin: '0 0 0 10px', padding: '0'}}>
                            {!bCalendarLast &&
                            <DateTimePanel style={{opacity: action === '' || action === 'calendar' ? '1' : '0.60', marginBottom: "-5px"}}/>}
                            <AddressPanel style={{
                                paddingTop: bCalendarLast ? '0' : '0px',
                                opacity: ((bCalendarLast && action === '') || action === 'address') ? '1' : '0.60', marginBottom: "-5px"
                            }}/>
                            <ContactsPanel style={{paddingTop: '0px', opacity: action === 'contacts' ? '1' : '0.60', marginBottom: "-5px"}}/>
                            <PropertyInfoPanel
                                style={{paddingTop: '0px', opacity: action === 'property-info' ? '1' : '0.60', marginBottom: "-5px"}}/>
                            <ShoppingCartPanel
                                style={{paddingTop: '0px', opacity: action === 'packages' ? '1' : '0.60', marginBottom: "-5px"}}/>
                            {bCalendarLast &&
                            <DateTimePanel style={{paddingTop: '0px', opacity: action === 'calendar' ? '1' : '0.60', marginBottom: "-5px"}}/>}
                            {/*Display the PAC notification if placing an order with an eligible PAC service. */}
                            {order.pricing.services.filter((service) => !!service.is_pac).length > 0 &&
                            <li style={{paddingTop: '0px'}}>
                                <div className={'alert alert-success'} style={{padding: '1em'}}>
                                    <i className="fa fa-check-circle"/> <b>FlexFund</b> is currently activated.
                                </div>
                            </li>}

                            {this.props.nav.review && order.services.length > 0 && order.datetime &&
                            <li className="text-center" style={{marginTop: '20px', marginBottom: '30px'}}>
                                <button type="button" className="btn btn-default btn-mini review-button" onClick={() => {
                                    this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/review?t=' + this.props.token);
                                }}>
                                    Review
                                </button>
                            </li>}
                        </ul>
                        </div>
                        <div className="col-md-12 text-center"
                             style={{position: 'absolute', bottom: '5px', width: '100%'}}>
                            <div className={'btn-toolbar text-center'} style={{display: 'inline-block'}}>
                                <button type="button" className="btn btn-default btn-mini start-over-button" onClick={() => {
                                    window.location = '/' + this.props.company_key + '/online-scheduler/' + this.props.office_uuid;
                                }}>Start Over
                                </button>
                                {this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_REDIRECT_BUTTON_ENABLED') && this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_REDIRECT_BUTTON_URL') !== ''
                                &&
                                <a href={this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_REDIRECT_BUTTON_URL')}
                                   className={'btn btn-default btn-mini link-button'}
                                   rel={'external'}
                                   target={'_blank'}>{this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_REDIRECT_BUTTON_TEXT')}</a>}
                            </div>
                            <CompanyContactFooter name={this.props.office_name}
                                                  phone={this.props.office_phone}
                                                  email={this.props.office_email}/>
                        </div>
                    </div>
                    }
                </div>
            </Swipeable>
            </>
        );
    }
}

/**
 *
 */
export default withRouter(connect(
    (state, ownProps) => {
        return {
            router: ownProps, ...state.Packages, ...state.Office,
            order: state.Order,
            calendar: state.Calendar,
            nav: state.Nav,
            app: state.App
        }
    },
    (dispatch) => {
        return {
            packageActions: bindActionCreators(PackagesActions, dispatch),
            orderActions: bindActionCreators(OrderActions, dispatch),
            officeActions: bindActionCreators(OfficeActions, dispatch),
            calendarActions: bindActionCreators(CalendarActions, dispatch),
            setNavPackages: (value) => dispatch(setNavPackages(value)),
            setNavLoading: (value) => dispatch(setNavLoading(value))
        }
    }
)(FixedSideBar))