import {
    SET_SAVED,
    SET_DATETIME,
    SET_DURATION,
    SET_INSPECTORS,
    SET_ADDRESS1,
    SET_ADDRESS2,
    SET_CITY,
    SET_STATE,
    SET_POSTAL_CODE,
    SET_COUNTY,
    SET_LATITUDE,
    SET_LONGITUDE,
    SET_SQFT,
    SET_YB,
    SET_FOUNDATION_TYPE,
    SET_SALES_PRICE,
    SET_TEXT_CONTROL,
    SET_TEXTBOX_CONTROL,
    SET_CHECKBOX_CONTROL,
    SET_DROPDOWN_CONTROL,
    SET_TRIGGERED_DROPDOWN_CONTROL,
    SET_TRIGGERED_TEXTBOX_CONTROL,
    SET_DATE_CONTROL,
    SET_CLOSING_DATE_CONTROL,
    SET_PACKAGES,
    SET_SERVICES,
    SET_PRICING,
    SET_PRICING_LOADING,
    SET_USER,
    SET_BUYERS_AGENT,
    SET_SELLERS_AGENT,
    SET_CLIENT,
    SET_INSURANCE_AGENT,
    SET_CONTACTS,
    SET_CONFIRMED,
    SET_PAY_URL,
    SET_ORDER_ALL,
    SET_RUSH_RER,
    SET_REFER_REASON,
    SET_REFER_REASON_OTHER
} from '../constants.jsx'

let initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ORDER_ALL:
            return Object.assign({}, state, action.value);
            break;
        case SET_SAVED:
            return Object.assign({}, state, {
                saved: action.value
            });
            break;
        case SET_DATETIME:
            return Object.assign({}, state, {
                datetime: action.value
            });
            break;
        case SET_DURATION:
            //console.info('SET_DURATION', action.value);
            return Object.assign({}, state, {
                duration: action.value
            });
            break;
        case SET_INSPECTORS:
            const inspectors = action.value.map((item) => {
                return item;
            });
            return Object.assign({}, state, {
                inspectors
            });
            break;
        case SET_ADDRESS1:
            return Object.assign({}, state, {
                address1: action.value
            });
            break;
        case SET_ADDRESS2:
            return Object.assign({}, state, {
                address2: action.value
            });
            break;
        case SET_CITY:
            return Object.assign({}, state, {
                city: action.value
            });
            break;
        case SET_STATE:
            return Object.assign({}, state, {
                state: action.value
            });
            break;
        case SET_POSTAL_CODE:
            return Object.assign({}, state, {
                postal_code: action.value
            });
            break;
        case SET_COUNTY:
            return Object.assign({}, state, {
                county: action.value
            });
            break;
        case SET_LATITUDE:
            return Object.assign({}, state, {
                latitude: action.value
            });
            break;
        case SET_LONGITUDE:
            return Object.assign({}, state, {
                longitude: action.value
            });
            break;
        case SET_SQFT:
            return Object.assign({}, state, {
                sqft: action.value
            });
            break;
        case SET_YB:
            return Object.assign({}, state, {
                yb: action.value
            });
            break;
        case SET_FOUNDATION_TYPE:
            return Object.assign({}, state, {
                foundation_type: action.value
            });
            break;
        case SET_SALES_PRICE:
            return Object.assign({}, state, {
                sales_price: action.value
            });
            break;
        case SET_TEXT_CONTROL:
        case SET_TEXTBOX_CONTROL:
        case SET_CHECKBOX_CONTROL:
        case SET_DROPDOWN_CONTROL:
        case SET_DATE_CONTROL:
        case SET_CLOSING_DATE_CONTROL:
            return ((state, action) => {
                const controls = state.controls.map((control) => {
                    return Object.assign({}, control);
                });
                let control = controls.find((control) => {
                    return control.id === action.control_id;
                });
                if (control) {
                    control.value = action.value;
                    return Object.assign({}, state, {
                        controls
                    });
                }
            })(state, action);
            break;
        case SET_TRIGGERED_TEXTBOX_CONTROL:
        case SET_TRIGGERED_DROPDOWN_CONTROL:
            return ((state, action) => {
                const controls = state.controls.map((control) => {
                    return Object.assign({}, control);
                });
                let control = controls.find((control) => {
                    return control.id === action.control_id;
                });
                if (control) {
                    control.value = action.value;
                    control.checked = action.checked;
                    return Object.assign({}, state, {
                        controls
                    });
                }
            })(state, action);
            break;
        case SET_PACKAGES:
            return Object.assign({}, state, {
                packages: action.value
            });
            break;
        case SET_SERVICES:
            return Object.assign({}, state, {
                services: action.value
            });
            break;
        case SET_PRICING:
            return Object.assign({}, state, {
                pricing: action.value
            });
            break;
        case SET_PRICING_LOADING:
            return Object.assign({}, state, {
                pricing_loading: action.value
            });
            break;
        case SET_USER:
            return Object.assign({}, state, {
                user: action.value
            });
            break;
        case SET_BUYERS_AGENT:
            return Object.assign({}, state, {
                buyers_agent: action.value
            });
            break;
        case SET_SELLERS_AGENT:
            return Object.assign({}, state, {
                sellers_agent: action.value
            });
            break;
        case SET_CLIENT:
            return Object.assign({}, state, {
                client: action.value
            });
            break;
        case SET_INSURANCE_AGENT:
            return Object.assign({}, state, {
                insurance_agent: action.value
            });
            break;
        case SET_CONTACTS:
            const contacts = action.value.map((item) => {
                return item;
            });
            return Object.assign({}, state, {
                contacts
            });
            break;
        case SET_CONFIRMED:
            return Object.assign({}, state, {
                confirmed: action.value
            });
            break;
        case SET_PAY_URL:
            return Object.assign({}, state, {
                pay_url: action.value
            });
            break;
        case SET_RUSH_RER:
            return Object.assign({}, state, {
                rush_rer: action.value
            });
            break;
        case SET_REFER_REASON:
            return Object.assign({}, state, {
                refer_reason: action.value
            });
            break;
        case SET_REFER_REASON_OTHER:
            return Object.assign({}, state, {
                refer_reason_other: action.value
            });
            break;
    }
    return state;
}