import { setConfirmed, setPayUrl } from './Order.jsx'
import { getSpecialNoteLocalStorage } from './PropertyInfo.jsx'
import request from 'superagent'
import errorHandler from '../error.jsx'

/**
 *
 * @returns {function(*, *)}
 */
export function save() {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        
        const payload = {
            special_note: dispatch(getSpecialNoteLocalStorage())
        };

        return request.post('/'+company_key+'/json/online_scheduler/confirm', payload)
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .then((res) => {
                dispatch(setPayUrl(res.body.pay_url));
                dispatch(setConfirmed(true));
            });
    }
}