import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import FixedSideBar from "./FixedSideBar"
import ReviewInfo from './ReviewInfo';
import "./OnlineScheduler.css"
export class PopUpModal extends Component{
    constructor(props) {
        super(props);
    }
    render(){
        return <Modal show={true} className={'rvw-modal appointment-na-modal'} style={{margin: "auto", backgroundColor: `rgba(69, 69, 69, 0.25)`,}}>
        <Modal.Body style={{ padding: "5px",  minHeight: '800px' }} >
           {/* <FixedSideBar/> */}
          <ReviewInfo toggleClose={this.props.toggleClose}/>
         </Modal.Body>
    </Modal>;
    }
}