import {
    setSaved,
    setUser as setOrderUser,
    setBuyersAgent as setOrderBuyersAgent,
    setSellersAgent as setOrderSellersAgent,
    setInsuranceAgent as setOrderInsuranceAgent,
    setClient as setOrderClient,
    setContacts as setOrderContacts
} from './Order.jsx'
import request from 'superagent'
import errorHandler from '../error.jsx'
import {getAnalyticsEventLabel} from "../util";

export function setUser(user) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        return request.post('/'+company_key+'/json/online_scheduler/set_user_type')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(user)
            .then((res) => {
                dispatch(setOrderUser(user));
                dispatch(setSaved(true));
            });
    };
}

export function setBuyersAgent(agent) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        return request.post('/'+company_key+'/json/online_scheduler/set_buyers_agent')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(agent)
            .then((res) => {
                if (res.body.agent) {
                    agent.id = res.body.agent.id;
                }
                dispatch(setOrderBuyersAgent(agent));
                dispatch(setSaved(true));

                if (window.ga) {
                    window.ga('send', {
                        hitType: 'event',
                        eventCategory: 'Online Scheduler',
                        eventAction: 'added-buyers-agent',
                        eventLabel: getAnalyticsEventLabel(company_key, 'A Buyer\'s Agent was added to the inspection')
                    });
                }

                return res.body.agent;
            });
    };
}

export function setSellersAgent(agent) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        return request.post('/'+company_key+'/json/online_scheduler/set_sellers_agent')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(agent)
            .then((res) => {
                if (res.body.agent) {
                    agent.id = res.body.agent.id;
                }
                dispatch(setOrderSellersAgent(agent))
                dispatch(setSaved(true));

                if (window.ga) {
                    window.ga('send', {
                        hitType: 'event',
                        eventCategory: 'Online Scheduler',
                        eventAction: 'added-sellers-agent',
                        eventLabel: getAnalyticsEventLabel(company_key, 'A Seller\'s Agent was added to the inspection')
                    });
                }

                return res.body.agent;
            });
    };
}

export function setInsuranceAgent(agent) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        return request.post('/'+company_key+'/json/online_scheduler/set_insurance_agent')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(agent)
            .then((res) => {
                if (res.body.agent) {
                    agent.id = res.body.agent.id;
                }
                dispatch(setOrderInsuranceAgent(agent));
                dispatch(setSaved(true));

                if (window.ga) {
                    window.ga('send', {
                        hitType: 'event',
                        eventCategory: 'Online Scheduler',
                        eventAction: 'added-insurance-agent',
                        eventLabel: getAnalyticsEventLabel(company_key, 'An Insurance Agent was added to the inspection')
                    });
                }

                return res.body.agent;
            });
    };
}

export function setClient(client) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        return request.post('/'+company_key+'/json/online_scheduler/set_client')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(client)
            .then((res) => {
                if (res.body.client) {
                    client.id = res.body.client.id;
                }
                dispatch(setOrderClient(client));
                dispatch(setSaved(true));

                if (window.ga) {
                    window.ga('send', {
                        hitType: 'event',
                        eventCategory: 'Online Scheduler',
                        eventAction: 'added-client',
                        eventLabel: getAnalyticsEventLabel(company_key, 'A Client was added to the inspection')
                    });
                }

                return res.body.agent;
            });
    };
}

export function setContacts(contacts) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;

        return request.post('/'+company_key+'/json/online_scheduler/set_contacts')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(contacts)
            .then((res) => {
                if (res.body.contacts && res.body.contacts instanceof Array) {
                    contacts = res.body.contacts;
                }
                dispatch(setOrderContacts(contacts));
                dispatch(setSaved(true));
                return res.body.agent;
            });
    };
}

export function addContact(contact) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        let { contacts } = state.Order;

        if (contact?.first && contact?.type) {

            return request.post('/'+company_key+'/json/online_scheduler/add_contact')
                .set('X-Auth-Token', token)
                .on('error', (err) => errorHandler(err, dispatch))
                .send(contact)
                .then((res) => {
                    if (res.body.contact) {
                        contact.id = res.body.contact.id;
                    }
                    if (!contacts.find((obj) => { return obj.id === contact.id })) {
                        contacts.push(contact);
                        dispatch(setOrderContacts(contacts));
                        dispatch(setSaved(true));
                    }

                    if (window.ga) {
                        window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'Online Scheduler',
                            eventAction: 'added-contact',
                            eventLabel: getAnalyticsEventLabel(company_key, 'A Contact was added to the inspection')
                        });
                    }

                    return res.body.contact;
            });
        }
    };
}

export function removeContact(contact) {
    return (dispatch, getState) => {
        const state = getState();
        const { token, company_key } = state.Office;
        let { contacts } = state.Order;

        return request.post('/'+company_key+'/json/online_scheduler/remove_contact')
            .set('X-Auth-Token', token)
            .on('error', (err) => errorHandler(err, dispatch))
            .send(contact)
            .then((res) => {
                if (res.body.removed) {
                    for (let i = 0, l = contacts.length; i < l; i++) {
                        if (contacts[i].id === contact.id) {
                            contacts.splice(i, 1);
                            dispatch(setOrderContacts(contacts));
                            dispatch(setSaved(true));
                            break;
                        }
                    }
                }
                if (!contacts.find((obj) => { return obj.id === contact.id })) {
                    contacts.push(contact);
                    dispatch(setOrderContacts(contacts));
                    dispatch(setSaved(true));
                }
                return res.body.removed;
            });
    };
}