import {
    CALENDAR_SET_LOADING,
    CALENDAR_SET_LOADING_MORE,
    CALENDAR_SET_TIME_AVAILABLE,
    CALENDAR_SET_SELECTED_DATE,
    CALENDAR_SET_SELECTED_APPOINTMENT,
    CALENDAR_SET_LIST_MODE_LAST_DATE,
    CALENDAR_SET_AVAILABILITY,
    CALENDAR_SERVICES_CHANGED
} from '../constants.jsx'
import moment from 'moment'

const initialState = {
    mode: 'date',
    now: moment(),
    loading: true,
    loading_more: false,
    time_available: false,
    selected_date: null,
    selected_appointment: null,
    list_mode_last_dte: null,
    availability: {},
    services_changed: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CALENDAR_SET_LOADING:
            return Object.assign({}, state, {
                loading: action.value
            });
            break;
        case CALENDAR_SET_LOADING_MORE:
            return Object.assign({}, state, {
                loading_more: action.value
            });
            break;
        case CALENDAR_SET_TIME_AVAILABLE:
            return Object.assign({}, state, {
                time_available: action.value
            });
            break;
        case CALENDAR_SET_SELECTED_DATE:
            return Object.assign({}, state, {
                selected_date: action.value
            });
            break;
        case CALENDAR_SET_SELECTED_APPOINTMENT:
            return Object.assign({}, state, {
                selected_appointment: action.value
            });
            break;
        case CALENDAR_SET_LIST_MODE_LAST_DATE:
            return Object.assign({}, state, {
                list_mode_last_dte: action.value
            });
            break;
        case CALENDAR_SET_AVAILABILITY:
            return Object.assign({}, state, {
                availability: action.value
            });
            break;
        case CALENDAR_SERVICES_CHANGED:
            return Object.assign({}, state, {
                services_changed: action.value
            });
            break;
    }
    return state;
}