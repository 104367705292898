import React, { Component } from 'react'

export class Container extends Component  {

    constructor(props) {
        super(props);
    }

    render() {

        const containerClasses = this.props.hasOwnProperty('classes') && this.props.classes && this.props.classes !== ''
            ? this.props.classes
            : '';

        const containerBodyClasses = this.props.hasOwnProperty('bodyClasses') && this.props.bodyClasses && this.props.bodyClasses !== ''
            ? this.props.bodyClasses
            : '';

        return (
            <div className={'osv3-container container-fluid' + (containerClasses !== '' ? ' ' + containerClasses : '')}>
                {(this.props.app.office_loading || this.props.app.order_loading) && <div style={{position:'absolute',top:'35%',left:'42%'}}>
                    <div className={'text-center'}>
                        <i className={'fas fa-spinner fa-spin fa-2x'} style={{fontSize:'2.5em'}}/>
                        <h3>Hang on, we're setting up!</h3>
                    </div>
                </div>}
                {!this.props.app.office_loading && !this.props.app.order_loading && <div>

                    {!this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_HIDE_LOGO') && <div className="row">
                            <div className={'text-center'}>
                                <img src={'/'+this.props.company_key+'/logo'}/>
                            </div>
                    </div>}
                        
                    <div className="row" style={{marginTop:'90px'}}> </div>
                    {this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_INFO_BANNER') !== ''
                    && <div className="row"> 
                        <div className={'alert alert-info alert-tight bannerNotification banner-respo pc-banner-respo'} dangerouslySetInnerHTML={{__html: this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_INFO_BANNER')}}/>
                    </div>}


                    {this.props.hasOwnProperty('title') && this.props.title !== '' && <div className="row" style={{marginTop:'15px'}}>        
                        <div className="page-title-class">
                            <h4>{this.props.title}</h4>
                        </div>
                    </div>}
                    <div className="row" style={{marginTop:'15px', width: '100%'}}>
                        <div className={containerBodyClasses}>
                            {this.props.children}
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default Container;