import {
    SET_NAV_CALENDAR,
    SET_NAV_ADDRESS,
    SET_NAV_CONTACTS,
    SET_NAV_PROPERTY_INFO,
    SET_NAV_PACKAGES,
    SET_NAV_REVIEW,
    SET_NAV_LOADING
} from '../constants.jsx'
import { app_state } from '../app_data.jsx'

const key = 'nav-'+app_state.token;

const persist = function (state) {
    let cp = Object.assign({}, state);
    if (cp.hasOwnProperty('loading')) {
        delete cp.loading;
    }
    localStorage.setItem(key, JSON.stringify(cp));
};

let initialState;

try {
    initialState = JSON.parse(localStorage.getItem(key));
} catch (e) {}

if (!initialState) {
    initialState = {
        calendar: false,
        address: false,
        contacts: false,
        property_info: false,
        packages: false,
        review: false
    };
}

initialState.loading = false;

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_NAV_CALENDAR:
            state = Object.assign({}, state, {
                calendar: action.value
            });
            persist(state);
            break;
        case SET_NAV_ADDRESS:
            state = Object.assign({}, state, {
                address: action.value
            });
            persist(state);
            break;
        case SET_NAV_CONTACTS:
            state = Object.assign({}, state, {
                contacts: action.value
            });
            persist(state);
            break;
        case SET_NAV_PROPERTY_INFO:
            state = Object.assign({}, state, {
                property_info: action.value
            });
            persist(state);
            break;
        case SET_NAV_PACKAGES:
            state = Object.assign({}, state, {
                packages: action.value
            });
            persist(state);
            break;
        case SET_NAV_REVIEW:
            state = Object.assign({}, state, {
                review: action.value
            });
            persist(state);
            break;
        case SET_NAV_LOADING:
            state = Object.assign({}, state, {
                loading: action.value
            });
            break;
    }
    return state;
}