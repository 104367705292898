import React, { Component } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import "./Coupon.css"


const styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing.unit,
      },
      dense: {
        marginTop: 16,
      },
      menu: {
        width: 200,
      },
      notchedOutline: { borderColor: '#a94442 !important' },
      resize:{
          fontSize:16,
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // borderColor: '#0F75BC', 
            borderWidth: 1, 
          },
        },
      inputRoot: {
          fontSize: 22,
          background: "white",
          paddingRight: 5,
          position: "absolute",
          top: "5px",
          fontFamily: 'Source Sans Pro',
      },
      inputRoot2: {
        fontSize: 22,
          paddingRight: 5,
          position: "absolute",
          top: 5,
          background: "#f2f2f2",
          fontFamily: "Source Sans Pro",
      },
      icon: {
          // fill: theme.palette.primary.main,
          right: '12px',
          top: 'calc(42% - 12px)', // Adjust the position if needed
          width: '30px', // Increase or decrease the size as per your needs
          height: '30px', // Increase or decrease the size as per your needs
          position: 'absolute',
          pointerEvents: 'none',
        },
        customSnackbarContent: {
          maxWidth: "211px !important"
        }
  });
  
const CouponIcon = ()=>(  
<svg xmlns="http://www.w3.org/2000/svg" fill="#0F75BC" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160q17 0 28.5-11.5T520-480q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm320 440H160q-33 0-56.5-23.5T80-240v-160q33 0 56.5-23.5T160-480q0-33-23.5-56.5T80-560v-160q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v160q-33 0-56.5 23.5T800-480q0 33 23.5 56.5T880-400v160q0 33-23.5 56.5T800-160Zm0-80v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102h640ZM480-480Z"/></svg>
)

class Coupon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            coupon: '',
            applying_coupon: false,
            applying_coupon_result: null,
            visible: false
        };

        this.apply = this.apply.bind(this);
    }

    apply() {
        this.setState({
            applying_coupon: true
        }, () => {
            this.props.apply(this.state.coupon)
                .then((result) => {
                    let coupon = this.state.coupon;
                    if (result.status === 'ok') {
                        coupon = '';
                    }
                    this.setState({
                        applying_coupon: false,
                        applying_coupon_result: result.status === 'ok' ? true : result.message,
                        coupon
                    });
                }, () => {
                    this.setState({
                        applying_coupon: false,
                        applying_coupon_result: 'There was a problem applying this coupon'
                    });
                });
        });
    }

    render() {
        const fontSize = this.props.hasOwnProperty('size') && this.props.size === 'small' ? '85%' : '100%';
        const { classes } = this.props;
        return (
            <>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            <div style={{paddingBottom: '10%'}}>
            <div className="coupon-style" style={{fontSize: fontSize, marginTop: "10px", marginBottom: "16px",marginLeft: "10px"}}>
              <CouponIcon style={{color: "#0F75BC"}}/>&nbsp;<span  style={{color: "#0F75BC", marginLeft: "3px"}} className="text-font-style">Have a coupon?</span>
            </div>
            {/* <input type="text" className="form-control input-sm coupon-input" placeholder="Add Coupon"
                   style={{fontSize: fontSize, fontSize: "14px", padding: '2px 10px', height: '45px', marginTop: "10px", background: "transparent", width: "258px"}}
                   value={this.state.coupon}
                   readOnly={this.state.applying_coupon}
                   onChange={(e) => {
                       this.setState({
                           coupon: e.target.value,
                           applying_coupon: false,
                           applying_coupon_result: null
                       })
                   }}
                   onKeyUp={(e) => { if (e.key === 'Enter') { this.apply() } }}/> */}
            {/* {this.state.coupon !== '' && <div className="text-center">
                <button type="button" className="btn btn-info btn-block btn-xs"
                        style={{marginTop: '3px'}} onClick={this.apply}
                        disabled={this.state.applying_coupon}>
                    <i className="fas fa-money-bill-alt"/>Apply Coupon
                </button>
            </div>} */}

            {/* <FormControl variant="outlined">
                <InputLabel className="coupon-input" htmlFor="outlined-adornment-password">Add coupon</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    style={{fontSize: fontSize, fontSize: "14px", padding: '2px 10px', height: '45px', marginTop: "10px", background: "transparent", width: "258px"}}
                    value={this.state.coupon}
                    readOnly={this.state.applying_coupon}
                    onChange={(e) => {
                        this.setState({
                            coupon: e.target.value,
                            applying_coupon: false,
                            applying_coupon_result: null
                        })
                    }}
                    onKeyUp={(e) => { if (e.key === 'Enter') { this.apply() } }}
                    // type={values.showPassword ? 'text' : 'password'}
                    // value={values.password}
                    // onChange={handleChange('password')}
                    endAdornment={
                    <InputAdornment position="end">
                          {this.state.coupon !== '' && <div className="text-center">
                            <button type="button" 
                                    onClick={this.apply}
                                    className='coupon-button'
                                    disabled={this.state.applying_coupon}>
                               APPLY
                            </button>
                          </div>}
                        {/* <button>APPLY</button> */}
                    {/* </InputAdornment>
                    }
                    labelWidth={70} */}
                {/* /> */}
             {/* </FormControl> */ }

             <TextField
                variant="outlined"
                fullWidth
                id='password'
                label='Add coupon'
                className={classes.textField}
                style={{
                    fontSize: "14px",
                    background: "transparent",
                    padding: "8px 0px",
                }}
                value={this.state.coupon}
                readOnly={this.state.applying_coupon}
                onChange={(e) => {
                    this.setState({
                        coupon: e.target.value,
                        applying_coupon: false,
                        applying_coupon_result: null
                    })
                }}
                onKeyUp={(e) => { if (e.key === 'Enter') { this.apply() } }}
                InputLabelProps={{
                    classes: {
                        root: this.props.type === 'sidebar' ? classes.inputRoot2 : classes.inputRoot,
                      },
                    shrink: true
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        {this.state.coupon !== '' && typeof this.state.applying_coupon_result !== 'string' && <div className="text-center">
                          <button type="button" 
                                  onClick={this.apply}
                                  className='coupon-button'
                                  disabled={this.state.applying_coupon}>
                             APPLY
                          </button>
                        </div>}
                      {/* <button>APPLY</button> */}
                  </InputAdornment>
                    ),
                    classes: {
                        input: classes.resize,
                        notchedOutline: typeof this.state.applying_coupon_result === 'string' ? classes.notchedOutline : '',
                      },
                }}
                />
            <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.applying_coupon_result === true}
          autoHideDuration={4000} // Adjust the duration as needed
          onClose={() => {
            this.setState({ applying_coupon_result: null });
          }}
          ContentProps={{
            style: { width: 214 },
          }}
        >
          <SnackbarContent
            message="Coupon applied correctly"
            className={classes.customSnackbarContent}
            style={{ fontSize: '16px', fontStyle: "normal",
            fontWeight: "500",lineHeight: "24px", fontFamily: "Source Sans Pro"}}
          />
        </Snackbar>

            {/* {this.state.applying_coupon_result === true &&
            <div className="alert alert-success" style={{padding: '3px', margin: '2px'}}>
                We have successfully applied the coupon to your order
            </div>} */}
            {typeof this.state.applying_coupon_result === 'string' &&
            <div className="alert alert-danger coupon-error-message">
                {this.state.applying_coupon_result}
            </div>}
        </div>
        </>);
    }
}

export default  withStyles(styles)(Coupon);