import React, { Component } from 'react'
import request from 'superagent';

class Currency extends React.Component {
    constructor(props) {
        super(props);
        Currency.currencyCode = 'USD';
    }

    static init(ck) {
        return request.get('/'+ck+'/json/currency_code')
            .then((res) => {
                Currency.currencyCode = res.body.currency_code;
            });
    }

    render() {
        try {
            const currencyCode = String(Currency.currencyCode);
            const formattedValue = Number(this.props.amount).toLocaleString('en', { style: 'currency', currency: currencyCode });
            return (
                <span>{formattedValue}</span>
            );
        }
        catch(e) {
            console.warn(e);
            return <em>n/a</em>;
        }

    }
}

export default Currency;