import React from 'react'

class Utils extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return '';
    }
}

Utils.copyObject = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

Utils.getCookie = (name) => {
    const start = document.cookie.indexOf(name+'=');
    const len = start+name.length+1;
    if ((!start) && (name !== document.cookie.substring(0,name.length))) return null;
    if (start === -1) return null;
    let end = document.cookie.indexOf(';',len);
    if (end === -1) end = document.cookie.length;
    return document.cookie.substring(len,end);
};

Utils.debounce = (fn, time) => {
    let timeout;

    return function() {
        const functionCall = () => fn.apply(this, arguments);

        clearTimeout(timeout);
        timeout = setTimeout(functionCall, time);
    }
};

Utils.commaList = (list) => {
    if (list.length === 1) {
        return list.join('');
    } else if (list.length === 2) {
        return list[0] + ' and ' + list[1];
    }

    let last = list.pop();
    return list.join(', ') + ', and ' + last;
};

Utils.scrollTop = (timeout) => {
    if (typeof timeout === 'number') {
        setTimeout(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }, timeout);
    } else {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
};

Utils.parseQuery = (query) => {
    let lets = query.split('&');
    let query_string = {};
    for (let i = 0; i < lets.length; i++) {
        let pair = lets[i].split('=');
        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1]);
        // If first entry with this name
        if (typeof query_string[key] === 'undefined') {
            query_string[key] = decodeURIComponent(value);
            // If second entry with this name
        } else if (typeof query_string[key] === 'string') {
            query_string[key] = [query_string[key], decodeURIComponent(value)];
            // If third or later entry with this name
        } else {
            query_string[key].push(decodeURIComponent(value));
        }
    }
    return query_string;    
};

Utils.sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export default Utils;