import React, { Component } from 'react'
import PersonEditForm from './PersonEditForm.jsx'
import PersonViewForm from './PersonViewForm.jsx'

export class ClientView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <PersonViewForm {...this.props}/>
            </div>
        )
    }
}

export class ClientEditView extends Component {
    constructor(props) {
        super(props);

        this.updateFirstName = this.updateFirstName.bind(this);
        this.updateLastName = this.updateLastName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateMobilePhone = this.updateMobilePhone.bind(this);
        this.updateWorkPhone = this.updateWorkPhone.bind(this);
        this.updateHomePhone = this.updateHomePhone.bind(this);
        this.updateWorkFax = this.updateWorkFax.bind(this);
        this.updateHomeFax = this.updateHomeFax.bind(this);
        this.updateAddress1 = this.updateAddress1.bind(this);
        this.updateAddress2 = this.updateAddress2.bind(this);
        this.updateCity = this.updateCity.bind(this);
        this.updateState = this.updateState.bind(this);
        this.updatePostalCode = this.updatePostalCode.bind(this);
    }

    updateFirstName(e) {
        this.props.updateTextField(e, 'client', 'first', this.props.validation.hasOwnProperty('first'));
    }

    updateLastName(e) {
        this.props.updateTextField(e, 'client', 'last', this.props.validation.hasOwnProperty('last'));
    }

    updateEmail(e) {
        this.props.updateTextField(e, 'client', 'email', this.props.validation.hasOwnProperty('email'));
    }

    updateMobilePhone(e) {
        this.props.updateTextField(e, 'client', 'mobile_phone', this.props.validation.hasOwnProperty('mobile_phone'));
    }

    updateWorkPhone(e) {
        this.props.updateTextField(e, 'client', 'work_phone', this.props.validation.hasOwnProperty('work_phone'));
    }

    updateHomePhone(e) {
        this.props.updateTextField(e, 'client', 'home_phone', this.props.validation.hasOwnProperty('home_phone'));
    }

    updateWorkFax(e) {
        this.props.updateTextField(e, 'client', 'work_fax', this.props.validation.hasOwnProperty('work_fax'));
    }

    updateHomeFax(e) {
        this.props.updateTextField(e, 'client', 'home_fax', this.props.validation.hasOwnProperty('home_fax'));
    }

    updateAddress1(e) {
        this.props.updateTextField(e, 'client', 'address1', this.props.validation.hasOwnProperty('address1'));
    }

    updateAddress2(e) {
        this.props.updateTextField(e, 'client', 'address2', this.props.validation.hasOwnProperty('address2'));
    }

    updateCity(e) {
        this.props.updateTextField(e, 'client', 'city', this.props.validation.hasOwnProperty('city'));
    }

    updateState(e) {
        this.props.updateTextField(e, 'client', 'state', this.props.validation.hasOwnProperty('state'));
    }

    updatePostalCode(e) {
        this.props.updateTextField(e, 'client', 'postal_code', this.props.validation.hasOwnProperty('postal_code'));
    }

    render() {
        return (
            <div>
                <div className="form-horizontal container-left"  >
                    <PersonEditForm
                        ref_key="client"
                        fields={this.props.fields}
                        setRef={this.props.setRef}
                        first={{value:this.props.first,update:this.updateFirstName}}
                        last={{value:this.props.last,update:this.updateLastName}}
                        email={{value:this.props.email,update:this.updateEmail}}
                        mobile_phone={{value:this.props.mobile_phone,update:this.updateMobilePhone}}
                        work_phone={{value:this.props.work_phone,update:this.updateWorkPhone}}
                        home_phone={{value:this.props.home_phone,update:this.updateHomePhone}}
                        work_fax={{value:this.props.work_fax,update:this.updateWorkFax}}
                        home_fax={{value:this.props.home_fax,update:this.updateHomeFax}}
                        address1={{value:this.props.address1,update:this.updateAddress1}}
                        address2={{value:this.props.address2,update:this.updateAddress2}}
                        city={{value:this.props.city,update:this.updateCity}}
                        state={{value:this.props.state,update:this.updateState}}
                        postal_code={{value:this.props.postal_code,update:this.updatePostalCode}}
                        validation={this.props.validation}
                        save={this.props.save}
                        useSubjectProperty={this.props.useSubjectProperty}
                        states={this.props.states}/>
                </div>
                {/* <div className="text-center" style={{marginTop:'5px'}}>
                    <button type="button" className="btn btn-success btn-xs"
                            onClick={this.props.save}>
                        <i className="fas fa-save"/> Save
                    </button>
                    &nbsp;
                    {!this.props.isNew && <button type="button" className="btn btn-danger btn-xs"
                            onClick={this.props.cancel}>
                        <i className="fas fa-ban"/> Cancel
                    </button>}
                </div> */}
            </div>
        )
    }
}

export default class ClientForm extends Component {
    constructor(props) {
        super(props);

        this.save = this.save.bind(this);
    }

    save()
    {
        this.props.save()
            .then(null, (e) => null)
    }

    render() {
        return (
            <div className="contact-form">
                <div className="panel">
                    {/* <div className="panel-heading">
                        {this.props.flags.edit ? 'Edit ' : ''}
                        {this.props.flags.add ? 'Add ' : ''}
                        {this.props.userIsClient ? this.props.order.user.text + ' (me)' : 'Client'}
                    </div> */}
                    {this.props.order?.user?.text ? (<div style={{ paddingLeft: '15%', fontSize: '18px', fontWeight: '600', display: 'flex', justifyContent: 'space-between' }}>
                             {this.props.order?.user?.type == "BUYERS_AGENT" ? "Home Buyer" : null}
                             {this.props.order?.user?.type == "SELLERS_AGENT" ? "Home Owner" : null}
                             {this.props.order?.user?.type == "INSURANCE_AGENT" ? "Home Buyer" : null}
                    </div>) : null} 
                    
                    <div className="panel-body">
                        {this.props.data !== null && !this.props.flags.edit && !this.props.flags.add
                        && <ClientEditView {...this.props.data}
                        fields={this.props.fields}
                        setRef={this.props.setRef}
                        me={this.props.userIsClient}
                        isNew={false}
                        updateTextField={this.props.update}
                        validation={this.props.validation}
                        save={this.save}
                        cancel={this.props.cancel}
                        useSubjectProperty={this.props.useSubjectProperty}
                        states={this.props.states}/>
                        }
                        {this.props.flags.edit
                        && <ClientEditView {...this.props.data}
                                           fields={this.props.fields}
                                           setRef={this.props.setRef}
                                           me={this.props.userIsClient}
                                           isNew={false}
                                           updateTextField={this.props.update}
                                           validation={this.props.validation}
                                           save={this.save}
                                           cancel={this.props.cancel}
                                           useSubjectProperty={this.props.useSubjectProperty}
                                           states={this.props.states}/>
                        }
                        {this.props.flags.add
                        && <ClientEditView {...this.props.data}
                                           fields={this.props.fields}
                                           setRef={this.props.setRef}
                                           me={this.props.userIsClient}
                                           isNew={true}
                                           updateTextField={this.props.update}
                                           validation={this.props.validation}
                                           save={this.save}
                                           cancel={this.props.cancel}
                                           useSubjectProperty={this.props.useSubjectProperty}
                                           states={this.props.states}/>
                        }
                    </div>
                    {/* {this.props.data !== null && !this.props.flags.edit && !this.props.flags.add
                    && <div className="panel-footer">
                        <div className="btn-toolbar text-center">
                            <button type="button" className="btn btn-default btn-xs"
                                    style={{float: 'none'}}
                                    onClick={this.props.edit}>
                                <i className="fas fa-pencil"/> Edit
                            </button>
                            <button type="button" className="btn btn-info btn-xs"
                                    style={{float: 'none'}}
                                    onClick={this.props.add}>
                                <i className="fa fa-refresh"/> Change {!this.props.userIsClient ? 'Client' : this.props.order.user.text}
                            </button>
                        </div>
                    </div>} */}
                </div>
            </div>
        )
    }
}